import React, { useState, useEffect } from "react";
import defaultProfile from "../../photos/generic-profile-pic.png";
import report from "../../assets/images/report.png";
import claim from "../../assets/images/claim.png";
import { Modal } from "antd";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { formatPhoneNumber } from "../misc/utilty.jsx";
import RegisterFromListingForm from "../forms/RegisterFromListingForm.jsx";
import { getCookie } from "../../helpers/utilities/Cookie.js";
import { getFromLocalStorage } from "../../helpers/utilities/LocalStorage.js";

function OwnerProfile({ ad }) {
  const [auth] = useAuth();
  const navigate = useNavigate();

  const [openModel, setModel] = useState(0);
  const isMobile = window.innerWidth < 540;
  const [viewDesktopPhoneNumber, setViewDesktopPhoneNumber] = useState(false);

  const loggedIn = auth.user !== null && auth.token !== "";
  const visitorId = getCookie("visitorId");
  const [loading, setLoading] = useState();

  const [existingRelationship, setExistingRelationship] = useState();
  const [existingRelationshipId, setExistingRelationshipId] = useState();

  const [existingReport, setExistingReport] = useState();
  const [reportText, setReportText] = useState("");

  const [existingClaim, setExistingClaim] = useState();
  const [claimText, setClaimText] = useState("");

  const [postSignUpMessage, setPostSignupMessage] = useState(false);
  const [careAssessmentComplete, setCareAssessmentComplete] = useState(false);

  const receivedBy = ad?.postedBy?._id;
  const listingId = ad._id;

  useEffect(() => {
    if (loggedIn) {
      fetchRelationship();
      fetchReport();
      fetchClaim();
    }
  }, []);

  useEffect(() => {
    if (postSignUpMessage === true) handleConnectRequest();
  }, [postSignUpMessage]);

  // checks assessment completion status whenever modal is opened or closed
  useEffect(() => {
    setCareAssessmentComplete(getFromLocalStorage("care-assessment-complete"));
  }, [setModel]);

  const fetchRelationship = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post("/listing/find-relationship", {
        receivedBy,
        listingId,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        setExistingRelationship(data?.status);
        setExistingRelationshipId(data?.id);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleConnectRequest = async () => {
    setLoading(true);

    const message = auth.user.firstName + " has requested to connect with you!";
    try {
      const { data } = await axios.post("/new-message", {
        receivedBy,
        listingId,
        message,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        toast.success("Message Sent");
        fetchRelationship();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  const fetchReport = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post("/listing/find-report", {
        listingId,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        setExistingReport(data?.status);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleReportRequest = async (e) => {
    e.preventDefault();
    setLoading(true);

    const supportMessage = reportText;
    const name = auth.user.firstName;
    const phone = auth.user.phone;
    const email = auth.user.email;
    const listingId = ad._id;
    const listingTitle = ad.title;
    try {
      const { data } = await axios.post(
        "/support/create-ticket/report-listing",
        {
          supportMessage,
          email,
          phone,
          name,
          listingId,
          listingTitle,
          visitorId,
        }
      );

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        toast.success("Report Submitted");
        fetchReport();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  const fetchClaim = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post("/listing/find-claim", {
        listingId,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        setExistingClaim(data?.status);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClaimRequest = async (e) => {
    e.preventDefault();
    setLoading(true);

    const supportMessage = claimText;
    const name = auth.user.firstName;
    const phone = auth.user.phone;
    const email = auth.user.email;
    const listingId = ad._id;
    const listingTitle = ad.title;

    try {
      const { data } = await axios.post(
        "/support/create-ticket/claim-listing",
        {
          supportMessage,
          email,
          phone,
          name,
          listingId,
          listingTitle,
          visitorId,
        }
      );

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        toast.success("Claim Submitted");
        fetchClaim();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  const handleInboxRedirect = () => {
    navigate("/client/inbox/?thread=" + existingRelationshipId);
  };

  const handleCallButtonClick = async () => {
    // track call event
    await axios.post("/analytics/user/call-listing", {
      listingId: ad?._id,
      userId: auth?.user?._id,
      type: "dial",
    });

    const phoneNumber = encodeURIComponent(ad.phoneNumber);
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleViewPhoneNumberClick = async () => {
    // track call event
    await axios.post("/analytics/user/call-listing", {
      listingId: ad?._id,
      userId: auth?.user?._id,
      type: "view",
    });
    setViewDesktopPhoneNumber(true);
  };

  return (
    <div>
      <div className="border-4 border-brownDark p-3 desktop:min-w-[480px] lgTablet:p-10 rounded shadow-avatar-card mb-1" style={{ backgroundColor: 'white' }}>
        <div className="lgTablet:space-y-10 flex lgTablet:block items-center gap-2">
          <div className="flex items-center">
            {ad?.postedBy?.photo?.Location ? (
              <Avatar
                src={ad?.postedBy?.photo?.Location}
                shape="circle"
                size="small"
                style={{ width: "120px", height: "120px" }}
              />
            ) : (
              <></>
            )}
            <div className="lgTablet:space-y-5">
              <p className="text-lg lgTablet:text-3xl m-0 font-medium text-primaryBlack">
                {ad.ownerFirstName + " " + ad.ownerLastName}
              </p>
              <p className="text-sm lgTablet:text-2xl font-roboto-flex text-grayy">
                Owner of {ad.title}
              </p>
            </div>
          </div>{" "}
          <div className="flex justify-content-center">
            <button
              className="bg-green w-full whitespace-nowrap text-white px-4 py-2 rounded-md  border-none text-[16px] "
              onClick={() => {
                setModel(1);
              }}
            >
              Contact Request
            </button>
          </div>
        </div>
      </div>

{/*       <div className="mt-10 cursor-pointer ">
        <div className="flex justify-content-center">
          <div
            className=""
            onClick={() => {
              setModel(2);
            }}
          >
            <p className="text-3xl m-0 font-normal font-roboto-flex text-brownDark text-center">
              Report this home
            </p>
          </div>
          {!ad.subscriptionType && (
            <div
              className="flex items-center "
              onClick={() => {
                setModel(3);
              }}
            >
              <h4 className="text-[14px]">Claim</h4>
            </div>
          )}
        </div>
      </div> */}

      {/* Contact Provider */}
      <Modal
        open={openModel === 1}
        onCancel={() => {
          setModel(0);
        }}
        footer={null}
      >
        {loggedIn ? (
          <>
            {existingRelationship ? (
              <div className="mt-3">
                <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mb-2">
                  Send Contact Request
                </h1>
                <p className="p-20px mb-5 text-grayy d-flex justify-content-center">
                  You've submitted a Contact Request to {ad.ownerFirstName}. Please continue the conversation in your Inbox.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="bg-green w-1/2 whitespace-nowrap text-white px-4 py-1 rounded-md  border-none text-[16px] "
                    onClick={handleInboxRedirect}
                  >
                    Inbox
                  </button>
                </div>
              </div>
            ) : (
              <div className="mt-3">
                <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mb-2">
                  Send Contact Request
                </h1>
                <p className="p-20px mb-5 text-grayy d-flex justify-content-center">
                  Request to connect with {ad.ownerFirstName}!
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  {!loading && (
                    <button
                      className="bg-green w-1/2 whitespace-nowrap text-white px-4 py-1 rounded-md  border-none text-[16px] "
                      onClick={handleConnectRequest}
                    >
                      Send Request
                    </button>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <RegisterFromListingForm
              ad={ad}
              setPostSignupMessage={setPostSignupMessage}
              setCareAssessmentComplete={setCareAssessmentComplete}
              careAssessmentComplete={careAssessmentComplete}
              setModel={setModel}
            />
          </>
        )}
      </Modal>

      {/* Report Listing */}
      <Modal
        open={openModel === 2}
        onCancel={() => {
          setModel(0);
        }}
        title="Report"
        footer={null}
      >
        {loggedIn ? (
          <>
            {existingReport ? (
              <>
                <p>
                  Thank you for submitting your report about this Senior Care
                  Home. Customer service will reach out concerning this if they
                  have not already.
                </p>
              </>
            ) : (
              <form>
                <p>
                  Create a report regarding this Senior Care Home for customer
                  service to review:
                </p>
                <div className="d-flex justify-content-center">
                  <textarea
                    rows="2"
                    cols="50"
                    placeholder="Enter your report here..."
                    value={reportText}
                    onChange={(e) => setReportText(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <button
                    type="button" // Use type="button" to prevent form submission
                    className="bg-lightBrown text-white px-3 py-1 rounded-md  border-none text-[16px]"
                    onClick={handleReportRequest}
                  >
                    Submit Report
                  </button>
                </div>
              </form>
            )}
          </>
        ) : (
          <>
            <p>
              Please <a href="/login">log in</a> or{" "}
              <a href="/register">sign up</a> to report this Senior Care Home.
            </p>
          </>
        )}
      </Modal>

      {/* Claim Listing */}
      <Modal
        open={openModel === 3}
        onCancel={() => {
          setModel(0);
        }}
        title="Claim"
        footer={null}
      >
        {loggedIn ? (
          <>
            {existingClaim ? (
              <>
                <p>
                  Thank you for submitting your claim for this Senior Care Home.
                  Customer service will reach out concerning this if they have
                  not already.
                </p>
              </>
            ) : (
              <form>
                <p>
                  Claim this Senior Care Home for customer service to review:
                </p>
                <div className="d-flex justify-content-center">
                  <textarea
                    rows="2"
                    cols="50"
                    placeholder="Claim details..."
                    value={claimText}
                    onChange={(e) => setClaimText(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <button
                    type="button" // Use type="button" to prevent form submission
                    className="bg-lightBrown text-white px-3 py-1 rounded-md  border-none text-[16px]"
                    onClick={handleClaimRequest}
                  >
                    Submit Claim
                  </button>
                </div>
              </form>
            )}
          </>
        ) : (
          <>
            <p>
              Please <a href="/login">log in</a> or{" "}
              <a href="/register">sign up</a> to claim this Senior Care Home.
            </p>
          </>
        )}
      </Modal>
    </div>
  );
}

export default OwnerProfile;

import { Link } from "react-router-dom";
import genericListing from "../../assets/images/house-pic-2.png";

import LikeUnlike from "../misc/LikeUnlike";
import { ReactComponent as LikeIcon } from "../../assets/icons/likeIconCard.svg";
import { FcLike } from "react-icons/fc";

export default function AdCardHome({ ad }) {
  return (
    <>
      <div className="bg-bgCard border-borderBlack shadow-card rounded-[20px] relative">
        <Link
          to={`/listing/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="rounded-[20px] w-full h-48 overflow-hidden">
            <img
              src={`${
                ad?.photos?.length > 0
                  ? ad?.photos?.[0].Location
                  : genericListing
              }`}
              alt="listing"
              className="w-full h-full object-cover rounded-[20px]"
            />
          </div>

          <div className="p-2.5 space-y-2.5">
            <h3
              className="text-sm lgTablet:text-3xl !font-normal ! text-primaryBlack max-w-[130px] min-w-[130px] lgTablet:max-w-sm	 truncate"
              title={ad?.title}
            >
              {ad?.title}
            </h3>
            <p
              className=" text-[#65463E] text-base mb-0 max-w-sm truncate hidden lgTablet:block"
              title={ad.address}
            >
              {ad?.googleMap[0]?.city}, {ad?.googleMap[0]?.administrativeLevels?.level1short}
            </p>
            <p className="text-[#65463E] text-base mb-0 max-w-sm truncate hidden lgTablet:block">
              Community Size: {ad.licensedBeds}
            </p>
            {/* <div className="flex items-center gap-2">
              {verifiedProvider === true ? <img src={shield} alt="img" /> : ""}

              {medicaidProvider === true ? <img src={wings} alt="img" /> : ""}
            </div> */}
          </div>
        </Link>
        <div className="absolute top-2.5 right-2.5">
          <LikeUnlike ad={ad}>
            {({ isLiked, handleLike, loggedIn, handleUnlike }) => (
              <>
                {loggedIn && isLiked && (
                  <button
                    onClick={handleUnlike}
                    className="flex items-center !gap-4 "
                  >
                    <FcLike />
                  </button>
                )}
                {(!loggedIn || !isLiked) && (
                  <button
                    onClick={handleLike}
                    className="flex items-center !gap-4 bg-bgCard rounded-full p-2"
                  >
                    <LikeIcon className="w-6 h-6" />
                  </button>
                )}
              </>
            )}
          </LikeUnlike>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { Form, Input, Checkbox } from "antd";

import tick from "../../assets/images/tick.svg";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import RecaptchaForm from "../auth/RecaptchaForm";
import { getCookie } from "../../helpers/utilities/Cookie";

function ContactForm() {
  const navigate = useNavigate();
  const [checkTerm, setCheckTerms] = useState(false);
  const [recaptchaPass, setRecaptchaPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const visitorId = getCookie("visitorId");

  const handleSubmit = async (values) => {
    if (!checkTerm) {
      return;
    }

    try {
      setLoading(true);

      const { data } = await axios.post(`/support/create-ticket/public`, {
        supportMessage: values.message,
        email: values.email,
        phone: values.phone,
        name: values.name,
        visitorId,
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        toast.success("Support ticket created.");
        setLoading(false);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Please try again.");
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        className="custom-form space-y-5 lgTablet:space-y-10"
      >
        <div className="space-y-2.5 lgTablet:space-y-4 text-left">
          <label
            htmlFor="#name"
            className="!text-lg lgTablet:!text-lg mb-0 font-roboto-flex"
          >
            Name
          </label>
          <Form.Item
            name="name"
            id="name"
            rules={[{ required: "true", message: "Name is required" }]}
          >
            <Input className="!border !border-green custom-input " />
          </Form.Item>
        </div>

        <div className="space-y-2.5 lgTablet:space-y-4 text-left">
          <label
            htmlFor="#phone"
            className="!text-lg lgTablet:!text-2xl font-roboto-flex"
          >
            Phone
          </label>
          <Form.Item
            name="phone"
            id="phone"
            rules={[
              { required: true, message: "Phone Number is required" },
              { type: "phone", message: "Please enter a valid phone number" },
            ]}
          >
            <Input className="custom-input" />
          </Form.Item>
        </div>

        <div className="space-y-2.5 lgTablet:space-y-4 text-left">
          <label
            htmlFor="#email"
            className="!text-lg lgTablet:!text-2xl font-roboto-flex"
          >
            Email
          </label>
          <Form.Item
            name="email"
            id="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "phone", message: "Please enter a valid email address" },
            ]}
          >
            <Input className="custom-input" />
          </Form.Item>
        </div>

        <div className="space-y-2.5 lgTablet:space-y-4 text-left">
          <label
            htmlFor="#message"
            className="!text-lg lgTablet:!text-2xl font-roboto-flex "
          >
            Message
          </label>
          <Form.Item name="message" id="message">
            <Input className="custom-input" />
          </Form.Item>
        </div>

        <div className="space-y-7">
          <div className="mt-[15px] d-flex justify-content-center">
            <RecaptchaForm setRecaptchaPass={setRecaptchaPass} />
          </div>

          <div className="flex-align-start md:flex-align-center">
            {/* <div
              className={`${
                checkTerm ? "bg-lightBrown" : "border-[2px] border-[grayy]"
              } flex-center w-[40px] h-[26px] xSm:w-[24px] xSm:h-[24px] rounded-full cursor-pointer`}
              onClick={() => setCheckTerms((prev) => !prev)}
            >
              <img
                src={tick}
                alt="tick"
                className={`${checkTerm ? "block" : "hidden"} w-[11px] h-[9px]`}
              />
            </div> */}
            <Checkbox />

            <span className="p-14px text-base italic text-grayy ml-[12px] text-left">
              By checking this box, you agree to our Terms of Service and
              Privacy Policy.
            </span>
          </div>
        </div>

        <div className="mt-[40px] ">
          <Form.Item>
            <div className="w-full flex justify-center">
              <button
                className="form-btn !bg-green "
                type="submit"
                disabled={!checkTerm || loading || !recaptchaPass}
              >
                {loading ? "Processing" : "Submit"}
              </button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

export default ContactForm;

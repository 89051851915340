import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { getCookie } from "./../../helpers/utilities/Cookie.js";
import { useAuth } from "../../context/auth.js";

import Logo from "../../components/misc/Logo";


export default function ProviderInviteSignup() {
    // hooks
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();
    const { token } = useParams();

    const [email, setEmail] = useState('');
    const [tokenValid, setTokenValid] = useState(false);
    const [tokenInvalidReason, setTokenInvalidReason] = useState('');
    const [clientName, setClientName] = useState('');
    const [loading, setLoading] = useState(false);


    const fetchRequest = async () => {
        setLoading(true);

        try {
            const { data } = await axios.post('/admin/requests/verify-out-of-network-provider-signup-link', {
                token,
            });

            if (data.success) {
                setTokenValid(true);
                setClientName(data?.data?.clientName);
            } else if (!data.success) {
                const expiredLinkMessage = data.messages.find(message => message.content === 'Out of Network Provider invite record link expired');
                if (expiredLinkMessage) {
                    setTokenInvalidReason("Invite link has expired. Please contact Customer Service to receive new signup link.");
                }

                const generalError = data.messages.find(message => message.content === 'There has been an error while searching for a signup link to out of network provider');
                if (generalError) {
                    setTokenInvalidReason("There has been an error while searching for a signup link to out of network provider");
                }

                const noRecordFound = data.messages.find(message => message.content === 'No Out of Network Provider invite record found');
                if (noRecordFound) {
                    setTokenInvalidReason("Invalid link.");
                    navigate('/');
                }
            }
            
            setLoading(false);
        } catch (error) {
            console.error(error);
            navigate('/');
            setLoading(false);
        }
    };
    
    const handleProviderSignUp = async (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            // Notify user of invalid email
            toast.error("Invalid email format");
            return;
        }

        try {
            setLoading(true);

            const { data } = await axios.post(`/register-invited-provider`, {
                token,
                email,
                visitorId: getCookie('visitorId'),
            });

            if (data?.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                setAuth(data);
                localStorage.setItem("auth", JSON.stringify(data));
                toast.success("Account Successfully Created!");
                setLoading(false);
                navigate("/provider/inbox");
            };
            
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.messages[0].content);
            setLoading(false);
        }
    };


    // checks token id
    useEffect(() => {
        fetchRequest();
    }, [token]);


    return (
        <div className="flex justify-between">
            <div className="w-full px-[1rem] sm:px-[1.5rem] lg:px-[4rem] 2xl:px-[8%] py-12 txt-left">
                <div className="block lgTablet:mr-auto mb-[3rem] d-flex justify-content-center">
                <Logo />
                </div>

                <h1 className="heading-48px text-primaryBlack mt-[3rem] d-flex justify-content-center">
                Connect with {clientName}
                </h1>

                {loading ? (
                    <div className="mt-12">
                        <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
                            Loading..
                        </p>
                    </div>
                ):(
                    <div className="mt-12">
                        {tokenValid ? (
                            <>
                                <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
                                    Please enter your email address to continue to your Inbox
                                </p>
                                <form onSubmit={(event) => handleProviderSignUp(event)} className="post-code-form">
                                    <div className="flex d-flex justify-content-center mb-2 mt-5">
                                        <div className="text-center w-1/2">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            className="custom-input"
                                            onChange={(event) => setEmail(event.target.value)}
                                            value={email}
                                            required
                                        />
                                        </div>
                                    </div>
                        
                                    <div className="d-flex justify-content-center">
                                        <div className="col col-lg-4 col-md-3 col-sm-1 d-none d-sm-block"></div>
                                        <div className="col col-lg-4 col-md-6 col-sm-10">
                                        <div className="row">
                                            <div className="mt-[40px]">
                                            <button className="form-btn mt-3" type="submit">
                                                Submit
                                            </button>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col col-lg-4 col-md-3 col-sm-1 d-none d-sm-block"></div>
                                    </div>
                                </form>
                            </>
                        ):(
                            <>
                            <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
                                Bad link
                            </p>
                            </>
                        )}
                    </div>
                )}
                
            </div>
        </div>
    );
}

import Logo from "./Logo";

export default function AuthLayout({
  children,
  bgClass,
  imageText,
  imageTextClass,
}) {
  return (
    <div>
      <div className="flex justify-between">
        <div className="w-full grow shrink basis-0 space-y-5 lgTablet:space-y-10">
          <div className="flex justify-center lgTablet:justify-start pt-1.5 lgTablet:px-10 lgTablet:pt-10">
            <Logo textClasses={"hidden lgTablet:block"} />
          </div>
          {children}
        </div>
        <div
          className={`grow shrink basis-0 h-screen sticky top-0 w-1/2 ${bgClass} bg-cover bg-center bg-no-repeat  hidden lgTablet:flex flex-col justify-between`}
        >
          <div className="h-full w-full bg-black/30 py-20 px-[1rem] sm:px-6 lg:px-8 2xl:px-[4%] flex items-end justify-center">
            <h1
              className={`text-white text-5xl font-medium mb-0 ${imageTextClass}`}
            >
              {imageText}
            </h1>
            {/*           <p className="p-20px text-white ml-2">
              Continue with Google account or custom Email & Password.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

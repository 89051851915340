import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const MapContainer = (props) => {
  return (
    // <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={{ lat: props.lat, lng: props.lng }}
      zoom={18}
    >
      <Marker position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
    // </LoadScript>
  );
};

export default MapContainer;

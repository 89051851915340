import { useState } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GOOGLE_PLACES_KEY } from "../../config";
import CurrencyInput, { formatValue } from 'react-currency-input-field';
import ImageUpload from "./ImageUpload";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {useAuth} from '../../context/auth';


export default function NewAssessmentForm() {
    const [auth, setAuth] = useAuth();
    
    const [ad, setAd] = useState({
        address: "",
        careNeeds: '',
        careReason: "",
        careStarted: "",
        seniorAware: "",
        checkRelationToClient: "self",
        responseRelationToClient: '',
        checkPOA: '',
        urgency: '',
        checkNurseAssessment: '',
        budgetAmount: '', //
        budgetSource_Private: '',
        budgetSource_Medicaid: '',
        budgetSource_Veteran: '',
        budgetSource_LTC: '',
        budgetSource_LifeInsurance: '',
        budgetSource_HomeEquity: '',
        budgetSource_Pension: '',
        budgetSource_Unknown: '',
        budgetSource_None: '',
        budgetSource_AdditionalCheck: '',
        budgetSource_AdditionalResponse: '',
        checkAdditionalPartner: '',
        responseAdditionalPartner: '',
        roomShared: '',
        roomPrivate: '',
        bathShared: '',
        bathPrivate: '',
        showerShared: '',
        showerPrivate: '',
        activities_activityCoordinator: '',
        activities_artsCrafts: '',
        activities_beautician: '',
        activities_birthdayCelebration: '',
        activities_cableTv: '',
        activities_smartTv: '',
        activities_emergencyCallButton: '',
        activities_tableGames: '',
        activities_houseGenerator: '',
        activities_hospitalBed: '',
        activities_hoyerLift: '',
        activities_wifi: '',
        activities_libraryOnWheels: '',
        activities_music: '',
        activities_newspaper: '',
        activities_outdoorCommonArea: '',
        activities_telephone: '',
        activities_tvInRoom: '',
        activities_wheelchairAccess: '',
        amentities_awakeNightStaff: '',
        amentities_twentyFourHourCare: '',
        amentities_twoCaregivers: '',
        amentities_threeCaregivers: '',
        amentities_catheter: '',
        amentities_colostomyBag: '',
        amentities_dementia: '',
        amentities_alzhiemers: '',
        amentities_developmentalDisabilities: '',
        amentities_diabetesCheck: '',
        amentities_diabetesInjectionNeeded: '',
        amentities_feedTube: '',
        amentities_femaleOnly: '',
        amentities_maleOnly: '',
        amentities_homeDoctor: '',
        amentities_hospice: '',
        amentities_housekeeping: '',
        amentities_laundry: '',
        amentities_massageTherapy: '',
        amentities_medicineDelivery: '',
        amentities_medicineManagement: '',
        amentities_ownerProvidesCare: '',
        amentities_oxygenTherapy: '',
        amentities_parkinson: '',
        amentities_petCare: '',
        amentities_podiatrist: '',
        amentities_physicalTherapy: '',
        amentities_respite: '',
        amentities_rn: '',
        amentities_stroke: '',
        amentities_tranDoctor: '',
        amentities_tranShopping: '',
        amentities_visualHearingImpaired: '',
        amentities_woundCare: '',
        checkCulturalNeed: '',
        responseCulturalNeed: '',
        checkDietNeeds: '',
        responseDietNeeds: '',
        checkRaceReligion: '',
        responseRaceReligion: '',
        checkEnjoyKids: '',
        responseEnjoyKids: '',
        checkPetAdverse: '',
        responsePetAdverse: '',
        checkSleepingHabits: '',
        responseSleepingHabits: '',
        checkWandering: '',
        responseWandering: '',
        checkCombative: '',
        responseCombative: '',
        checkOtherAgencies: '',
        responseOtherAgencies: '',
        loading: false,
    });

    
    const navigate = useNavigate();

    const handleClick = async () => {
        try {
            setAd({...ad, loading: true});
            const {data} = await axios.post('/new-site-assessment', ad);
            
            if(data?.error) {
                toast.error(data.error);
                setAd({...ad, loading: false});
            } else {
                

                // update user in context
                setAuth({...auth, user: data.user});

                // update user in local storage
                const fromLS = JSON.parse(localStorage.getItem('auth'));
                fromLS.user = data.user;

                localStorage.setItem('auth', JSON.stringify(fromLS));
                toast.success('Care Assessment Completed.');
                setAd({...ad, loading: false});
                // Reload the current page
                window.location.href = '/client/assessment';

            }
        } catch (error) {
            console.log(error);
            setAd({...ad, loading: false});
        }
    };

    
    
    return (
        <>
            <div style={{padding: '5%', color: 'black'}}>
                
                {/* Search Area */}
                <div className="mb-5">
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Desired Area</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <GooglePlacesAutocomplete 
                            apiKey= {GOOGLE_PLACES_KEY} 
                            apiOptions="us" 
                            required
                            selectProps={{
                                defaultInputValue: ad?.address,
                                placeholder: "City or Zipcode..",
                                onChange: ({value}) => {
                                    setAd({...ad, address: value.description});
                                },
                            }}
                        />
                    </div>
                </div>

                
                {/* Estimated Monthly Budget */}
                <div className="mb-5">
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Estimated Monthly Budget</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-1 d-flex align-items-center">
                            <p className="m-0">$</p>
                        </div>
                        <div className="col-11">
                            <CurrencyInput 
                                placeholder="Enter Amount.." 
                                defaultValue={ad.budgetAmount} 
                                required
                                className="form-control" 
                                onValueChange={(value) => setAd({...ad, budgetAmount: value})}
                            />
                        </div>
                    </div>
                </div>

                
                {/* Budget Sources */}
                <fieldset className="mb-5">
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Budget Sources (Select all Applicable)</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_Private" 
                                name="budgetSource_Private"
                                onChange={(e) => setAd({...ad, budgetSource_Private: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_Private">Private Pay</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_Medicaid" 
                                name="budgetSource_Medicaid"
                                onChange={(e) => setAd({...ad, budgetSource_Medicaid: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_Medicaid">Medicaid</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_Veteran:" 
                                name="budgetSource_Veteran:"
                                onChange={(e) => setAd({...ad, budgetSource_Veteran: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_Veteran:">Veteran Benefits</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_LTC" 
                                name="budgetSource_LTC"
                                onChange={(e) => setAd({...ad, budgetSource_LTC: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_LTC">Longterm Care Insurance</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_LifeInsurance" 
                                name="budgetSource_LifeInsurance"
                                onChange={(e) => setAd({...ad, budgetSource_LifeInsurance: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_LifeInsurance">Life Insurance</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_HomeEquity" 
                                name="budgetSource_HomeEquity"
                                onChange={(e) => setAd({...ad, budgetSource_HomeEquity: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_HomeEquity">Home Equity</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_Pension" 
                                name="budgetSource_Pension"
                                onChange={(e) => setAd({...ad, budgetSource_Pension: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_Pension">Pension</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_Unknown" 
                                name="budgetSource_Unknown"
                                onChange={(e) => setAd({...ad, budgetSource_Unknown: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_Unknown">I don't know</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_None" 
                                name="budgetSource_None"
                                onChange={(e) => setAd({...ad, budgetSource_None: e.target.value})}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_None">None</label>
                        <div className="col-3"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="budgetSource_AdditionalCheck" 
                                name="budgetSource_AdditionalCheck"
                                onChange={(e) => {
                                    setAd({...ad, budgetSource_AdditionalCheck: e.target.value})
                                }}
                            />
                        </div>
                        <label className='col-6 text-left' htmlFor="budgetSource_AdditionalCheck">Additional Sources</label>
                        <div className="col-3"></div>
                        {ad.budgetSource_AdditionalCheck === 'on' ? (
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Please describe.."
                                    value={ad.budgetSource_AdditionalResponse}
                                    onChange={(e) => setAd({...ad, budgetSource_AdditionalResponse: e.target.value})}
                                />
                        ) : ('')}
                    </div>
                </fieldset>

                

                {/* Level of Care */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, careNeeds: e.target.value})}
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Care Level Needed</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="contactChoice1" name="careNeedsOptions" value="noCare" />
                        </div>
                        <label className='col-9 text-left' htmlFor="contactChoice1">No Care Needs (Independent Living)</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="contactChoice2" name="careNeedsOptions" value="lightCare" />
                        </div>
                            <label className='col-9 text-left' htmlFor="contactChoice2">Light Care (medication, meals, laundry)</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="contactChoice3" name="careNeedsOptions" value="advancedCare" />
                        </div>
                            <label className='col-9 text-left' htmlFor="contactChoice3">Advanced Care (bathing, dressing, transferring)</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="contactChoice4" name="careNeedsOptions" value="memoryCare" />
                        </div>
                            <label className='col-9 text-left' htmlFor="contactChoice4">Memory Care (Alzheimers, Dementia, Wandering Risk)</label>
                    </div>
                </form>

                <div className="mb-5">
                    {/* Main Care Needs */}
                    <div className="row justify-content-center mb-2">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Care Needs</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <input 
                        type="text" 
                        className="form-control mb-4 justify-content-center" 
                        placeholder="What are the main care needs?"
                        value={ad.careReason}
                        onChange={(e) => setAd({...ad, careReason: e.target.value})}
                    />

                    {/* Care Needs Noticed */}
                    <input 
                        type="text" 
                        className="form-control mb-4 justify-content-center" 
                        placeholder="When were these needs first noticed?"
                        value={ad.careStarted}
                        onChange={(e) => setAd({...ad, careStarted: e.target.value})}
                    />
                </div>

                {/* Relationship to Client */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkRelationToClient: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>What is your relationship to the client?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="relation1" name="relationClient" value="self" />
                        </div>
                        <label className='col-9 text-left' htmlFor="relation1">Self</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="relation2" name="relationClient" value="partner" />
                        </div>
                        <label className='col-9 text-left' htmlFor="relation2">Spouse/Partner</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="relation3" name="relationClient" value="parent" />
                        </div>
                        <label className='col-9 text-left' htmlFor="relation3">Parent</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="relation4" name="relationClient" value="other" />
                        </div>
                        <label className='col-9 text-left' htmlFor="relation4">Other</label>
                    </div>
                    {ad?.checkRelationToClient === 'other' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain how you know the client."
                            value={ad.responseRelationToClient}
                            onChange={(e) => setAd({...ad, responseRelationToClient: e.target.value})}
                        />
                    ) : ('')}
                </form>

                
                {ad?.checkRelationToClient !== "self" ? (
                    <form 
                        className="mb-5"
                        onChange={(e) => setAd({...ad, seniorAware: e.target.value})}
                        required
                    >
                        <div className="row mb-3 d-flex justify-content-center">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <h4>Is the client aware that care is being sought?</h4>
                            </div>
                            <div className="col-1"></div>
                        </div>

                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-3 text-right">
                                <input type="radio" id="clientAware1" name="clientAware" value="yes" />
                            </div>
                            <label className='col-9 text-left' htmlFor="clientAware1">Yes</label>
                        </div>

                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-3 text-right">
                                <input type="radio" id="clientAware2" name="clientAware" value="no" />
                            </div>
                            <label className='col-9 text-left' htmlFor="clientAware2">No</label>
                        </div>
                    </form>
                ) : ('')}

                {/* Power of Attorney */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkPOA: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Do you legally represent the client? (Power of Attorney)</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="pOA1" name="pOA" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="pOA1">Yes</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="pOA2" name="pOA" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="pOA2">No</label>
                    </div>
                </form>

                
                {/* Urgency */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, urgency: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>How soon is care needed?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="urgency1" name="reurgencylationClient" value="asap" />
                        </div>
                        <label className='col-9 text-left' htmlFor="urgency1">As Soon As Possible</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="urgency2" name="urgency" value="lessOneMonth" />
                        </div>
                        <label className='col-9 text-left' htmlFor="urgency2">Within 1 month</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="urgency3" name="urgency" value="oneThreeMonth" />
                        </div>
                        <label className='col-9 text-left' htmlFor="urgency3">1 to 3 months</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="urgency4" name="urgency" value="moreThreeMonth" />
                        </div>
                        <label className='col-9 text-left' htmlFor="urgency4">3 months or more</label>
                    </div>
                </form>
                

                {/* Nurse Assessment */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkNurseAssessment: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Have you completed a nurse assessment within the past 6 months?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="nAssess1" name="nAssess" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="nAssess1">Yes</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="nAssess2" name="nAssess" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="nAssess2">No</label>
                    </div>
                </form>

                
                {/* Additional Partner */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkAdditionalPartner: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Is there a spouse or partner moving with the client?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="aPartner1" name="aPartner" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="aPartner1">Yes</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="aPartner2" name="aPartner" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="aPartner2">No</label>
                    </div>
                    {ad?.checkAdditionalPartner === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Who else will be moving in with the client?"
                            value={ad.responseAdditionalPartner}
                            onChange={(e) => setAd({...ad, responseAdditionalPartner: e.target.value})}
                        />
                    ) : ('')}
                </form>
                

                {/* Accomodations */}
                <fieldset className="mb-5">
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Desired Accomodations</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="roomShared" 
                                name="roomShared"
                                onChange={(e) => setAd({...ad, roomShared: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="roomShared">Room: Shared</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="roomPrivate" 
                                name="roomPrivate"
                                onChange={(e) => setAd({...ad, roomPrivate: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="roomPrivate">Room: Private</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="bathShared:" 
                                name="bathShared:"
                                onChange={(e) => setAd({...ad, bathShared: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="bathShared:">Bathroom: Shared</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="bathPrivate" 
                                name="bathPrivate"
                                onChange={(e) => setAd({...ad, bathPrivate: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="bathPrivate">Bathroom: Private</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="showerShared" 
                                name="showerShared"
                                onChange={(e) => setAd({...ad, showerShared: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="showerShared">Shower: Shared</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="showerPrivate" 
                                name="showerPrivate"
                                onChange={(e) => setAd({...ad, showerPrivate: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="showerPrivate">Shower: Private</label>
                    </div>
                </fieldset>
                

                {/* Activities */}
                <fieldset className="mb-5">
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Desired Activities</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_activityCoordinator" 
                                name="activities_activityCoordinator"
                                onChange={(e) => setAd({...ad, activities_activityCoordinator: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_activityCoordinator">Activity Coordinator</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_artsCrafts" 
                                name="activities_artsCrafts"
                                onChange={(e) => setAd({...ad, activities_artsCrafts: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_artsCrafts">Arts & Crafts</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_beautician:" 
                                name="activities_beautician:"
                                onChange={(e) => setAd({...ad, activities_beautician: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_beautician:">Beautician</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_birthdayCelebration" 
                                name="activities_birthdayCelebration"
                                onChange={(e) => setAd({...ad, activities_birthdayCelebration: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_birthdayCelebration">Birthday Celebrations</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_cableTv" 
                                name="activities_cableTv"
                                onChange={(e) => setAd({...ad, activities_cableTv: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_cableTv">Cable TV</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_smartTv" 
                                name="activities_smartTv"
                                onChange={(e) => setAd({...ad, activities_smartTv: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_smartTv">Smart TV</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_emergencyCallButton" 
                                name="activities_emergencyCallButton"
                                onChange={(e) => setAd({...ad, activities_emergencyCallButton: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_emergencyCallButton">Emergency Call Button</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_tableGames" 
                                name="activities_tableGames"
                                onChange={(e) => setAd({...ad, activities_tableGames: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_tableGames">Table Games</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_houseGenerator" 
                                name="activities_houseGenerator"
                                onChange={(e) => setAd({...ad, activities_houseGenerator: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_houseGenerator">House Backup Generator</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_hospitalBed" 
                                name="activities_hospitalBed"
                                onChange={(e) => setAd({...ad, activities_hospitalBed: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_hospitalBed">Hospital/Electric Bed</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_hoyerLift" 
                                name="activities_hoyerLift"
                                onChange={(e) => setAd({...ad, activities_hoyerLift: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_hoyerLift">Hoyer Lift</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_wifi" 
                                name="activities_wifi"
                                onChange={(e) => setAd({...ad, activities_wifi: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_wifi">Wireless Internet</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_libraryOnWheels" 
                                name="activities_libraryOnWheels"
                                onChange={(e) => setAd({...ad, activities_libraryOnWheels: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_libraryOnWheels">Library on Wheels</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_music" 
                                name="activities_music"
                                onChange={(e) => setAd({...ad, activities_music: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_music">Music</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_newspaper" 
                                name="activities_newspaper"
                                onChange={(e) => setAd({...ad, activities_newspaper: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_newspaper">Newspaper</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_outdoorCommonArea" 
                                name="activities_outdoorCommonArea"
                                onChange={(e) => setAd({...ad, activities_outdoorCommonArea: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_outdoorCommonArea">Outdoor Common Area</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_telephone" 
                                name="activities_telephone"
                                onChange={(e) => setAd({...ad, activities_telephone: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_telephone">Community Telephone</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_tvInRoom" 
                                name="activities_tvInRoom"
                                onChange={(e) => setAd({...ad, activities_tvInRoom: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_tvInRoom">TV in Room</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="activities_wheelchairAccess" 
                                name="activities_wheelchairAccess"
                                onChange={(e) => setAd({...ad, activities_wheelchairAccess: e.target.value})}
                            />
                        </div>
                        <label className='col-9 text-left' htmlFor="activities_wheelchairAccess">Wheelchair Access</label>
                    </div>
                </fieldset>


                {/* Amenities */}
                <fieldset className="mb-5">
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Desired Amenities</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_awakeNightStaff" 
                                name="amentities_awakeNightStaff"
                                onChange={(e) => setAd({...ad, amentities_awakeNightStaff: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_awakeNightStaff">Awake Night Staff</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_twentyFourHourCare" 
                                name="amentities_twentyFourHourCare"
                                onChange={(e) => setAd({...ad, amentities_twentyFourHourCare: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_twentyFourHourCare">24 Hour Care</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_twoCaregivers:" 
                                name="amentities_twoCaregivers:"
                                onChange={(e) => setAd({...ad, amentities_twoCaregivers: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_twoCaregivers:">2 Caregivers on Shift</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_threeCaregivers" 
                                name="amentities_threeCaregivers"
                                onChange={(e) => setAd({...ad, amentities_threeCaregivers: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_threeCaregivers">3 Caregivers on Shift</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_catheter" 
                                name="amentities_catheter"
                                onChange={(e) => setAd({...ad, amentities_catheter: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_catheter">Catheter Care</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_colostomyBag" 
                                name="amentities_colostomyBag"
                                onChange={(e) => setAd({...ad, amentities_colostomyBag: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_colostomyBag">Colostomy Bag Care</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_dementia" 
                                name="amentities_dementia"
                                onChange={(e) => setAd({...ad, amentities_dementia: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_dementia">Dementia</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_alzhiemers" 
                                name="amentities_alzhiemers"
                                onChange={(e) => setAd({...ad, amentities_alzhiemers: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_alzhiemers">Alzhiemers</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_developmentalDisabilities" 
                                name="amentities_developmentalDisabilities"
                                onChange={(e) => setAd({...ad, amentities_developmentalDisabilities: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_developmentalDisabilities">Developmental Disabilities</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_diabetesCheck" 
                                name="amentities_diabetesCheck"
                                onChange={(e) => setAd({...ad, amentities_diabetesCheck: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_diabetesCheck">Type A/B Diabetes</label>
                    </div>

                    {ad?.amentities_diabetesCheck === "on" ? (
                        <form 
                            onChange={(e) => setAd({...ad, amentities_diabetesInjectionNeeded: e.target.value})}
                            required
                        >
                            <div className="row mb-2 mt-4 d-flex justify-content-center">
                                <h6>Insulin Injection Needed?</h6>
                            </div>
                            <div className="row d-flex justify-content-center mb-2">
                                <div className="col-3 text-right">
                                    <input type="radio" id="idCare1" name="idCare" value="on" />
                                </div>
                                <label className='col-9 text-left' htmlFor="idCare1">Yes</label>
                            </div>
                            <div className="row d-flex justify-content-center mb-4">
                                <div className="col-3 text-right">
                                    <input type="radio" id="idCare2" name="idCare" value="" />
                                </div>
                                <label className='col-9 text-left' htmlFor="idCare2">No</label>
                            </div>
                        </form>
                    ) : ('')}

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_feedTube" 
                                name="amentities_feedTube"
                                onChange={(e) => setAd({...ad, amentities_feedTube: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_feedTube">Feed Tube Care</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_femaleOnly" 
                                name="amentities_femaleOnly"
                                onChange={(e) => setAd({...ad, amentities_femaleOnly: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_femaleOnly">Female Only Residents</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_maleOnly" 
                                name="amentities_maleOnly"
                                onChange={(e) => setAd({...ad, amentities_maleOnly: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_maleOnly">Male Only Residents</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_homeDoctor" 
                                name="amentities_homeDoctor"
                                onChange={(e) => setAd({...ad, amentities_homeDoctor: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_homeDoctor">Home Doctor Visits</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_hospice" 
                                name="amentities_hospice"
                                onChange={(e) => setAd({...ad, amentities_hospice: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_hospice">Hospice Care</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_housekeeping" 
                                name="amentities_housekeeping"
                                onChange={(e) => setAd({...ad, amentities_housekeeping: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_housekeeping">Housekeeping Service</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_laundry" 
                                name="amentities_laundry"
                                onChange={(e) => setAd({...ad, amentities_laundry: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_laundry">Laundry Service</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_massageTherapy" 
                                name="amentities_massageTherapy"
                                onChange={(e) => setAd({...ad, amentities_massageTherapy: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_massageTherapy">Massage Therapy</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_medicineDelivery" 
                                name="amentities_medicineDelivery"
                                onChange={(e) => setAd({...ad, amentities_medicineDelivery: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_medicineDelivery">Medicine Delivery</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_medicineManagement" 
                                name="amentities_medicineManagement"
                                onChange={(e) => setAd({...ad, amentities_medicineManagement: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_medicineManagement">Medicine Management</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_ownerProvidesCare" 
                                name="amentities_ownerProvidesCare"
                                onChange={(e) => setAd({...ad, amentities_ownerProvidesCare: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_ownerProvidesCare">Owner Caregiver</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_oxygenTherapy" 
                                name="amentities_oxygenTherapy"
                                onChange={(e) => setAd({...ad, amentities_oxygenTherapy: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_oxygenTherapy">Oxygen Therapy</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_parkinson" 
                                name="amentities_parkinson"
                                onChange={(e) => setAd({...ad, amentities_parkinson: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_parkinson">Parkinson</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_petCare" 
                                name="amentities_petCare"
                                onChange={(e) => setAd({...ad, amentities_petCare: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_petCare">Pet Care</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_podiatrist" 
                                name="amentities_podiatrist"
                                onChange={(e) => setAd({...ad, amentities_podiatrist: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_podiatrist">Podiatrist</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_physicalTherapy" 
                                name="amentities_physicalTherapy"
                                onChange={(e) => setAd({...ad, amentities_physicalTherapy: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_physicalTherapy">Physical/Sports Therapy</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_respite" 
                                name="amentities_respite"
                                onChange={(e) => setAd({...ad, amentities_respite: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_respite">Respite Care (Short Term Care)</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_rn" 
                                name="amentities_rn"
                                onChange={(e) => setAd({...ad, amentities_rn: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_rn">Registered Nurse Operated</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_stroke" 
                                name="amentities_stroke"
                                onChange={(e) => setAd({...ad, amentities_stroke: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_stroke">Stroke Recovery</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_tranDoctor" 
                                name="amentities_tranDoctor"
                                onChange={(e) => setAd({...ad, amentities_tranDoctor: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_tranDoctor">Transporation to Doctor's Office</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_tranShopping" 
                                name="amentities_tranShopping"
                                onChange={(e) => setAd({...ad, amentities_tranShopping: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_tranShopping">Transporation to Shopping Centers</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_visualHearingImpaired" 
                                name="amentities_visualHearingImpaired"
                                onChange={(e) => setAd({...ad, amentities_visualHearingImpaired: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_visualHearingImpaired">Visual/Hearing Impaired</label>
                    </div>

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input 
                                type="checkbox" 
                                id="amentities_woundCare" 
                                name="amentities_woundCare"
                                onChange={(e) => setAd({...ad, amentities_woundCare: e.target.value})}
                            />
                         </div>
                        <label className='col-9 text-left' htmlFor="amentities_woundCare">Wound Care</label>
                    </div>

                </fieldset>

                
                {/* Cultural Needs */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkCulturalNeed: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Any particular cultural or language needs?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="culNeeds1" name="culNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="culNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="culNeeds2" name="culNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="culNeeds2">No</label>
                    </div>
                    {ad?.checkCulturalNeed === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responseCulturalNeed}
                            onChange={(e) => setAd({...ad, responseCulturalNeed: e.target.value})}
                        />
                    ) : ('')}
                </form>
                

                {/* Diet Needs */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkDietNeeds: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Any particular diet or food needs?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="dietNeeds1" name="dietNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="dietNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="dietNeeds2" name="dietNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="dietNeeds2">No</label>
                    </div>
                    {ad?.checkDietNeeds === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responseDietNeeds}
                            onChange={(e) => setAd({...ad, responseDietNeeds: e.target.value})}
                        />
                    ) : ('')}
                </form>


                {/* Religion Needs */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkRaceReligion: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Any racial or religous concerns/prejudices?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="relNeeds1" name="relNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="relNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="relNeeds2" name="relNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="relNeeds2">No</label>
                    </div>
                    {ad?.checkRaceReligion === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responseRaceReligion}
                            onChange={(e) => setAd({...ad, responseRaceReligion: e.target.value})}
                        />
                    ) : ('')}
                </form>


                {/* Child Restrictions */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkEnjoyKids: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Any issues with having children around?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="childNeeds1" name="childNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="childNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="childNeeds2" name="childNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="childNeeds2">No</label>
                    </div>
                    {ad?.checkEnjoyKids === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responseEnjoyKids}
                            onChange={(e) => setAd({...ad, responseEnjoyKids: e.target.value})}
                        />
                    ) : ('')}
                </form>


                {/* Pet Restrictions */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkPetAdverse: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Any issues with having pets(dogs, cats, etc) around?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="petNeeds1" name="petNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="petNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="petNeeds2" name="petNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="petNeeds2">No</label>
                    </div>
                    {ad?.checkPetAdverse === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responsePetAdverse}
                            onChange={(e) => setAd({...ad, responsePetAdverse: e.target.value})}
                        />
                    ) : ('')}
                </form>


                {/* Sleeping Habits */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkSleepingHabits: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Any particular sleeping habits provider should be aware of? (wakes up frequently, napping throughout day, etc..)</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="sleepNeeds1" name="sleepNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="sleepNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="sleepNeeds2" name="sleepNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="sleepNeeds2">No</label>
                    </div>
                    {ad?.checkSleepingHabits === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responseSleepingHabits}
                            onChange={(e) => setAd({...ad, responseSleepingHabits: e.target.value})}
                        />
                    ) : ('')}
                </form>


                {/* Wandering */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkWandering: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Does the client wander or exit seeking?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="wanderNeeds1" name="wanderNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="wanderNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="wanderNeeds2" name="wanderNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="wanderNeeds2">No</label>
                    </div>
                    {ad?.checkWandering === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responseWandering}
                            onChange={(e) => setAd({...ad, responseWandering: e.target.value})}
                        />
                    ) : ('')}
                </form>


                {/* Wandering */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkCombative: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Is the client combative or agressive?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="combatNeeds1" name="combatNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="combatNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="combatNeeds2" name="combatNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="combatNeeds2">No</label>
                    </div>
                    {ad?.checkCombative === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please explain..."
                            value={ad.responseCombative}
                            onChange={(e) => setAd({...ad, responseCombative: e.target.value})}
                        />
                    ) : ('')}
                </form>


                {/* Working with others? */}
                <form 
                    className="mb-5"
                    onChange={(e) => setAd({...ad, checkOtherAgencies: e.target.value})}
                    required
                >
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <h4>Are you and/or the client currently working with other agencies?</h4>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="agencyNeeds1" name="agencyNeeds" value="yes" />
                        </div>
                        <label className='col-9 text-left' htmlFor="agencyNeeds1">Yes</label>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-3 text-right">
                            <input type="radio" id="agencyNeeds2" name="agencyNeeds" value="no" />
                        </div>
                        <label className='col-9 text-left' htmlFor="agencyNeeds2">No</label>
                    </div>
                    {ad?.checkOtherAgencies === 'yes' ? (
                        <input 
                            type="text" 
                            className="form-control mb-2" 
                            placeholder="Please list out who else you're working with."
                            value={ad.responseOtherAgencies}
                            onChange={(e) => setAd({...ad, responseOtherAgencies: e.target.value})}
                        />
                    ) : ('')}
                </form>


                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8 d-flex justify-content-center">
                        <button onClick={handleClick} className={`btn btn-primary btn-sm col-12 p-2 mb-2 ${ad.loading ? "disabled" : ""}` } style={{ textTransform: "capitalize" }}>
                            {ad.loading ? 'Saving...' : 'Submit'}
                        </button>
                    </div>
                    <div className="col-2"></div>
                </div>
                
            </div>
        </>
    )
}
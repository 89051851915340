import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import axios from "axios";
import GOOGLE_RECAPTCHA_SCORE_THRESHOLD from '../../config.js';

function RecaptchaForm({ setRecaptchaPass }) {
  
  const [loading, setLoading] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value === null) {
      setRecaptchaPass(false);
    } else {
      checkRecaptchaVerification(value);
    }
  };

  const checkRecaptchaVerification = async (value) => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `/security/verify-captcha-session/${value}`
      );

      // !!!! dev bypass due to Google Auth issues for localhost IP validation. Disable before push to Staging & Prod !!!!
      // setRecaptchaPass(true);
      // !!!!!!!!!!

      if (data?.score >= 0.75) {
        setRecaptchaPass(true);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Please try again or contact support.");
      setLoading(true);
    }
  };

  return (
    <div>
      <ReCAPTCHA
        sitekey="6LfvAAopAAAAALH2zGbrz1RFQKFTodbhVrLgMVRs"
        data-action='login'
        onChange={handleRecaptchaChange}
      />
    </div>
  );
}

export default RecaptchaForm;

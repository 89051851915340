import React, { useEffect, useState } from "react";
import google from "../../assets/images/google icon.png";
import apple from "../../assets/images/apple.png";
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_LOGIN_CLIENT_ID } from "../../config";
import { useAuth } from "../../context/auth";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../helpers/utilities/Cookie";

function SocialLogin() {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: GOOGLE_LOGIN_CLIENT_ID,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const onGoogleSuccess = async (info) => {
    try {
      setLoading(true);
      const { data } = await axios.post(`/login`, {
        credentialType: "google",
        email: info.profileObj.email,
        password: info.profileObj.googleId,
        visitorId: getCookie("visitorId"),
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        setAuth(data);
        localStorage.setItem("auth", JSON.stringify(data));
        toast.success("Login Successful.");
        setLoading(false);

        if (data?.user?.role === "Client") {
          location?.state !== null
              ? navigate(location.state)
              : navigate("/client/engagements");
        }

        if (data?.user?.role === "Provider") {
          if (data?.user?.identity?.googleGeocoder?.formattedAddress === null) {
            location?.state !== null
              ? navigate(location.state)
              : navigate("/provider/listings");
          } else {
            location?.state !== null
              ? navigate(location.state)
              : navigate("/provider/inbox");
          }
        }

        if (data?.user?.role === "Admin") {
          location?.state !== null
            ? navigate(location.state)
            : navigate("/admin/manage-providers");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Please try again.");
      setLoading(false);
    }
  };

  const onGoogleFailure = (res) => {
    toast.error(
      "Unable to sign in with Google. Please try another sign in method or contact support."
    );
  };

  return (
    <>
      <div className="">
        {/* google login  */}
        <GoogleLogin
          clientId={GOOGLE_LOGIN_CLIENT_ID}
          render={(renderProps) => (
            <div className="border-[1.5px] rounded-2xl.5 flex-center py-4 h-[70px]">
              <div
                className="flex-align-center cursor-pointer"
                onClick={renderProps.onClick}
              >
                <img src={google} alt="google" className="w-8 mr-4" />
                <h4 className="p-20px text-primaryBlack font-roboto-flex">
                  Continue with Google
                </h4>
              </div>
            </div>
          )}
          buttonText="Login"
          onSuccess={onGoogleSuccess}
          onFailure={onGoogleFailure}
          cookiePolicy={"single_host_origin"}
        />
        {/* react-google-login  */}
        {/*         <div className="border-[1.5px] rounded-xl flex-center py-4 h-[70px] mt-4">
          <div className="flex-align-center">
            <img src={apple} alt="google" className="w-8 mr-4" />
            <h4 className="p-20px text-primaryBlack">Continue using Apple</h4>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default SocialLogin;

import {useState, useEffect} from 'react';
import Sidebar from '../../components/nav/Sidebar';
import {useAuth} from "../../context/auth";
import axios from 'axios';
import AdCardHome from '../../components/cards/AdCardHome';



export default function Wishlist() {
    const [auth, setAuth] = useAuth();

    const [ads, setAds] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAds();
    }, [auth.token !== '']);

    const fetchAds = async () => {
        try {
            const {data} = await axios.get('/wishlist');
            setAds(data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <h1 className='display-4 bg-primary text-light p-2'>Wishlist</h1>
            <Sidebar />

            {!ads?.length ? (
                <div 
                    className='d-flex justify-content-center align-items-center vh-100' 
                    style={{marginTop: "-10%"}}
                >
                    <h2>Hi {auth.user?.name ? auth.user?.name : auth.user?.username}, 
                    you have no listings saved to your wishlist.</h2>
                </div>
            ) : (
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2'>
                            <p className='text-center'>You have liked {ads?.length} listings.</p>
                        </div>
                    </div>

                    <div className='row'>
                        {ads?.map(ad => (
                            <AdCardHome ad={ad} key={ad._id}/>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}


import React, { useEffect } from "react";
import anchor1 from "../assets/images/anchor1.png";
import anchor2 from "../assets/images/anchor2.jpg";
import anchor3 from "../assets/images/anchor3.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnchorCard from "../components/cards/anchorCard";

function AnchorClient() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Find Senior Care Providers In Your Neighborhood</title>
        <meta
          name="description"
          content="A user-friendly platform tailored for families seeking quality assisted 
              living and senior care options."
        />
      </Helmet>
      <div className="bg-anchor-client-bg bg-cover bg-no-repeat bg-center w-full  flex-center">
        <div className="bg-black/50 w-full py-24 flex items-center justify-center">
          <div className="max-container">
            <div className="px-[1rem] sm:px-[4rem] md:px-28 lg:px-40 text-center">
              <h1 className="text-2xl lgTablet:text-5xl  text-white text-center">
                Discover Trusted Senior Care Homes Near You
              </h1>
              <p className="text-base lgTablet:text-2xl font-normal text-white text-center px-[10px] sm:px-[2.5rem] md:px-20 xl:px-56 mt-[1.5rem]">
                Easily find, compare, and connect with the best senior care
                homes in your area
              </p>

              <Link to="/client-care-assessment">
                <button className="btn-sm bg-green text-white border-none">
                  Connect with Providers
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="max-container my-[3rem]">
        <div className="max-container  lgTablet:bg-bgPink !p-5 lgTablet:!p-10 space-y-16 rounded-2xl.5">
          <AnchorCard
            heading="Why Use SeniorCareHomes.org?"
            para="SeniorCareHomes.org simplifies your search for quality senior care homes, saving you time and stress. Our platform offers detailed listings and direct connections to providers, ensuring you find the perfect home for your loved one."
            image={anchor1}
          />{" "}
          <AnchorCard
            heading="How Our Process Works"
            para="Start by completing a quick, confidential care assessment to match your needs with the best senior care homes. Explore options by city or zip code, connect directly with providers, and schedule tours—all with just a few clicks."
            image={anchor2}
            reverse
          />{" "}
          <AnchorCard
            heading="Get Started with SeniorCareHomes.org"
            para="No matter your budget or specific needs, SeniorCareHomes.org helps you find the right care home. Join our community today and make the transition smoother for your loved ones."
            image={anchor3}
          />
          <div className="flex items-center justify-center">
            <Link
              className="btn-sm block w-fit min-w-[300px] text-center bg-green text-white border-none"
              to="/client-care-assessment"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnchorClient;

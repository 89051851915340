import {Link} from 'react-router-dom';



export default function Provider_MessagerCardContacts({allThreads}) {

    return (
        <div className="col p-2 gx-4 gy-4">
            <Link to={`/provider/inbox/?thread=${allThreads._id}`}>
                <div className="card hoverable shadow">
                    <div className="card-body">
                        <h5>{allThreads?.client_name}</h5>
                        <p className='card-text'>{`${allThreads?.listing_name}`}</p>
                    </div>
                </div>
            </Link>
        </div>
    );
}
import logoDark from "../../assets/images/logo.svg";
import SocialLogin from "../../components/auth/SocialLogin";
import LoginForm from "../../components/forms/LoginForm";
import Logo from "../../components/misc/Logo";
import { Helmet } from "react-helmet";
import AuthLayout from "../../components/misc/authLayout";

const LogIn = () => {
  return (
    <>
      <Helmet>
        <title>Sign In</title>
        <meta
          name="description"
          content="Sign In to your account at SeniorCareHomes.Org."
        />
      </Helmet>
      <AuthLayout bgClass="bg-login-left" imageText="Welcome Back">
        <div className="!px-5 lgTablet:!px-10 desktop:!px-28 space-y-10">
          <div className="space-y-2.5 lgTablet:space-y-10">
            <h1 className="text-lg lgTablet:text-2xl desktop:text-5xl text-left font-bold lgTablet:font-medium text-primaryBlack">
              Login to your Account
            </h1>
            <h4 className="text-sm lgTablet:text-lg desktop:text-2xl text-grayy font-roboto-flex font-normal">
              Login to your existing account using your Google account, phone
              number, or email address
            </h4>
          </div>
          <div className="">
            <SocialLogin />
          </div>
          <div className="flex gap-3 items-center justify-between">
            <div className="h-[1px] bg-lightGray w-full" />
            <p className="m-0 text-primaryBlack text-xl italic">or</p>
            <div className="h-[1px] bg-lightGray w-full" />
          </div>
          <div className="">
            <LoginForm />
          </div>
        </div>
      </AuthLayout>
    </>
  );
};
export default LogIn;

import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

export default function TermsOfUse() {
    // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

    return (
        <>
			<Helmet>
				<title>Terms of Use</title>
				<meta name="description" content="Terms of Use at SeniorCareHomes.Org" />
			</Helmet>
			<h1 className="mt-4">Terms of Use</h1>
			<br/>
            <p>Effective January 1, 2024
			<br/>
			<br/>
PLEASE READ THESE TERMS AND CONDITIONS OF USE (“TERMS OF USE”) CAREFULLY. YOUR USE OF THIS WEB SITE AS WELL AS ALL OTHER WEB SITES OWNED AND/OR OPERATED BY SENIORCAREHOMES.ORG (INDIVIDUALLY AND COLLECTIVELY, “SITE” OR “SITES”) AND/OR THE SERVICES PROVIDED BY SENIORCAREHOMES.ORG THROUGH OR IN CONNECTION WITH THE SITES (INCLUDING SENIOR HOUSING AND CARE MARKETING AND INFORMATION SERVICES (“SERVICES”)) IS CONDITIONED UPON YOUR ACCEPTANCE OF THESE TERMS OF USE WITHOUT MODIFICATION. BY CHECKING THE BOX ACCOMPANYING THE LINK BY WHICH YOU ARRIVED AT THESE TERMS OR BY ACCESSING OR USING OUR SITES OR SERVICES, YOU AGREE TO BE BOUND BY THE TERMS OF USE AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO THE TERMS OF USE, DO NOT USE THE SITES AND/OR THE SERVICES.
			<br/>
			<br/>
PLEASE NOTE THAT THESE TERMS OF USE CONTAIN A MANDATORY AND BINDING ARBITRATION, CLASS ACTION WAIVER, AND WAIVER OF RIGHT TO JURY TRIAL CLAUSE, WHICH AFFECTS YOUR RIGHTS REGARDING HOW TO RESOLVE ANY DISPUTE WITH SENIORCAREHOMES.ORG. PLEASE READ IT CAREFULLY.
			<br/>
			<br/>
The Sites and the Services are provided by Senior Care Homes Org, LLC. (“SCHO”).
			<br/>
			<br/>
The Terms of Use apply to your access to, and use of, the Sites and/or the Services, so please read them carefully.
			<br/>
			<br/>
We reserve the right to change or modify any of the Terms of Use, the Sites, and/or the Services at any time. If we decide to change our Terms of Use, we will notify you by revising the date at the top of the Terms of Use and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). Your use of the Sites and/or the Services following the posting of changes or modifications to the Terms of Use will constitute your acceptance of the revised Terms of Use. Therefore, you should review the Terms of Use and applicable policies from time to time to understand the terms and conditions that apply to your use of the Sites and/or Services. If you do not agree to the amended terms, you must immediately stop using the Sites and/or Services. To the extent that notice is required under any applicable law or otherwise, you waive the right to such notice, provided that this sentence will not apply to you if you are a Washington State resident or to the extent you are seeking Services in Washington State.
			<br/>
			<br/>
The Terms of Use do not alter in any way the terms or conditions of any other agreement you may have with SCHO for services, products, or otherwise. Your access to, and use of, the Sites may also be subject to any legal notices or additional terms and conditions applicable to the Sites and/or Services, in addition to these Terms of Use, (“Additional Terms”). Where Additional Terms apply to the Services, the Additional Terms will be accessible for you to read through your use of the Services and/or provided separately. These Terms of Use, together with the Additional Terms, form a legally binding agreement between you and SCHO in relation to your use of the Sites and/or Services. It is important that you take the time to read them carefully. If there is any contradiction between what the Additional Terms say and what these Terms of Use say with respect to the Services, then the Additional Terms shall take precedence in relation to that Service.
			<br/>
			<br/>
To use the Sites and/or Services, you must be of legal age to enter into a binding contract and not be prohibited from using the Sites and/or Services by the laws of the jurisdiction from which you access the Sites and/or Services.
			<br/>
			<br/>
			<br/>
OUR SERVICES
			<br/>
SeniorCareHomes.Org is a senior housing and care marketing and information service that provides resources and personalized assistance in finding senior housing and care. We help families find options based on a loved one’s stated needs and preferences and empower them to make informed decisions. This may include independent living, home care, residential care homes, assisted living, specialized memory care, and skilled nursing. Based on the family’s stated needs and preferences, we provide the family with information about senior care providers (“Care Providers”) and may relay the family’s information to those Care Providers. Families can review detailed information about local and national Care Providers. The service is offered at no charge to Clients & families as the Care Providers may pay a fee to SCHO.
			<br/>
			<br/>
			<br/>
PARTICIPATING SENIOR HOUSING AND/OR CARE PROVIDERS
			<br/>
The Care Providers are solely responsible for evaluating, accepting (or declining, as appropriate), and caring for all residents, including, without limitation, ensuring that their staff are appropriately qualified, current on education, and possess the necessary facilities, resources, and equipment to competently provide the services and care required by its residents. Each Care Provider is responsible for the conditions at its community and the training of and acts and omissions of its employees, contractors, vendors, and any other individual who works at or for a community.
			<br/>
			<br/>
Each Care Provider is responsible for the content of the information posted on its page or on the Sites. The information does not represent our views or any individual associated with us, and we do not control this content. We do not vouch for the accuracy or completeness of any of the information posted by and/or for the Care Providers on the Sites, and do not take any responsibility or assume any liability for any actions you may take as a result of reading the information posted by and/or for the Care Providers on the Sites. By using the Sites and/or Services, you assume all associated risks.
			<br/>
			<br/>
			<br/>
			FEES & OBLIGATIONS
			<br/>
				SCH charges 2 types of fees to Care Providers: (1) Placement fees in the amount of 30% the monthly cost of care for every successful non-medicaid placement into the Care Provider's facility, unless the Care Provider was contacted while Out of Network by SCH staff which resulted in a successful placement in which case a Placement fee in the amount of 100% the monthly cost of care will be charged, and (2) Ad Campaign fees which vary in amount based on location and competition. The placement fees are required in order to receive placement of private-pay Clients through SCH, whereas the Ad Campaign fees are purely optional based on Care Provider's desire to maximize their business's online visibility. Care Providers are required to self-report SCH-originated Client placement into their facility within 15 calendar days, otherwise they will be liable for a placement fee of 100% of monthly cost of care and/or removed from the platform. Clients are also responsible to self-report placement within 15 calendar days of occurance, otherwise they may be liable for a placement fee of 100% of monthly cost of care and/or removed from the platform. Any costs to collect payment from either party after 15 calendar days will be billed to and paid by the respective party.
			<br/>
			<br/>
			<br/>
NO ENDORSEMENT; NO AFFILIATION; NOT A BROKER
			<br/>
We exercise no independent judgment as to the quality of, nor do we recommend or endorse, any Care Providers. We provide only an information service and do not (1) place the consumer in any Care Providers, (2) perform any medical assessment of or for the consumer or the Care Providers, or (3) participate in the consumer’s and Care Providers’s decision regarding final selection or admittance. Further, we are not a representative of or agent for either the consumer or the Care Providers and do not act on either’s behalf. It is the responsibility solely of the Care Providers and the potential resident, and/or the potential resident’s family and care provider(s) and/or other involved parties acting on the potential resident’s behalf to determine if any person is an appropriate admission to the Care Providers. SCHO does not broker, sell, or lease space directly and is not a party to any transaction between the provider and the family.
			<br/>
			<br/>
			<br/>
NO PROFESSIONAL ADVICE
			<br/>
All information, materials, content and/or advice on the Sites or provided through the Services is for informational purposes only and is not intended to replace or substitute for any professional, financial, medical, legal, or other advice. SCHO expressly disclaims, and you expressly release SCHO from, any and all liability concerning any treatment, action by, or effect on any person following the information offered or provided within or through the Sites and/or Services. You should consult with an appropriately trained and licensed specialist for all concerns that require medical, legal, financial, or other professional advice.
			<br/>
			<br/>
			<br/>
PRIVACY AND CONSENT TO COMMUNICATIONS
			<br/>
Your privacy is important to us, and we provide a Privacy Policy that sets out how we collect and use your personal information collected from the Sites and in connection with your use of our Services. Please review our Privacy Policy to which you should refer to fully understand how we collect and use personal information. By filling out any forms on the Sites or providing information to us, you expressly consent to being contacted by us and/or the Care Providers, whether by phone, mobile phone, email, mail, or otherwise. If you are a customer or potential customer of the Services, you authorize us to provide the information that you provided us and related information we may receive from third parties to Care Providers for the purpose of providing Services. Such authorization shall continue in effect until you have withdrawn your consent by requesting that we no longer provide you Services; provided that we may continue to refer to and/or disclose your information when communicating with a Care Providers as necessary for our normal Care Providers related business practices (such as Care Providers billing matters) until such matters are closed.
			<br/>
			<br/>
			<br/>
RECORDED TELEPHONE COMMUNICATIONS
			<br/>
Telephone communications between You and SCHO may be recorded or monitored for quality control purposes. You expressly consent to SCHO recording and monitoring these telephone communications.
			<br/>
			<br/>
			<br/>
CONSENT TO AUTOMATIC TELEPHONE DIALING SYSTEM
			<br/>
You agree to be contacted by SCHO and by our senior living customers at the telephone number you have provided. You agree that we and our senior living customers may use automatic telephone dialing systems and technology to contact you by call or text message at the telephone number you have provided, even if you previously registered on a Do Not Call registry; however you do not need to consent to this to use our Services.
			<br/>
			<br/>
			<br/>
USE OF SITES; LIMITED LICENSE
			<br/>
You are granted a limited, revocable, non-exclusive, nontransferable, non-sublicensable license to access and use the Sites and all content, data, information, and materials included in the Sites (the “Site Materials”) solely for your personal use, subject to the terms and conditions set forth in these Terms of Use. You will not use the Sites or any of the Site Materials other than for their intended purpose or in any way that is unlawful or harms SCHO. Any use of the Sites or the Site Materials other than as specifically authorized herein is strictly prohibited and, without limiting other available remedies, will automatically and immediately terminate the license granted herein. Such unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these Terms of Use shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication, or otherwise. This license is revocable by SCHO at any time.
			<br/>
			<br/>
			<br/>
NO UNAUTHORIZED ACCESS OF AND/OR USE OF THE SITES AND/OR SERVICES
			<br/>
The following are examples of some actions and activities that are prohibited by you in accessing and/or using the Sites and/or Services, and you agree not to do any of the following:
			<br/>
Using the Sites for commercial purposes.
			<br/>
			<br/>
Conducting or promoting any illegal activities while using the Sites or Services.
			<br/>
			<br/>
Uploading or attempting to upload or otherwise transferring any virus, worm, trap door, back door, Trojan horse, timer, clock, counter, or other limiting or malicious routine, instruction, or design that would cause harm to the Sites, SCHO’s computer and/or telecommunication systems or data.
			<br/>
			<br/>
Interfering in any way with the proper functioning of the Sites (including harming, disabling, or significantly slowing) or interfering with or disrupting any servers or networks connected to the Sites, or disobeying any requirements, procedures, policies, or regulations of networks connected to the Sites.
			<br/>
			<br/>
Modifying, copying, distributing, reselling, transmitting, displaying, performing, reproducing, publishing, licensing, creating derivative works from, framing in another web page, and/or using on any other Web site or service any of the Sites content.
			<br/>
			<br/>
Engaging in the practices of “screen scraping,” “database scraping,” “data mining,” or any other activity with the purpose of obtaining lists of users or other information, in whole or in part, from the Sites or use web “bots” or similar data gathering or extraction methods for such purposes.
			<br/>
			<br/>
Attempting to reverse engineer or jeopardize the correct functioning of the Sites, or otherwise attempting to derive the source code of the software (including the tools, methods, processes, and infrastructure) that enables or underlies the Sites.
			<br/>
			<br/>
Attempting to gain access to secured portions of the Sites.
			<br/>
			<br/>
Circumventing, attempting to circumvent, disabling, or attempting to disable, or discovering or attempting to discover any security measures (including without limitation encryption algorithms, keys, passwords, and the like) used by or for SCHO to protect its computer and/or telecommunications systems.
			<br/>
			<br/>
Using the Sites and/or Services to generate unsolicited email advertisements or spam; and/or allowing, enabling, or otherwise supporting the transmission of mass unsolicited, commercial advertising or solicitations via e-mail (spam).
			<br/>
			<br/>
Using the Sites and/or Services to stalk, harass, or harm another individual.
			<br/>
			<br/>
Impersonating any person or entity, or otherwise misrepresenting your affiliation with a person or entity.
			<br/>
			<br/>
Uploading, distributing or printing anything that may be harmful to minors.
			<br/>
			<br/>
Mirroring or framing the Sites, placing pop-up windows over its pages, or otherwise affecting the display of its pages.
			<br/>
			<br/>
Engaging in any other action in any way that is unlawful or harms SCHO.
			<br/>
			<br/>
			<br/>
INTELLECTUAL PROPERTY OWNERSHIP
			<br/>
All trademarks, service marks, and trade names are proprietary to SCHO and our licensors. All rights, title, and interests in and to the Sites, including content, materials, trademarks, and other intellectual property contained in the Sites, vest solely in SCHO and its licensors. The SCHO name and any SCHO products and services, slogans, or logos referenced herein on the Sites are either trademarks or registered trademarks of SCHO in the United States and/or other countries. The names of actual third party companies and products mentioned in the Sites may be the trademarks of their respective owners. Any rights not expressly granted herein are reserved.
			<br/>
			<br/>
			<br/>
ACCOUNT SECURITY
			<br/>
On certain portions of the Sites, you will be provided with a password and user identification to use the Sites. You must agree to abide by these Site Terms and are responsible for all activity under such user identification. You are responsible for maintaining the confidentiality and security of any password connected with your account.
			<br/>
			<br/>
			<br/>
HYPERLINKS TO EXTERNAL WEB SITES
			<br/>
The Sites may provide links to external Web sites (“External Web Sites”). We provide these links to External Web Sites for your convenience only. We do not control such External Web Sites; therefore, SCHO is not responsible for the content of any linked site or any link contained in a linked site. Our inclusion of links to such External Web Sites does not imply any endorsement of any such third party who advertises their goods or services through the Sites (including Care Providers). We make no claim or representation regarding, and accept no responsibility for, the quality, content, nature, or reliability of External Web Sites.
			<br/>
			<br/>
When you leave the Site, you should be aware that our Terms of Use and our Privacy Policy do not apply to your use of External Web Sites. You should review the applicable terms and policies, including privacy and data gathering practices, of any External Web Sites to which you navigate from the Site. Your use of and dealings with the owners or operators of these External Web Sites are at your own risk, and you agree not to make any claim against SCHO arising out of your use of these External Web Sites.
			<br/>
			<br/>
			<br/>
THIRD PARTY PRODUCTS AND SERVICES
			<br/>
We may run advertisements and promotions from third parties (including Care Providers) on the Sites or may otherwise provide information about or links or referrals to third-party (including Care Providers) products or services on the Sites (“Third Party Products and Services”).
			<br/>
			<br/>
We do not make any guarantees about the accuracy, currency, suitability, or quality of the information about such Third Party Products and Services, and we assume no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful content made available by Third Party Products and Services. Because we do not control such Third Party Products and Services, we are not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or promotions or from any Third Party Products and Services, and you use such Third Party Products and Services at your own risk.
			<br/>
			<br/>
Your business dealings or correspondence with, or participation in promotions of, such Third Party Products and Services, and any terms, conditions, warranties, or representations associated with such dealings or promotions are solely between you and such third party.
			<br/>
			<br/>
You should investigate and use your independent judgment regarding the merits, quality, and reputation of any Third Party Products and Services that you find on or through the Sites.
			<br/>
			<br/>
			<br/>
PUBLIC AREAS AND SUBMISSIONS
			<br/>
If you use a “Public Area,” such as a forum, message board, or other community area that allows user posts, additional terms and conditions apply to you.
			<br/>
			<br/>
You agree that any materials, including but not limited to questions, comments, suggestions, ideas, plans, notes, drawings, original or creative materials, or other information regarding SCHO, the Sites, and/or the Services, provided by you in any manner including but not limited to e-mail, other written, or oral submissions to SCHO, or postings on the Sites (“Submissions”), are non-confidential. You grant us a perpetual, irrevocable, nonexclusive, worldwide, fully paid, and sublicensable license to use, disseminate, distribute, display, reproduce, post, and publish your Submission and any name, username, or likeness provided in connection with your Submission in all media formats and channels now known or later developed without compensation to you. When you post or otherwise share your Submission on or through our Sites or Services, you understand that your Submission and any associated information may be visible to others.
			<br/>
			<br/>
If you are an author of a Submission, you warrant that you will not post any materials that would (a) constitute or encourage conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national, or international law, including any threatening, libelous, defamatory, obscene, vulgar, pornographic, profane, or indecent messages of any kind; (b) violate or infringe upon the rights of others, including their privacy or publicity rights, or which is protected by copyright, trademark, or other proprietary rights, without first obtaining permission from the person who owns or holds that right; (c) contain a virus or other material of a destructive nature; and (d) violate any law, statute, ordinance, or regulation including but not limited to misrepresentation, fraud, deception, anti-discrimination, and/or false advertising.
			<br/>
			<br/>
Additional terms and conditions may apply to you in your use of the “Public Area,” a forum, message board, or other community area.
			<br/>
			<br/>
			<br/>
DISCLAIMER
			<br/>
THE SITES AND THE SITE MATERIALS (INCLUDING ALL THIRD PARTY CONTENT), AND ALL LINKS, INFORMATION, MATERIALS, EVALUATIONS, RECOMMENDATIONS, SERVICES, AND PRODUCTS PROVIDED ON OR THROUGH THE SITES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. YOU EXPRESSLY AGREE THAT USE OF THE SITES AND THE MATERIALS IS AT YOUR SOLE RISK. SCHO AND ITS ASSOCIATED PARTIES (DEFINED AS AFFILIATES, SUCCESSORS, OFFICERS, DIRECTORS, SHAREHOLDERS, PERSONAL REPRESENTATIVES, AGENTS, AND EMPLOYEES) DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT AS TO THE SITES, THE SITE MATERIALS, LINKS, INFORMATION, MATERIALS, SERVICES, AND PRODUCTS AVAILABLE ON OR THROUGH ON THE SITES AND/OR SERVICES. IN ADDITION, SCHO IS NOT RESPONSIBLE FOR THE PRODUCTS OR SERVICES PROVIDED BY, OR THE CONDUCT OF, ANY THIRD PARTY OR ITS AGENTS OR EMPLOYEES (EVEN IF WE HAVE INTRODUCED YOU TO SUCH PARTY OR PARTIES), INCLUDING WITHOUT LIMITATION, ANY PARTICIPATING COMMUNITY OR OTHER HEALTH CARE AND/OR ANY OTHER SENIOR HOUSING AND CARE PROVIDER, WHETHER OFFLINE OR ONLINE. NO ORAL OR WRITTEN INFORMATION FROM SCHO OR ANY OTHER PARTY WILL MODIFY THIS DISCLAIMER. SCHO EXPRESSLY DISCLAIMS ANY REPRESENTATIONS, WARRANTIES, OR COVENANTS THAT THE MATERIALS ON THE SITES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE. WHILE WE ATTEMPT TO PROVIDE YOU ACCESS SAFE FROM VIRUSES, SCHO DOES NOT REPRESENT, WARRANT, OR COVENANT THAT THE SITES OR ITS SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. IN NO EVENT SHALL SCHO BE LIABLE TO USER FOR ANY OF THE PRODUCTS, SERVICES, CONTENT, OR INFORMATION PROVIDED THROUGH THE SITES OR OTHERWISE PROVIDED BY OR ON BEHALF OF SCHO.
			<br/>
			<br/>
			<br/>
LIMITATION OF LIABILITY
			<br/>
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL SCHO OR THE ASSOCIATED PARTIES BE LIABLE FOR ANY, SPECIAL, PUNITIVE, EXEMPLARY, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND WHATSOEVER, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA OR OTHER INFORMATION, FOR BUSINESS INTERRUPTION, AND FOR ANY OTHER PECUNIARY OR OTHER LOSS WHATSOEVER) ARISING OUT OF OR IN ANY WAY RELATED TO THE SITES AND/OR SERVICES, OUR ACTS OR OMISSIONS UNDER OR IN ANY WAY RELATED TO THE SITES AND/OR SERVICES, OR OTHERWISE UNDER OR IN CONNECTION WITH THE SITES AND/OR SERVICES, REGARDLESS OF THE LEGAL THEORY, INCLUDING WITHOUT LIMITATION TORT, STRICT LIABILITY, BREACH OF CONTRACT, OR BREACH OF WARRANTY BY US OR ANY ASSOCIATED PARTY, AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
			<br/>
			<br/>
THE FOREGOING LIMITATIONS, EXCLUSIONS AND DISCLAIMERS OF THIS “LIMITATION OF LIABILITY” SECTION SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY REMEDY FAILS ITS ESSENTIAL PURPOSE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE AGGREGATE LIABILITY OF SCHO OR THE ASSOCIATED PARTIES, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE, OR IMPUTED), OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OR INABILITY TO USE THE COMPLETE SITE EXCEED THE GREATER OF USD $50.00 OR ANY COMPENSATION YOU PAY, IF ANY, TO SCHO FOR ACCESS TO OR USE OF THE SITE AND/OR SERVICES. THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT LIMIT OR EXCLUDE SCHO OR THE ASSOCIATED PARTIES’ LIABILITY FOR GROSS NEGLIGENCE, FRAUD, INTENTIONAL, WILLFUL, MALICIOUS, OR RECKLESS MISCONDUCT. SOME JURISDICTIONS (SUCH AS WASHINGTON STATE) DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CONSEQUENTIAL OR INDIRECT DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
			<br/>
			<br/>
			<br/>
INDEMNIFICATION
			<br/>
To the fullest extent permitted by applicable law You agree to indemnify and hold SCHO and the Associated Parties harmless from any claim or demand, including attorneys’ fees, arising out of your use of the Sites and/or the Services or your violation of our Terms of Use, or the infringement by you of any intellectual property or other right of any person or entity.
			<br/>
			<br/>
			<br/>
TERMINATION
			<br/>
We may terminate or suspend your access to the Sites at any time, with or without cause, and with or without notice. Upon such termination or suspension, your right to use the Sites will immediately cease.
			<br/>
			<br/>
			<br/>
CHANGES TO THE SITES AND/OR SERVICES
			<br/>
We reserve the right to change, modify, suspend, or cease providing the Sites and/or Services (and therefore, your access to and use of) at any time without notice.
			<br/>
			<br/>
			<br/>
GOVERNING LAW AND FORUM SELECTION
			<br/>
Any dispute arising from the Site and your use of the Services will be governed by and construed and enforced in accordance with the laws of the state of Wyoming, except to the extent preempted by U.S. federal law, without regard to conflict of law rules or principles (whether of Wyoming or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that is not subject to arbitration or cannot be heard in small claims court will be resolved in the state or federal courts of Wyoming and the United States, respectively, sitting in Laramie County, Wyoming.
			<br/>
			<br/>
			<br/>
CLAIMS SUBJECT TO MANDATORY AND BINDING ARBITRATION, CLASS WAIVER, AND JURY TRIAL WAIVER
			<br/>
Please read the following important section carefully.
			<br/>
			<br/>
Except for small claims disputes or claims related to intellectual property, you and we waive our rights to a jury trial and to have any dispute arising out of or related to the Sites or Services resolved in court. Instead, disputes relating in any way to the Sites or Services must be submitted to binding arbitration administered by JAMS. The Federal Arbitration Act and federal arbitration law apply to this agreement. The most recent version of the JAMS Rules are available at www.jamsadr.com/rules-download and are hereby incorporated by reference.
			<br/>
			<br/>
You and we agree that any dispute arising out of or related to the Sites or our Services is personal to you and us and that any dispute will be resolved solely through individual action, and will not be brought as a class arbitration, class action, or any other type of representative proceeding.
			<br/>
			<br/>
You and we agree that for any arbitration you initiate, you will pay the filing fee (up to a maximum of $250 if you are a consumer), and we will pay the remaining JAMS fees and costs. For any arbitration initiated by us, we will pay all JAMS fees and costs. You have the right to an in-person hearing in your hometown area.
			<br/>
			<br/>
You have the right to opt out of binding arbitration within 30 days of the date you first accepted the terms of this section by sending an email to support@seniorcarehomes.org. In order to be effective, the opt-out notice must include your full name and address and clearly indicate your intent to opt out of binding arbitration. By opting out of binding arbitration, you are agreeing to resolve disputes in accordance with the section, “Governing Law and Forum Selection.”
			<br/>
			<br/>
If any portion of this section is found to be unenforceable or unlawful for any reason, (a) the unenforceable or unlawful provision shall be severed from these Terms of Use; (b) severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of this section or the parties’ ability to compel arbitration of any remaining claims on an individual basis pursuant to this section; and (c) to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims in arbitration. Further, if any part of this section is found to prohibit an individual claim seeking public injunctive relief, that provision will have no effect to the extent such relief is allowed to be sought out of arbitration, and the remainder of this section will be enforceable.
			<br/>
			<br/>
			<br/>
COPYRIGHT INFRINGEMENT NOTICES & DMCA NOTICES
			<br/>
If you believe in good faith that materials hosted by us infringe your copyright, you (or your agent) may send us a written notice that includes the following information. Please note that we will not process your complaint if it is not properly filled out or is incomplete. Any misrepresentations in your notice whether content or activity is infringing may expose you to liability for damages.
			<br/>
			<br/>
A clear identification of the copyrighted work you claim was infringed.
			<br/>
A clear identification of the material you claim is infringing on the Website, such as a link to the infringing material.
			<br/>
Your address, email address, and telephone number.
			<br/>
A statement that you have a “good faith belief that the material that is claimed as copyright infringement is not authorized by the copyright owner, its agent, or the law.”
			<br/>
A statement that “the information in the notification is accurate, and under penalty of perjury, the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed”.
			<br/>
A signature by the person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
			<br/>
			<br/>
You may send your notice by using the contact information below:
			<br/>
Senior Care Homes Org, LLC.
			<br/>
Attn: Legal Department
			<br/>
1309 Coffeen Avenue, Suite 1200
			<br/>
Sheridan, Wyoming 82801
			<br/>
Email: support@seniorcarehomes.org
			<br/>
			<br/>
			<br/>
GENERAL TERMS
			<br/>
If any part of the Terms of Use is determined to be invalid or unenforceable pursuant to applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms of Use will continue in effect. You may not assign the Terms of Use, or assign, transfer, or sublicense your rights, if any, in the Sites. Except as expressly stated herein, the Terms of Use constitute the entire agreement between you and SCHO with respect to the Sites.
			<br/>
			<br/>
		</p>
        </>
    );
}
import React, { useState } from "react";
import { Form, Input } from "antd";
import tick from "../../assets/images/tick.svg";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { getCookie } from "../../helpers/utilities/Cookie";
import { generateRandomPassword } from "../../helpers/ad";
import CareAssessmentFormSignUpShort from './CareAssessmentForm_SignUp_Short.jsx';
import ProgressBar from "@ramonak/react-progress-bar";
import { saveToLocalStorage } from "../../helpers/utilities/LocalStorage.js";

function RegisterFromListingForm({ ad, setPostSignupMessage, setCareAssessmentComplete, careAssessmentComplete, setModel }) {
  const [auth, setAuth] = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [token, setToken] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkTerm, setCheckTerms] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const visitorId = getCookie("visitorId");
  const userId = auth?.user ? auth?.user?._id : null;
  const actionSource = 'websiteLoading';


  // fetch remote care assessment
  const getRemoteCareAssessment = async () => {
    try {
        const { data } = await axios.post(
            `/assessments/create-update-assessment/findByVisitorUser`, {
                userId,
                visitorId,
                actionSource,
            }
        );

        if (data?.error) {
            return null;
        } else {
            return data?.data;
        };

    } catch (error) {
    console.log(error);
    };
  };


  const handleInfoSubmit = async (values) => {
    if (!checkTerm) {
      setShowError(true);
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(`/pre-register`, {
        firstName: values.firstName,
        lastName: 'NA',
        email: values.email,
        password: generateRandomPassword(),
        role: "Client",
        credentialType: 'custom',
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        const { response } = await axios.post(`/register`, {
            token: data.token,
            phone: values.phone,
        });

        if (response?.error) {
            toast.error(response.error);
            setLoading(false);
        } else {
            setCodeSent(true);
            setPhoneNumber(values.phone);
            setToken(data.token);
            toast.success('Code sent via SMS!');
            setLoading(false);
            return;
        }
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again", error);
      console.log("Error from pre-register");
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();

    try {
        setLoading(true);

        const { data } = await axios.post(`/post-register`, {
            token: token,
            phone: phoneNumber,
            code: code,
            visitorId: getCookie('visitorId'),
        });

        if (data?.error) {
            toast.error(data.error);
            setLoading(false);
        } else {
            setAuth(data);
            localStorage.setItem("auth", JSON.stringify(data));

            // need to refetch care assessment and load to local storage. Otherwise may not be up to date in user's browser.
            const remoteCareAssessment = await getRemoteCareAssessment();
            saveToLocalStorage("care-assessment", remoteCareAssessment);

            setLoading(false);
            setPostSignupMessage(true);
        }
    } catch (error) {
        toast.error("Something went wrong. Please try again", error);
        setLoading(false);
    }
  };

  return (
    <>
      {careAssessmentComplete === true ? (
        <>
          {codeSent ? (
            <>
              <div className="mt-[2rem] mb-[2rem]">
                <ProgressBar
                  completed={90}
                  labelSize="13px"
                  bgColor="#216017"
                />
                <p className="p-20px mb-5 text-grayy d-flex justify-content-center mt-4">
                  Please confirm you're not a robot and enter the code you received via text message.
                </p>
                
                <form onSubmit={(event) => handleVerifyCode(event)} className="post-code-form">
                  
                  <div className="flex d-flex justify-content-center mb-2 mt-2">
                    <div className="text-center">
                      <input
                        type="text"
                        id="code"
                        name="code"
                        placeholder="Enter code.."
                        className="custom-input"
                        onChange={(event) => setCode(event.target.value)}
                        value={code}
                        required
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div className="col col-lg-4 col-md-3 col-sm-1 d-none d-sm-block"></div>
                    <div className="col col-lg-4 col-md-6 col-sm-10">
                      <div className="row">
                        <div className="mt-[40px]">
                          <button className="form-btn" type="submit" style={{ fontSize: '16px', padding: '5px 10px' }}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-4 col-md-3 col-sm-1 d-none d-sm-block"></div>
                  </div>

                </form>
              </div>
            </>
          ) : (
            <div className="mt-[2rem] mb-[2rem]">
              <ProgressBar
                completed={75}
                labelSize="13px"
                bgColor="#216017"
              />
              <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3 mb-3">
                Contact Details
              </h1>
              <p className="p-20px mb-5 text-grayy d-flex justify-content-center">
                Needed to create a free account and keep communication with {ad?.ownerFirstName} until you've toured their home and decided if they're the right provider for you.
              </p>

              <Form onFinish={handleInfoSubmit} className="custom-form">
                <div className="text-left">
                  <Form.Item
                    name="firstName"
                    rules={[{ required: "true", message: "First name is required" }]}
                  >
                    <Input placeholder="First Name" className="custom-input" />
                  </Form.Item>
                </div>

                <div className="text-left">
                  <Form.Item
                    name="phone"
                    rules={[{ required: "true", message: "Cell phone number is required" }]}
                  >
                    <Input placeholder="Cell Phone Number" className="custom-input" />
                  </Form.Item>
                </div>

                <div className="text-left">
                  <Form.Item
                    name="email"
                    rules={[{ required: "true", message: "Email is required" }]}
                  >
                    <Input placeholder="Email" className="custom-input" />
                  </Form.Item>
                </div>

                <div className="row mb-3">
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    <div
                      className={`${checkTerm ? "bg-lightBrown" : "border-[2px] border-[grayy]"} flex-center w-[100px] h-[26px] xSm:w-[50px] xSm:h-[24px] rounded-full cursor-pointer`}
                      style={checkTerm ? { background: '#216017' } : { background: 'white', border: '1px solid gray' }}
                      onClick={() => setCheckTerms((prev) => !prev)}
                    >
                      <img
                        src={tick}
                        alt="tick"
                        className={`${checkTerm ? "block" : "hidden"} w-[11px] h-[9px]`}
                      />
                    </div>
                  </div>
                  <div className="col-10">
                    <span className="p-16px text-grayy text-left">
                      By checking this box, you agree to our{" "}
                      <a href="/policies/terms-of-use">Terms of Use</a> and{" "}
                      <a href="/policies/privacy-policy">Privacy Policy</a>. We don't sell your information and only provide your first name to Providers when initially connecting the both of you.
                    </span>
                  </div>
                </div>
                {showError && (
                  <div className="d-flex justify-content-center mt-2">
                    <span className="p-14px text-red-500">Please accept Terms of Use & Privacy Policy</span>
                  </div>
                )}

                <div className="mt-[20px]">
                  <Form.Item>
                    <button className="form-btn" type="submit">
                      Submit
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
        </>
      ):(
        <CareAssessmentFormSignUpShort 
          ad={ad}
          setCareAssessmentComplete={setCareAssessmentComplete}
          setModel={setModel}
        />
      )}
    </>
  );
}

export default RegisterFromListingForm;

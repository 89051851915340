import {useAuth} from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Modal } from "antd";
import { useState } from "react";

import { ReactComponent as LikeIcon } from "../../assets/icons/likeIconCard.svg";
import { ReactComponent as LikedIcon } from "../../assets/icons/likedIcon.svg";


export default function LikeUnlike({ad}) {
    // context
    const [auth, setAuth] = useAuth();
    // hooks
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const loggedIn = auth.user !== null && auth.token !== "";

    const handleLike = async () => {
        try {
            if(auth.user === null) {
                navigate("/login", {
                    state: `/listings/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`,
                });
                return;
            }
            const {data} = await axios.post('/wishlist', {adId: ad._id});
            setAuth({...auth, user: data});
            const fromLS = JSON.parse(localStorage.getItem("auth"));
            fromLS.user = data;
            localStorage.setItem("auth", JSON.stringify(fromLS));
            toast.success('Added to Wishlist');
        } catch (error) {
            console.log(error);
        }
    };

    const handleUnlike = async () => {
        try {
            if(auth.user === null) {
                navigate("/login", {
                    state: `/listings/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`,
                });
                return;
            }
            const {data} = await axios.delete(`/wishlist/${ad._id}`);
            setAuth({...auth, user: data});
            const fromLS = JSON.parse(localStorage.getItem("auth"));
            fromLS.user = data;
            localStorage.setItem("auth", JSON.stringify(fromLS));
            toast.success('Removed from Wishlist');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
          <>
            {loggedIn ? (
                <>
                    {auth.user?.wishlist?.includes(ad?._id) ? (
                        <span onClick={handleUnlike} className='pointer'>
                            <div className="p-2 w-fit bg-[#d4e7dc] border border-black rounded-full mb-3" style={{display: "inline-block", position: 'relative'}}>
                                <LikedIcon/>
                            </div>
                        </span>
                    ) : (
                        <span onClick={handleLike} className='pointer'>
                            <div className="p-2 w-fit bg-[#d4e7dc] border border-black rounded-full mb-3" style={{display: "inline-block", position: 'relative'}}>
                                <LikeIcon/>
                            </div>
                        </span>
                    )}
                </>
            ):(
                <span className='pointer'>
                    <div className='h2 mb-3' style={{display: "inline-block", position: 'relative'}}>
                        <div
                            onClick={() => {
                                setOpen(true);
                            }}
                            className="p-2 w-fit bg-[#d4e7dc] border border-black rounded-full"
                        >
                            <LikeIcon/>
                        </div>
                    </div>
                </span>
            )}
          </>
          <Modal
              open={open}
              onCancel={() => {
                  setOpen(false);
              }}
              title={null}
              footer={null}
          >
            <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3 mb-3">
                Save Home
            </h1>
            <p className="p-20px mb-5 text-grayy">
                Please <a href="/login">log in</a> or <a href="/register/client">sign up</a> to add this home to your Saved Homes List.
            </p>
          </Modal>
        </>
    );
}
import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

import ImageUpload from '../../components/forms/ImageUpload';


export default function EditListing() {
    // state
    const [ad, setAd] = useState({
        _id: "",
        title: "",
        published: "",
        photos: [],
        description: "",
        currentAvailibility: {},
        careCost: {},
        amenities: {},
        services: {},
        uploading: false,
        loading: false,
    });
    const [loaded, setLoaded] = useState(false);

    // hooks
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params?.title) fetchListing();
    }, [params?.title]);

    const fetchListing = async () => {
        setLoaded(true);

        try {
            const { data } = await axios.get(`/ad/${params.title}`);
            
            const updatedAd = { ...data?.ad };

            if (!data?.ad?.currentAvailibility) { // if this is a listing that has just been claimed, the edittable fields do not exist in the record, though they are defined in the model. Therefore, we need to set them here to default values for user to modify and save.
                updatedAd.currentAvailibility = {
                    pR_pB_pS: 0,
                    pR_pB_sS: 0,
                    pR_sB_sS: 0,
                    sR_sB_sS: 0,
                };

                updatedAd.careCost.actual = {
                    rangeLowAmount: null,
                    rangeHighAmount: null,
                    sharePublic: false,
                };

                updatedAd.amenities =  {
                    activityCalendar: false,
                    artsCraftsGames: false,
                    birthdayCelebrations: false,
                    television: false,
                    cable: false,
                    callButton: false,
                    hospitalBed: false,
                    hoyerLift: false,
                    wirelessInternet: false,
                    library: false,
                    newspaper: false,
                    outdoorCommonArea: false,
                    communityTelephone: false,
                    wheelchairAccessible: false,
                    fullMeals: false,
                    snacks: false,
                };

                updatedAd.services = {
                    beautician: false,
                    bathing: false,
                    dressing: false,
                    transferring: false,
                    awakeNightStaff: false,
                    twentyFourSevenCare: false,
                    catheter: false,
                    colostomy: false,
                    dementia: false,
                    alzhiemers: false,
                    developmentalDisabilities: false,
                    diabetes: false,
                    insulin: false,
                    feedTube: false,
                    onlyFemale: false,
                    onlyMale: false,
                    doctorHomeVisit: false,
                    hospice: false,
                    housekeeping: false,
                    laundry: false,
                    massageTherapy: false,
                    medicineDelivery: false,
                    medicineManagement: false,
                    oxygenTherapy: false,
                    parkinson: false,
                    petCare: false,
                    podiatrist: false,
                    physicalTherapy: false,
                    respiteCare: false,
                    rnOperated: false,
                    strokeRecovery: false,
                    transportDoctor: false,
                    transportShopping: false,
                    visualHearing: false,
                    woundCare: false,
                    wandering: false,
                    combative: false,
                    additionalLanguages: "",
                };
            }

            setAd(updatedAd);

            setLoaded(false);
        } catch (error) {
            console.log(error);
            setLoaded(false);
        }
    };

    const handleSubmit = async (e) => {
        try {
            // validation
            if (!ad.photos?.length) {
              toast.error("At least 1 photo is required");
              return;
            } else if (!ad.description) {
              toast.error("Description is required");
              return;
            } else {
              // make API put request
              setAd({ ...ad, loading: true });
      
              const { data } = await axios.put(`/ad/${ad._id}`, ad);
              
              if (data?.error) {
                toast.error(data.error);
                setAd({ ...ad, loading: false });
              } else {
                toast.success("Listing updated successfully");
                setAd({ ...ad, loading: false });
                navigate("/provider/listings");
              }
            }
          } catch (err) {
            console.log(err);
            setAd({ ...ad, loading: false });
          }
    };

    return (
        <div>
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                    <div className='col col-lg-10 col-md-9 col-sm-8'>
                        <div className='container mt-3' style={{ padding: '1%' }}>
                            <div className='mb-3'>
                                <h4>Listing Pictures</h4>
                                <ImageUpload ad={ad} setAd={setAd} />
                            </div> 

                            <div className='mb-3'>
                                <h4>Description</h4>
                                <textarea 
                                    rows={5} 
                                    placeholder='Enter listing description..' 
                                    className='form-control' 
                                    value={ad.description}
                                    onChange={(e) => setAd({ ...ad, description: e.target.value })}
                                />
                            </div>

                            <div className='mb-3'>
                                <h4>Current Availability</h4>
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="pd-pb-ps" className='mr-2'>Private Bed, Private Bath, Private Shower:</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                            type="number"
                                            id="pd-pb-ps"
                                            name="quantity"
                                            min="0"
                                            max="8"
                                            step="1"
                                            value={ad.currentAvailibility.pR_pB_pS}
                                            onChange={(e) => {
                                            // Create a copy of the currentAvailibility object
                                            const updatedcurrentAvailibility = { ...ad.currentAvailibility };

                                            // Update the specific property within the nested object
                                            updatedcurrentAvailibility.pR_pB_pS = e.target.value;

                                            // Update the ad state with the modified currentAvailibility object
                                            setAd({ ...ad, currentAvailibility: updatedcurrentAvailibility });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="pd-pb-ss" className='mr-2'>Private Bed, Private Bath, Shared Shower:</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                            type="number"
                                            id="pd-pb-ss"
                                            name="quantity"
                                            min="0"
                                            max="8"
                                            step="1"
                                            value={ad.currentAvailibility.pR_pB_sS}
                                            onChange={(e) => {
                                            // Create a copy of the currentAvailibility object
                                            const updatedcurrentAvailibility = { ...ad.currentAvailibility };

                                            // Update the specific property within the nested object
                                            updatedcurrentAvailibility.pR_pB_sS = e.target.value;

                                            // Update the ad state with the modified currentAvailibility object
                                            setAd({ ...ad, currentAvailibility: updatedcurrentAvailibility });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="pd-sb-ss" className='mr-2'>Private Bed, Shared Bath, Shared Shower:</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                            type="number"
                                            id="pd-sb-ss"
                                            name="quantity"
                                            min="0"
                                            max="8"
                                            step="1"
                                            value={ad.currentAvailibility.pR_sB_sS}
                                            onChange={(e) => {
                                                // Create a copy of the currentAvailibility object
                                                const updatedCurrentAvailibility = { ...ad.currentAvailibility };
                                                
                                                // Update the specific property within the nested object
                                                updatedCurrentAvailibility.pR_sB_sS = e.target.value;
                                        
                                                // Update the ad state with the modified currentAvailibility object
                                                setAd({ ...ad, currentAvailibility: updatedCurrentAvailibility });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="sd-sb-ss" className='mr-2'>Shared Bed, Shared Bath, Shared Shower:</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                            type="number"
                                            id="sd-sb-ss"
                                            name="quantity"
                                            min="0"
                                            max="8"
                                            step="1"
                                            value={ad.currentAvailibility.sR_sB_sS}
                                            onChange={(e) => {
                                                // Create a copy of the currentAvailibility object
                                                const updatedCurrentAvailibility = { ...ad.currentAvailibility };
                                                
                                                // Update the specific property within the nested object
                                                updatedCurrentAvailibility.sR_sB_sS = e.target.value;
                                        
                                                // Update the ad state with the modified currentAvailibility object
                                                setAd({ ...ad, currentAvailibility: updatedCurrentAvailibility });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3'>
                                <h4>Monthly Price Range (in USD)</h4>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="low" className='mr-2'>Low</label>
                                    </div>

                                    <div className='col'>
                                        $
                                        <input
                                            type="number"
                                            id="low"
                                            name="quantity"
                                            min="0"
                                            max="20000"
                                            step="100"
                                            value={ad?.careCost?.actual?.rangeLowAmount ? ad.careCost.actual.rangeLowAmount : 0}
                                            onChange={(e) => {
                                            // Create a deep copy of ad to avoid mutating the state directly
                                            const updatedAd = JSON.parse(JSON.stringify(ad));

                                            // Update the low value in the nested structure
                                            updatedAd.careCost.actual.rangeLowAmount = Number(e.target.value);

                                            // Update the ad state with the modified object
                                            setAd(updatedAd);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="low" className='mr-2'>High</label>
                                    </div>

                                    <div className='col'>
                                        $
                                        <input
                                            type="number"
                                            id="high"
                                            name="quantity"
                                            min="0"
                                            max="30000"
                                            step="100"
                                            value={ad?.careCost?.actual?.rangeHighAmount ? ad.careCost.actual.rangeHighAmount : 0}
                                            onChange={(e) => {
                                            // Create a deep copy of ad to avoid mutating the state directly
                                            const updatedAd = JSON.parse(JSON.stringify(ad));

                                            // Update the low value in the nested structure
                                            updatedAd.careCost.actual.rangeHighAmount = Number(e.target.value);

                                            // Update the ad state with the modified object
                                            setAd(updatedAd);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="sharePublic" className='mr-2'>Show Price Range on Listing Page?</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="sharePublic"
                                        name="sharePublic"
                                        checked={ad?.careCost?.actual?.sharePublic === true}
                                        onChange={(e) => {
                                            // Create a deep copy of ad to avoid mutating the state directly
                                            const updatedAd = JSON.parse(JSON.stringify(ad));

                                            // Update the sharePublic value in the nested structure
                                            updatedAd.careCost.actual.sharePublic = e.target.checked;

                                            // Update the ad state with the modified object
                                            setAd(updatedAd);
                                        }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3'>
                                <h4>Amenities</h4>
                                
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="activityCalendar" className='mr-2'>Activity Calendar</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="activityCalendar"
                                        name="activityCalendar"
                                        checked={ad.amenities.activityCalendar} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.activityCalendar = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="artsCraftsGames" className='mr-2'>Arts, Crafts, & Games</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="artsCraftsGames"
                                        name="artsCraftsGames"
                                        checked={ad.amenities.artsCraftsGames} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.artsCraftsGames = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="birthdayCelebrations" className='mr-2'>Birthday Celebrations</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="birthdayCelebrations"
                                        name="birthdayCelebrations"
                                        checked={ad.amenities.birthdayCelebrations} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.birthdayCelebrations = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="television" className='mr-2'>Television</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="television"
                                        name="television"
                                        checked={ad.amenities.television} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.television = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>
                                
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="cable" className='mr-2'>Cable</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="cable"
                                        name="cable"
                                        checked={ad.amenities.cable} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.cable = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="callButton" className='mr-2'>Call Button</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="callButton"
                                        name="callButton"
                                        checked={ad.amenities.callButton} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.callButton = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="hospitalBed" className='mr-2'>Hospital/Electric Bed</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="hospitalBed"
                                        name="hospitalBed"
                                        checked={ad.amenities.hospitalBed} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.hospitalBed = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="hoyerLift" className='mr-2'>Hoyer Lift</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="hoyerLift"
                                        name="hoyerLift"
                                        checked={ad.amenities.hoyerLift} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.hoyerLift = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="wirelessInternet" className='mr-2'>Wireless Internet</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="wirelessInternet"
                                        name="wirelessInternet"
                                        checked={ad.amenities.wirelessInternet} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.wirelessInternet = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="library" className='mr-2'>Book Library</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="library"
                                        name="library"
                                        checked={ad.amenities.library} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.library = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="newspaper" className='mr-2'>Newspaper</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="newspaper"
                                        name="newspaper"
                                        checked={ad.amenities.newspaper} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.newspaper = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="outdoorCommonArea" className='mr-2'>Outdoor Common Area</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="outdoorCommonArea"
                                        name="outdoorCommonArea"
                                        checked={ad.amenities.outdoorCommonArea} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.outdoorCommonArea = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="communityTelephone" className='mr-2'>Community Telephone</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="communityTelephone"
                                        name="communityTelephone"
                                        checked={ad.amenities.communityTelephone} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.communityTelephone = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="wheelchairAccessible" className='mr-2'>Wheelchair Accessible</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="wheelchairAccessible"
                                        name="wheelchairAccessible"
                                        checked={ad.amenities.wheelchairAccessible} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.wheelchairAccessible = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="fullMeals" className='mr-2'>Full Meals</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="fullMeals"
                                        name="fullMeals"
                                        checked={ad.amenities.fullMeals} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.fullMeals = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="snacks" className='mr-2'>Snacks</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="snacks"
                                        name="snacks"
                                        checked={ad.amenities.snacks} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentAmenities = { ...ad.amenities };

                                            // Update the specific property within the nested object
                                            updatedCurrentAmenities.snacks = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, amenities: updatedCurrentAmenities });
                                        }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3'>
                                <h4>Services</h4>
                                
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="beautician" className='mr-2'>Beautician</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="beautician"
                                        name="beautician"
                                        checked={ad.services.beautician} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.beautician = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="bathing" className='mr-2'>Showering/Bathing</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="bathing"
                                        name="bathing"
                                        checked={ad.services.bathing} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.bathing = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="dressing" className='mr-2'>Dressing</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="dressing"
                                        name="dressing"
                                        checked={ad.services.dressing} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.dressing = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="transferring" className='mr-2'>Transferring</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="transferring"
                                        name="transferring"
                                        checked={ad.services.transferring} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.transferring = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="awakeNightStaff" className='mr-2'>Awake Night Staff</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="awakeNightStaff"
                                        name="awakeNightStaff"
                                        checked={ad.services.awakeNightStaff} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.awakeNightStaff = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="twentyFourSevenCare" className='mr-2'>24/7 Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="twentyFourSevenCare"
                                        name="twentyFourSevenCare"
                                        checked={ad.services.twentyFourSevenCare} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.twentyFourSevenCare = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="catheter" className='mr-2'>Catheter Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="catheter"
                                        name="catheter"
                                        checked={ad.services.catheter} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.catheter = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="colostomy" className='mr-2'>Colostomy Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="colostomy"
                                        name="colostomy"
                                        checked={ad.services.colostomy} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.colostomy = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="dementia" className='mr-2'>Dementia</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="dementia"
                                        name="dementia"
                                        checked={ad.services.dementia} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.dementia = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="alzhiemers" className='mr-2'>Alzhiemers</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="alzhiemers"
                                        name="alzhiemers"
                                        checked={ad.services.alzhiemers} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.alzhiemers = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="developmentalDisabilities" className='mr-2'>Developmental Disabilities</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="developmentalDisabilities"
                                        name="developmentalDisabilities"
                                        checked={ad.services.developmentalDisabilities} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.developmentalDisabilities = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="diabetes" className='mr-2'>Diabetes</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="diabetes"
                                        name="diabetes"
                                        checked={ad.services.diabetes} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.diabetes = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="insulin" className='mr-2'>Insulin</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="insulin"
                                        name="insulin"
                                        checked={ad.services.insulin} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.insulin = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="feedTube" className='mr-2'>Feed Tube Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="feedTube"
                                        name="feedTube"
                                        checked={ad.services.feedTube} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.feedTube = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="onlyFemale" className='mr-2'>Female Residents Only</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="onlyFemale"
                                        name="onlyFemale"
                                        checked={ad.services.onlyFemale} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.onlyFemale = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="onlyMale" className='mr-2'>Male Residents Only</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="onlyMale"
                                        name="onlyMale"
                                        checked={ad.services.onlyMale} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.onlyMale = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="doctorHomeVisit" className='mr-2'>Doctor Home Visits</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="doctorHomeVisit"
                                        name="doctorHomeVisit"
                                        checked={ad.services.doctorHomeVisit} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.doctorHomeVisit = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="hospice" className='mr-2'>Hospice Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="hospice"
                                        name="hospice"
                                        checked={ad.services.hospice} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.hospice = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="housekeeping" className='mr-2'>Housekeeping</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="housekeeping"
                                        name="housekeeping"
                                        checked={ad.services.housekeeping} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.housekeeping = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="laundry" className='mr-2'>Laundry & Linen Service</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="laundry"
                                        name="laundry"
                                        checked={ad.services.laundry} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.laundry = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="massageTherapy" className='mr-2'>Massage Therapy</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="massageTherapy"
                                        name="massageTherapy"
                                        checked={ad.services.massageTherapy} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.massageTherapy = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="medicineDelivery" className='mr-2'>Medicine Delivery</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="medicineDelivery"
                                        name="medicineDelivery"
                                        checked={ad.services.medicineDelivery} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.medicineDelivery = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="medicineManagement" className='mr-2'>Medicine Management</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="medicineManagement"
                                        name="medicineManagement"
                                        checked={ad.services.medicineManagement} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.medicineManagement = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="oxygenTherapy" className='mr-2'>Oxygen Therapy</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="oxygenTherapy"
                                        name="oxygenTherapy"
                                        checked={ad.services.oxygenTherapy} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.oxygenTherapy = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="parkinson" className='mr-2'>Parkinson</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="parkinson"
                                        name="parkinson"
                                        checked={ad.services.parkinson} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.parkinson = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="petCare" className='mr-2'>Pet Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="petCare"
                                        name="petCare"
                                        checked={ad.services.petCare} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.petCare = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="podiatrist" className='mr-2'>Podiatrist</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="podiatrist"
                                        name="podiatrist"
                                        checked={ad.services.podiatrist} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.podiatrist = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="physicalTherapy" className='mr-2'>Physical Therapy</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="physicalTherapy"
                                        name="physicalTherapy"
                                        checked={ad.services.physicalTherapy} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.physicalTherapy = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="respiteCare" className='mr-2'>Respite/Shortterm Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="respiteCare"
                                        name="respiteCare"
                                        checked={ad.services.respiteCare} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.respiteCare = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="rnOperated" className='mr-2'>RN Operated</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="rnOperated"
                                        name="rnOperated"
                                        checked={ad.services.rnOperated} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.rnOperated = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="strokeRecovery" className='mr-2'>Stroke Recovery</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="strokeRecovery"
                                        name="strokeRecovery"
                                        checked={ad.services.strokeRecovery} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.strokeRecovery = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="transportDoctor" className='mr-2'>Transportation to Doctor</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="transportDoctor"
                                        name="transportDoctor"
                                        checked={ad.services.transportDoctor} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.transportDoctor = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="transportShopping" className='mr-2'>Transportation to Shopping</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="transportShopping"
                                        name="transportShopping"
                                        checked={ad.services.transportShopping} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.transportShopping = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="visualHearing" className='mr-2'>Visual and/or Hearing Impairment Support</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="visualHearing"
                                        name="visualHearing"
                                        checked={ad.services.visualHearing} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.visualHearing = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="woundCare" className='mr-2'>Wound Care</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="woundCare"
                                        name="woundCare"
                                        checked={ad.services.woundCare} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.woundCare = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="wandering" className='mr-2'>Wandering Management</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="wandering"
                                        name="wandering"
                                        checked={ad.services.wandering} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.wandering = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="combative" className='mr-2'>Combative Management</label>
                                    </div>

                                    <div className='col'>
                                        <input
                                        type="checkbox"
                                        id="combative"
                                        name="combative"
                                        checked={ad.services.combative} // Use 'checked' for checkboxes
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.combative = e.target.checked; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="additionalLanguages" className='mr-2'>Languages Spoken in Addition to English</label>
                                    </div>

                                    <textarea 
                                        placeholder='Enter additional languages spoken..' 
                                        className='form-control' 
                                        id='additionalLanguages'
                                        value={ad.services.additionalLanguages}
                                        onChange={(e) => {
                                            // Create a copy of the currentAvailability object
                                            const updatedCurrentServices = { ...ad.services };

                                            // Update the specific property within the nested object
                                            updatedCurrentServices.additionalLanguages = e.target.value; // Update with the checkbox value

                                            // Update the ad state with the modified currentAvailability object
                                            setAd({ ...ad, services: updatedCurrentServices });
                                        }}
                                    />
                                </div>

                            </div>
                            
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button onClick={handleSubmit} className="btn btn-primary btn-sm col-4 p-1" style={{ textTransform: "capitalize" }} disabled={ad.loading}>
                                    {ad.loading ? 'Processing' : 'Update Listing'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
    // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

    return (
        <>
			<Helmet>
				<title>Privacy Policy</title>
				<meta name="description" content="Privacy Policy for SeniorCareHomes.Org" />
			</Helmet>
            <h1 className="mt-4">Privacy Policy</h1>
            <p>EFFECTIVE JANUARY 1, 2024
			<br/>
			<br/>
This privacy policy (the “Privacy Policy”) explains the collection, use, and disclosure practices of the website, mobile applications, and other online and offline products and services, including our senior living referral services (collectively, the “Services”) owned and operated by Senior Care Homes Org, LLC (“SCHO,” “we,” “us,” or “our”). For clarity, this Privacy Policy describes how we collect and use information when you access and use our website(the “Site” or “Site”). Please be advised that the practices described in this Privacy Policy also apply to information that you submit to us offline and to Personal Information (as described below) that we may collect from third parties.
			<br/>
			<br/>
			<br/>
CONSENT
			<br/>
Privacy Policy. By using our Site or submitting information to us, you acknowledge and understand the terms of our Privacy Policy.
			<br/>
Automatic Telephone Dialing System: By providing us with your telephone number, including on our Site or over the telephone, you agree to be contacted by SCHO and contracted senior care network providers (to explore options relating to our Services. You agree to be contacted by telephone call or text message at the telephone number you provided using automatic telephone dialing systems, even if you previously registered on a Do Not Call registry, and consent is not a condition of any purchase.
			<br/>
			<br/>
			<br/>
COLLECTION & USE OF YOUR PERSONAL INFORMATION
			<br/>
As used in this Privacy Policy, “Personal Information” means any information that identifies, relates to, describes, is reasonably capable of being associated with, reasonably can be linked, directly or indirectly, with an individual or household, or as otherwise defined under applicable law.
			<br/>
			<br/>
We collect Personal Information from four primary sources: directly from our users or customers, automatically from the use of our Services, from affiliated and unaffiliated third parties, and by using information we collect to derive new Personal Information. The types of Personal Information we collect about you depend on your interactions with us and are described in more detail below.
			<br/>
			<br/>
Information We Collect Directly from You. We collect information directly from you in a variety of ways. Any information you provide to us is voluntary, but please note that some information may be necessary for us to provide you with our Services. You may also provide Personal Information about another person for whom you may be seeking senior housing and/or care Services (“Client”).
			<br/>
			<br/>
The categories of Personal Information we collect directly from you include:
			<br/>
Identifiers, such as your first name, last name, phone number, email address, and mailing address;
			<br/>
Categories of personal information described in subdivision (e) of Section 1798.80 of the California Civil Code, such as name, physical characteristics or description, address, telephone number and medical or health insurance information;
			<br/>
Characteristics of protected classifications under state or federal law, such as age, sex/gender, marital status, medical condition and disability information;
			<br/>
Information you provide on our Site to request Services for yourself or another person, for example your username, password, location, zip/postal code, mailing address, fax number, and email address;
			<br/>
Financial information, including the Client’s budget for senior housing options and whether the Client receives Medicare or Medicaid;
			<br/>
Commercial information, including records of your move-in status with referred senior care network providers;
			<br/>
Feedback you provide to us about our Services, including in response to surveys or through our customer service centers; and
			<br/>
For Clients, his or her gender; household income; budget; senior care needs; and veteran status, as well as other information you may choose to voluntarily provide, such as marital status, religion, or ethnicity.
			<br/>
			<br/>
			<br/>
Information Automatically Collected from Your Use of the Site. We may automatically collect information about you from your use of our Services, including by using website analytics tools or other technologies, like cookies and web beacons (which are described in more detail below).
			<br/>
The categories and types of information we collect automatically from your use of the Services include:
			<br/>
Identifiers, such as your IP address and unique device identifiers;
			<br/>
Internet or other electronic network activity information, such as the type of browser you use, app version, access dates and times, pages viewed, and the page you visited before navigating to our Site;
			<br/>
Geolocation data, such as the country from which you access our Site;
			<br/>
Other information that relates to or is capable of being associated with you, such as information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, and mobile network information.
			<br/>
			<br/>
Cookies. Cookies are small text files that are stored on your computer when you visit certain online pages that record your preferences. We use cookies to track use of our Site and other Services. We may also use cookies to monitor traffic, improve the Site, make the Site easier and/or relevant for your use, and improve the performance of our advertising on other websites. You can opt out of accepting cookies by adjusting your browser settings to not accept cookies or to notify you when you are sent a cookie. However, certain features of our Site or other Services may not work if you delete or disable cookies.
			<br/>
			<br/>
Web Beacons. Some pages of our Site and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened an email, and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
			<br/>
			<br/>
			<br/>
INFORMATION WE COLLECT FROM OTHER SOURCES
			<br/>
We may collect information about you from other sources. For example, we may collect information about you from:
			<br/>
Clients (if you are not the Client) or a person who contacts us seeking Services for a Client;
			<br/>
From senior care network providers;
			<br/>
Third-party social media services, such as Facebook, Twitter, and Instagram, that you use to interact with our Services (e.g., to provide feedback on our Services) or that allow you to share information (e.g., via plugins, widgets or other tools); and
			<br/>
Unaffiliated parties, such as our service providers, analytics companies, advertising networks, affiliates, and other third parties that provide us with information, so we can better understand you and provide you with information that may be of interest to you.
			<br/>
			<br/>
			<br/>
INFORMATION WE INFER
			<br/>
We may derive information or draw inferences about you based on the other information we collect about you. For example, we may infer your location from our collection of your IP address.
			<br/>
			<br/>
			<br/>
USE OF YOUR INFORMATION
			<br/>
For each category of Personal Information we collect about you (whether directly or automatically), we use such category of Personal Information for the business and commercial purposes set forth below:
			<br/>
To provide you the Services and otherwise complete the transactions and/or referrals you request, perform our contractual obligations, and as otherwise anticipated within the context of our ongoing business relationship;
			<br/>
Create and manage your online accounts;
			<br/>
Communicate with you, including by telephone, text, email, and chat, regarding your use of the Services;
			<br/>
Respond to your requests and any other communications from you, including to provide customer service;
			<br/>
Send advertising or marketing communications about products, services, offers, promotions, rewards, and events offered by us and our partners and other third parties to provide news and information that we believe may be of interest to you;
			<br/>
Offer and administer surveys, contests, prize draws, sweepstakes, and other promotions;
			<br/>
Conduct internal research and development;
			<br/>
Analyze your engagement with SCHO and senior care network providers and your use of our Services to better understand your interests and behaviors and customize your experience;
			<br/>
Detect security incidents and protect against malicious, deceptive, or illegal activity, including fraudulent transactions, error, negligence, and breach of contract, and to protect against harm to the rights, property, or safety of SCHO and our users, customers, employees, or the public;
			<br/>
Debug, identify, and repair errors that impair the functionality of our Services;
			<br/>
Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with our business;
			<br/>
Comply with our legal obligations, including our tax obligations and those related to the prevention of fraud and money laundering, and those required for you to benefit from rights recognized by law or any regulatory requirements or provisions; and
			<br/>
With our legal, financial, insurance, and other advisors in connection with the kinds of transactions described above or in connection with the management of all or part of SCHO’s business or operations.
			<br/>
			<br/>
			<br/>
DISCLOSURE OF PERSONAL INFORMATION
			<br/>
We will disclose your Personal Information as follows:
			<br/>
To Senior Care Network Providers. We may share Personal Information with senior care network providers to provide you with the Services you’ve requested. The senior care network providers may contact you directly, including by email, telephone, regular mail, or in-person visits.
			<br/>
To Partners and other Third Parties. We may share Personal Information to partners and other third parties.
			<br/>
To People Acting On Your Behalf. We may share Personal Information with the person who referred you as a Client or to another person acting on your behalf.
			<br/>
To Our Service Providers. We may share Personal Information with one or more service providers with which we have contracted to provide services to us or to offer the Services you have requested from us.
			<br/>
To Affiliated Companies. We may share your Personal Information with our parent company, subsidiaries, joint ventures, or other companies under common control with us.
			<br/>
For Our Internal Analyses and Operations. We may share Personal Information with third party vendors for our own internal statistical, design, or operational purposes, such as estimating our audience size, measuring aggregate traffic patterns, and understanding demographic trends among our users. The use and disclosure of your Personal Information by these other parties is restricted to performance of such tasks and is subject to confidentiality.
			<br/>
For Legal and Other Compliance. We may share Personal Information when we believe it is appropriate for legal purposes, including to: (a) comply with the law; (b) enforce or apply this Privacy Policy or our other policies or agreements; (c) protect our or our users’ rights, property, or safety; (d) protect our users from fraudulent, abusive, or unlawful use of our Site; or (e) if we reasonably believe that an emergency involving immediate danger of death or serious physical injury to any person requires disclosure of communications or justifies disclosure of records without delay.
			<br/>
As Part of Business Transfers. We may share Personal Information as part of any merger, acquisition, debt financing, sale of company assets, or other business transaction.
			<br/>
As Aggregated, Anonymized Information. We may share aggregated, anonymous, or de-identified information to our advertisers, business partners, and other third parties that cannot reasonably be used by those third parties to identify you.
			<br/>
At your request. We may otherwise share Personal Information with your consent or at your direction.
			<br/>
			<br/>
			<br/>
LINKS TO THIRD PARTIES
			<br/>
We may provide links to third party website from our Site as a service to our users, but we have no ability to control nor are we responsible for the privacy and data collection, use, and disclosure practices of third party website. When you click on links that take you to external website, you will be subject to their privacy policies and practices and not ours. We encourage you to review and understand the privacy policies of such website before providing them with any information.
			<br/>
			<br/>
			<br/>
SECURITY
			<br/>
We use reasonable security measures to store and protect your Personal Information while it is under our control. Please note, however, that no transmission of data over the Internet is 100% secure.
			<br/>
			<br/>
			<br/>
THIRD PARTY ANALYTICS
			<br/>
To learn more about interest-based ads, or to opt out of having your web browsing information used for behavioral advertising purposes, please visit https://www.aboutads.info/choices. Your device may also include a feature (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads” or “Opt Out of Ads Personalization” on Android) that allows you to opt out of having certain information collected through apps used for behavioral advertising purposes.
			<br/>
			<br/>
			<br/>
RECORDED TELEPHONE COMMUNICATIONS
			<br/>
Telephone communications between You and SCHO may be recorded or monitored for quality control purposes. By providing information to us, you expressly consent to the recording of these telephone communications.
			<br/>
			<br/>
			<br/>
CHILDREN
			<br/>
Our Services are designed for adults, not children under the age of 16. As such, we do not intend to collect Personal Information from children under the age of 16, and our Services are not designed for such information
			<br/>
			<br/>
			<br/>
ADDITIONAL INFORMATION FOR CALIFORNIA CONSUMERS
			<br/>
The California Consumer Privacy Act or “CCPA” (California Civil Code Section 1798.100 et seq.) requires us to disclose to California consumers the categories of Personal Information (as defined in the CCPA) we have collected in the preceding 12 months, our business and commercial purposes for collecting, using, or selling such Personal Information, the categories of sources from which we collect Personal Information, whether we have disclosed or sold any Personal Information for business or commercial purposes in the preceding 12 months, the categories of Personal Information we have disclosed or sold for a business or commercial purpose in the preceding 12 months, and the categories of third parties with whom we share Personal Information. We have provided these disclosures in the prior sections of this policy. Additionally, in the preceding 12 months, we have disclosed the following categories of Personal Information for business purposes to the following categories of recipients:
			<br/>
Category of Personal Information: Identifiers, such as your first name, last name, phone number, email address, and mailing address
			<br/>
Categories of Recipients: Cloud storage service providers, marketing service providers, data analytics providers, internet service providers, email service providers, customer communication service providers
			<br/>
Category of Personal Information: Categories of personal information described in subdivision (e) of Section 1798.80 of the California Civil Code, such as name, physical characteristics or description, address, telephone number and medical or health insurance information
			<br/>
Categories of Recipients: Cloud storage service providers, marketing service providers, data analytics providers, internet service providers, email service providers, customer communication service providers
			<br/>
Category of Personal Information: Internet or other electronic network activity information, such as the type of browser you use, app version, access dates and times, pages viewed, and the page you visited before navigating to our Site
			<br/>
Categories of Recipients: Cloud storage service providers, marketing service providers, data analytics providers, internet service providers, email service providers, customer communication service providers
			<br/>
Category of Personal Information: Geolocation data, such as the country from which you access our Site
			<br/>
Categories of Recipients: Cloud storage service providers, marketing service providers, data analytics providers, internet service providers, email service providers, customer communication service providers
			<br/>
Categories of Personal Information: Gender; household income; budget; senior care needs; and veteran status, as well as other information you may choose to voluntarily provide, such as marital status, religion, or ethnicity
			<br/>
Categories of Recipients: Cloud storage service providers, marketing service providers, data analytics providers, internet service providers, email service providers, customer communication service providers
			<br/>
The CCPA also requires us to communicate information about rights California consumers have to request access to their Personal Information, to request deletion of their Personal Information, to request additional details about our information practices, to request to opt out of the “sale” of their Personal Information, if applicable, and to not be discriminated against for exercising such rights.
			<br/>
Your options in regard to the Personal Information we collect about you are described below. You may designate an authorized agent to exercise these options on your behalf by providing your authorized agent with written permission to act on your behalf and by verifying your own identity with SCHO. Alternatively, you may provide a power of attorney pursuant to Probate Code sections 4000 to 4465. We will verify your request by asking you to provide information related to your interactions with us, such as by confirming your email address and/or your state of residence. In some cases, we may need to collect additional information to verify your identity, such as a government issued ID.
			<br/>
Access: As a California resident, you may request access to your Personal Information we collect, use, disclose, or sell and the specific pieces of Personal Information we have collected about you, subject to specified exceptions. You may also request additional details about our information practices, including the categories of the Personal Information we collected, the sources of collection, categories of Personal Information we share for a business or commercial purpose, and the categories of third parties we share your information with. You may request access by sending an email to support@seniorcarehomes.org. You must provide your First and Last Name, email address, and phone number used with SCHO services in order for us to match you within our systems, as well as certify that you are a California resident.
			<br/>
Deletion: As a California resident, you may request that we delete the Personal Information we have collected from or about you. Please note that we may retain certain information as required or permitted by applicable law. You may request that we delete Personal Information about you by sending an email to support@seniorcarehomes.org. You must provide your First and Last Name, email address, and phone number used with SCHO services in order for us to match you within our systems, as well as certify that you are a California resident. If you request to delete your Personal Information, certain of our products and services may no longer be available to you.
			<br/>
Personal Information We Disclose or Sell (as defined by the CCPA): California law requires that we provide transparency about Personal Information we “sell,” which, for purposes of the CCPA, broadly means instances in which SCHO has shared Personal Information with certain senior care network providers and with partners and other third parties in exchange for valuable consideration. If in the preceding 12 months, SCHO may have disclosed Personal Information to senior care network providers to facilitate providing services to you and with partners and other third parties, this disclosure may be considered a sale under California law. In the preceding 12 months, we have “sold” the following categories of personal information to the following categories of third parties:
			<br/>
Category of Personal Information: Identifiers, such as your first name, last name, phone number, email address, and mailing address
			<br/>
Categories of Third Parties: senior care network providers, partners, and other third parties
			<br/>
Category of Personal Information: Information about the Services you are seeking for yourself or a Client
			<br/>
Category of Third Parties: Categories of Third Parties: Senior care network providers, partners, and other third parties
			<br/>
For information about opting out of these “sales,” please see the “Do Not Sell My Info” section below. We do not knowingly disclose or sell personal information of minors under 16 years old without affirmative authorization.
			<br/>
Do Not Sell My Info: As described above, we may provide Personal Information for the purpose of connecting you with senior care network providers to discuss services that you may be interested in for yourself or for a Client and to our partners and other third parties. We give you the option to opt out of such sharing at any time by sending an email to support@seniorcarehomes.org.
			<br/>
California’s Shine the Light law also permits residents of California to request certain details about how their information is shared with third parties for direct marketing purposes. Under the law, a business must either provide this information or permit California residents to opt in to, or opt out of, this type of sharing. SCHO qualifies for this alternative option. To opt out of having information about you shared with third parties for direct marketing purposes, please send an email to support@seniorcarehomes.org
			<br/>
			<br/>
			<br/>
SHARING OPT-OUT
			<br/>
As described above, we may provide Personal Information for the purpose of connecting you with senior care network providers or sell it to partners and other third parties. We give you the option to opt out of such sharing at any time by sending an email to support@seniorcarehomes.org
			<br/>
			<br/>
			<br/>
OPT OUT OF SCHO COMMUNICATIONS
			<br/>
At any time, you may update your notification settings in your user dashboard to adjust your communication preferences.
			<br/>
Promotional Communications: When you receive a newsletter or other promotional communications from us, you may indicate a preference to stop receiving further newsletters and other promotional communications by sending an email to support@seniorcarehomes.org
			<br/>
Mobile Push Notifications/Alerts: With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.
			<br/>
If you wish to opt out of communications with senior care network providers, you must contact them directly, as we do not control communications from senior care network providers.
			<br/>
			<br/>
			<br/>
CHANGES IN THIS PRIVACY POLICY
			<br/>
We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you.
			<br/>
			<br/>
			<br/>
GOVERNING LAW
			<br/>
SCHO owns and maintains the Site in Canada and the United States. We attempt to comply with local data protection, privacy, and consumer rights laws to the extent they may apply to our services. If you are uncertain whether this Privacy Policy conflicts with the applicable local privacy laws where you are located, you should not submit your Personal Information to the Site.
			<br/>
			<br/>
			<br/>
QUESTIONS AND CONCERNS
			<br/>
If you have any questions about this Privacy Policy or our privacy practices,
			<br/>
please contact us at support@seniorcarehomes.org.
			<br/>
			<br/>
		</p>
        </>
    );
}
import React from "react";

function StepsCard({ image, heading, content, index }) {
  return (
    <>
      <div className="lgTablet:bg-bgCard w-fit space-y-4 lgTablet:shadow-card rounded-2xl.5 p-10">
        <div className="flex items-center gap-2.5">
          <div className="h-7 w-7  lgTablet:h-12 lgTablet:w-12 bg-brown rounded-full flex items-center justify-center">
            <p className="text-base italic lgTablet:text-3xl text-bgCard  m-0">
              {index}
            </p>
          </div>
          <h3 className="text-lg  lgTablet:text-3xl text-primaryBlack">
            {heading}
          </h3>
        </div>
        <div className="lgTablet:ml-[69px] ml-10">
          <p className="p-24px text-primaryBlack">{content}</p>
        </div>
      </div>
    </>
  );
}

export default StepsCard;

import {NavLink} from 'react-router-dom';

export default function AgentSidebar() {
    return (
        <>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <NavLink className="nav-link" to='/agent/dashboard'>
                        Dashboard
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/agent/assessment'>
                        Assessments
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/agent/journey'>
                        Care Journey
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/agent/help'>
                        Help
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/agent/profile'>
                        Profile
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/agent/settings'>
                        Settings
                    </NavLink>
                </li>
            </ul>
        </>
    );
}
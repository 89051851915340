import { formatNumber } from '../../helpers/ad';
import {Link} from 'react-router-dom';



export default function EditListingCard({ad, user}) {




    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className='row'>
                    <div className='col-9 d-flex align-items-center'>
                        <p className='card-text'>{`${ad?.title}`}</p>
                    </div>
            
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                        <Link target='_blank' className='ml-3' to={`/listings/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`}>
                            <button className='btn btn-primary' style={{ textTransform: "capitalize" }}>View</button>
                        </Link>

                        <Link className='ml-3' to={`/provider/listings/edit/${ad?.slug?.title}/${user}/${ad._id}`}>
                            <button className='btn btn-warning' style={{ textTransform: "capitalize" }}>Edit</button>
                        </Link>
                    </div>

                </div>
            </div>
      </div>
      
    );
}
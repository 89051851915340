// importing npm modules
import axios from "axios";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import { Modal } from "antd";
import toast from "react-hot-toast";

import grayCheckmark from "./../../assets/images/gray-checkmark.png";
import greenCheckmark from "./../../assets/images/green-checkmark.png";

// -------------------------

export default function OutOfNetworkRequests() {
  // --- state ---
  const [open, setOpen] = useState(false);
  const [clientRequests, setClientRequests] = useState([]);
  const [isLoadingRequests, setIsLoadingRequests] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [receiverPhone, setReceiverPhone] = useState("");
  const [loading, setLoading] = useState(false);

  // --- functions ---
  const getClientRequests = async () => {
    // remove previous results
    setClientRequests([]);
    // show loading
    setIsLoadingRequests(true);

    // make the request
    const { data } = await axios.get(
      `/admin/requests/contact-out-of-network-providers`
    );

    // set state if successful
    if (data.success) {
      // set to state
      setClientRequests(data.data.allOpenContactRequests);
    }

    // hide loading
    setIsLoadingRequests(false);
  };

  const handleSharePhone = async () => {
    try {
      setLoading(true);

      const { data } = await axios.post(
        "/admin/requests/send-sms-out-network-provider",
        {
          receiverPhone: receiverPhone,
          requestId: currentRequest._id,
        }
      );

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        toast.success("SMS Sent");
        setOpen(false);
        setReceiverPhone("");
        getClientRequests();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // --- business logic ---

  useEffect(() => {
    getClientRequests();
  }, []);

  // --- template ---

  return (
    <>
      {/* page title */}
      <h1 className="p-4">Out of Network Providers Contact Requests</h1>

      {/* page content */}
      <div className="flex flex-row">
        {/* clients */}
        <div className="basis-2/5 py-4 pl-4">
          <div
            className="header-wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className="mb-4">
              OoN Contact Requests ({clientRequests.length})
            </h2>
          </div>

          <div className="pr-4 border-r border-red-900 h-[70vh] overflow-scroll">
            {isLoadingRequests ? (
              <span>loading ...</span>
            ) : (
              clientRequests.map((request) => {
                return (
                  // assessment card
                  <div
                    className="p-2 mb-2 border border-black"
                    key={request._id}
                  >
                    {/* header */}
                    <div className="flex flex-row justify-between mb-2">
                      <span className="text-lg font-bold">
                        Listing: {request.listingName}
                      </span>
                      {/*  */}
                      <div className="flex flex-row gap-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setCurrentRequest(request)}
                        >
                          Select
                        </button>
                      </div>
                    </div>

                    {/* details */}
                    <div>
                      <p className="mb-0">
                        created:{" "}
                        {format(new Date(request.createdAt), "dd MMM yyyy")}
                      </p>
                      <p className="mb-0">
                        last updated:{" "}
                        {format(new Date(request.updatedAt), "dd MMM yyyy")}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        {/* providers */}
        <div className="basis-3/5 p-4">
          <h2 className="mb-4">Details</h2>
          <div className="h-[70vh]">
            {currentRequest === null ? (
              <span>Select Contact Request</span>
            ) : (
              <>
                <div>
                  {/* status */}
                  <div className="flex flex-row justify-between mb-2">
                    <div className="flex flex-row gap-2 mr-2">
                      <div className="flex items-center">
                        Contacted by Admin?
                        {currentRequest?.contactedByAdmin_status ? (
                          <img src={greenCheckmark} alt="Green Checkmark" />
                        ) : (
                          <img src={grayCheckmark} alt="Gray Checkmark" />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 mr-2">
                      <div className="flex items-center">
                        Sign Up Link Sent?
                        {currentRequest?.signUpLinkSent_status ? (
                          <img src={greenCheckmark} alt="Green Checkmark" />
                        ) : (
                          <img src={grayCheckmark} alt="Gray Checkmark" />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 mr-2">
                      <div className="flex items-center">
                        Sign Up Link Opened?
                        {currentRequest?.signUpLinkOpened_status ? (
                          <img src={greenCheckmark} alt="Green Checkmark" />
                        ) : (
                          <img src={grayCheckmark} alt="Gray Checkmark" />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 mr-2">
                      <div className="flex items-center">
                        Account Created?
                        {currentRequest?.accountCreated_status ? (
                          <img src={greenCheckmark} alt="Green Checkmark" />
                        ) : (
                          <img src={grayCheckmark} alt="Gray Checkmark" />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* header - listing */}
                  <div className="flex flex-row justify-between mb-2">
                    <span className="text-lg font-bold">Listing Info</span>
                  </div>
                  {/* details - listing */}
                  <div className="mb-4">
                    <p className="mb-1">
                      ListingId: {currentRequest.listingId}
                    </p>
                    <p className="mb-1">
                      ListingId: {currentRequest.listingName}
                    </p>
                    <p className="mb-1">
                      Provider Name: {currentRequest.providerFirstName}
                    </p>
                    <p className="mb-1">
                      Phone:
                      <a
                        href={`tel:${currentRequest.providerPhone}`}
                        style={{ marginLeft: "5px" }}
                      >
                        {currentRequest.providerPhone}
                      </a>
                    </p>
                  </div>

                  {/* header-client */}
                  <div className="flex flex-row justify-between mb-2">
                    <span className="text-lg font-bold">Client Info</span>
                  </div>
                  {/* details-client */}
                  <div className="mb-4">
                    <p className="mb-1">Client Id: {currentRequest.clientId}</p>
                    <p className="mb-1">
                      Client Name: {currentRequest.clientFirstName}
                    </p>
                    <p className="mb-1">
                      Client Email: {currentRequest.clientEmail}
                    </p>
                    <p className="mb-1">
                      Client Phone:
                      <a
                        href={`tel:${currentRequest.clientPhone}`}
                        style={{ marginLeft: "5px" }}
                      >
                        {currentRequest.clientPhone}
                      </a>
                    </p>
                  </div>

                  {/* header-info */}
                  <div className="flex flex-row justify-between mb-2">
                    <span className="text-lg font-bold">Additional Info</span>
                  </div>
                  {/* details-info */}
                  <div className="mb-4">
                    <p className="mb-1">Record Id: {currentRequest._id}</p>
                    <p className="mb-1">
                      Engagement Id: {currentRequest.engagementId}
                    </p>
                  </div>

                  {/* header-actions */}
                  <div className="flex flex-row justify-between mb-2">
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Text Signup Link
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        title="Send Signup Link"
        footer={null}
      >
        <>
          <form>
            <div className="d-flex justify-content-center mt-4">
              <input
                type="phone"
                placeholder="Enter Provider's Cell.."
                value={receiverPhone}
                onChange={(e) => setReceiverPhone(e.target.value)}
                inputMode="phone"
                required
                className="col-6"
              />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div className="col-6 d-flex justify-content-center">
                <button
                  type="button"
                  className="bg-lightBrown text-white px-3 py-1 rounded-md  border-none text-[16px]"
                  onClick={handleSharePhone}
                >
                  Send SMS
                </button>
              </div>
            </div>
          </form>
        </>
      </Modal>
    </>
  );
}

import React, { useEffect } from "react";
import ContactForm from "../components/forms/ContactForm";
import { Helmet } from "react-helmet";

function ContactUs() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Contact our Senior Care Homes Support Team today and get the answers you need!"
        />
      </Helmet>
      <div className="flex justify-between">
        <div className="w-full max-w-lg lgTablet:max-w-full mx-auto lgTablet:w-1/2 px-[1rem] sm:px-[1.5rem] lg:px-[4rem] 2xl:px-[8%] !py-0 lgTablet:!py-12 txt-left">
          <h1 className="text-2xl lgTablet:text-4xl.5 font-medium text-primaryBlack mt-2.5 ">
            Contact Us
          </h1>

          <div className="mt-6 lgTablet:mt-10">
            <ContactForm />
          </div>
        </div>

        <div className="h-screen sticky top-0 w-1/2 bg-contact-bg bg-cover bg-center bg-no-repeat py-10 px-[1rem] sm:px-6 lg:px-8 2xl:px-[4%] hidden lgTablet:flex flex-col justify-end "></div>
      </div>
    </>
  );
}

export default ContactUs;


import {useAuth} from '../../context/auth';


export default function CurrentAssessmentForm({assessment}) {
    const [auth, setAuth] = useAuth();
    
    
    return (
        <>
            <div className="container text-capitalize" style={{padding: '5%', color: 'black'}}>
                
                {/* Search Area */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Search Area</h6>
                    <p>{assessment?.address}</p>
                </div>

                {/* Monthly Budget */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Monthly Budget</h6>
                    <p>${assessment?.budgetAmount}</p>
                </div>

                {/* Budget Sources */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Budget Sources</h6>
                    {assessment?.budgetSource_Private && <p>Private Pay</p>}
                    {assessment?.budgetSource_Medicaid && <p>Medicaid</p>}
                    {assessment?.budgetSource_Veteran && <p>Veteran Benefits</p>}
                    {assessment?.budgetSource_LTC && <p>Longterm Care Insurance</p>}
                    {assessment?.budgetSource_LifeInsurance && <p>Life Insurance</p>}
                    {assessment?.budgetSource_HomeEquity && <p>Home Equity</p>}
                    {assessment?.budgetSource_Pension && <p>Pension</p>}
                    {assessment?.budgetSource_Unknown && <p>Unknown</p>}
                    {assessment?.budgetSource_None && <p>None</p>}
                    {assessment?.budgetSource_AdditionalCheck && <p>{assessment?.budgetSource_AdditionalResponse}</p>}
                </div>

                {/* Level of Care */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Care Level Needed</h6>
                    {(assessment?.careNeeds === 'noCare') && <p>No Care Needs (Independent Living)</p>}
                    {(assessment?.careNeeds === 'lightCare') && <p>Light Care (medication, meals, laundry)</p>}
                    {(assessment?.careNeeds === 'advancedCare') && <p>Advanced Care (bathing, dressing, transferring)</p>}
                    {(assessment?.careNeeds === 'memoryCare') && <p>Memory Care (Alzheimers, Dementia, Wandering Risk)</p>}
                </div>

                {/* Main Care Needs */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>What are the main care needs?</h6>
                    <p>{(assessment?.careReason)}</p>
                </div>

                {/* Care Needs Noticed */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>When were these needs first noticed?</h6>
                    <p>{(assessment?.careStarted)}</p>
                </div>

                {/* Client Relationship to Patient */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Client Relationship to Patient</h6>
                    {assessment?.checkRelationToClient === 'self' && <p>Client is the Patient</p>}
                    {assessment?.checkRelationToClient === 'partner' && <p>Client is the Patient's Partner/Spouse</p>}
                    {assessment?.checkRelationToClient === 'parent' && <p>Client is the Patient's Child</p>}
                    {assessment?.checkRelationToClient === 'other' && <p>{assessment?.responseRelationToClient}</p>}
                </div>

                {/* Power of Attorney */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Does the Client have Power of Attorney over the Patient?</h6>
                    <p>{(assessment?.checkPOA)}</p>
                </div>

                {/* Urgency */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Move-In Urgency</h6>
                    {assessment?.urgency === 'asap' && <p>As soon as possible</p>}
                    {assessment?.urgency === 'lessOneMonth' && <p>Within one month</p>}
                    {assessment?.urgency === 'oneThreeMonth' && <p>In one to three months</p>}
                    {assessment?.urgency === 'moreThreeMonth' && <p>In more than three months</p>}
                </div>

                {/* Nurse Assessment */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Has the Client completed a nurse assessment within the past 6 months?</h6>
                    <p>{(assessment?.checkNurseAssessment)}</p>
                </div>

                {/* Is there a spouse or partner moving with the client? */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Is there a spouse or partner moving with the client?</h6>
                    {assessment?.checkAdditionalPartner === 'no' && <p>No</p>}
                    {assessment?.checkAdditionalPartner === 'yes' && <p>Yes: {assessment?.responseAdditionalPartner}</p>}
                </div>

                {/* Requested Accomodations */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Requested Accomodations</h6>
                    {assessment?.roomShared && <p>Shared Room</p>}
                    {assessment?.roomPrivate && <p>Private Room</p>}
                    {assessment?.bathShared && <p>Shared Bathroom</p>}
                    {assessment?.bathPrivate && <p>Private Bathroom</p>}
                    {assessment?.showerShared && <p>Shared Shower</p>}
                    {assessment?.showerPrivate && <p>Private Shower</p>}
                </div>

                {/* Requested Activities */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Requested Activities</h6>
                    {assessment?.activities_activityCoordinator && <p>Activites Coordinator</p>}
                    {assessment?.activities_artsCrafts && <p>Arts & Crafts</p>}
                    {assessment?.activities_beautician && <p>Beautician</p>}
                    {assessment?.activities_cableTv && <p>Cable TV</p>}
                    {assessment?.activities_smartTv && <p>Smart TV</p>}
                    {assessment?.activities_emergencyCallButton && <p>Emergency Call Button</p>}
                    {assessment?.activities_tableGames && <p>Tables Games</p>}
                    {assessment?.activities_houseGenerator && <p>House Generator</p>}
                    {assessment?.activities_hospitalBed && <p>Hospital Bed</p>}
                    {assessment?.activities_hoyerLift && <p>Hoyer Lift</p>}
                    {assessment?.activities_wifi && <p>WiFi</p>}
                    {assessment?.activities_libraryOnWheels && <p>Library on Wheels</p>}
                    {assessment?.activities_music && <p>Music</p>}
                    {assessment?.activities_newspaper && <p>Newspaper</p>}
                    {assessment?.activities_outdoorCommonArea && <p>Outdoor Common Area</p>}
                    {assessment?.activities_telephone && <p>Telephone</p>}
                    {assessment?.activities_tvInRoom && <p>Tv in the Room</p>}
                    {assessment?.activities_wheelchairAccess && <p>Wheelchair Access</p>}
                </div>

                {/* Requested Amenities */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Requested Amenities</h6>
                    {assessment?.amentities_awakeNightStaff && <p>Awake Night Staff</p>}
                    {assessment?.amentities_twentyFourHourCare && <p>Twenty-Four Hour Care</p>}
                    {assessment?.amentities_twoCaregivers && <p>Two Caregivers</p>}
                    {assessment?.amentities_threeCaregivers && <p>Three Caregivers</p>}
                    {assessment?.amentities_catheter && <p>Catheter</p>}
                    {assessment?.amentities_colostomyBag && <p>Colostomy Bag</p>}
                    {assessment?.amentities_dementia && <p>Dementia</p>}
                    {assessment?.amentities_alzhiemers && <p>Alzhiemers</p>}
                    {assessment?.amentities_developmentalDisabilities && <p>Developmental Disabilities</p>}
                    {assessment?.amentities_diabetesCheck && assessment?.amentities_diabetesInjectionNeeded && <p>Diabetes: Injection Support Needed</p>}
                    {assessment?.amentities_diabetesCheck && !assessment?.amentities_diabetesInjectionNeeded && <p>Diabetes: No Injection Support Needed</p>}
                    {assessment?.amentities_feedTube && <p>Feedtube</p>}
                    {assessment?.amentities_femaleOnly && <p>Female only Residents</p>}
                    {assessment?.amentities_maleOnly && <p>Male only Residents</p>}
                    {assessment?.amentities_homeDoctor && <p>Home Doctor</p>}
                    {assessment?.amentities_hospice && <p>Hospice</p>}
                    {assessment?.amentities_housekeeping && <p>Housekeeping</p>}
                    {assessment?.amentities_laundry && <p>Laundry</p>}
                    {assessment?.amentities_massageTherapy && <p>Massage Therapy</p>}
                    {assessment?.amentities_medicineDelivery && <p>Medicine Delivery</p>}
                    {assessment?.amentities_medicineManagement && <p>Medicine Management</p>}
                    {assessment?.amentities_ownerProvidesCare && <p>Owner Provides Care</p>}
                    {assessment?.amentities_oxygenTherapy && <p>Oxygen Therapy</p>}
                    {assessment?.amentities_parkinson && <p>Parkinson</p>}
                    {assessment?.amentities_petCare && <p>Pet Care</p>}
                    {assessment?.amentities_podiatrist && <p>Podiatrist</p>}
                    {assessment?.amentities_physicalTherapy && <p>Physical Therapy</p>}
                    {assessment?.amentities_respite && <p>Respite Care</p>}
                    {assessment?.amentities_rn && <p>RN Operated</p>}
                    {assessment?.amentities_stroke && <p>Stroke Care</p>}
                    {assessment?.amentities_tranDoctor && <p>Transport to Doctor</p>}
                    {assessment?.amentities_tranShopping && <p>Transport to Shipping</p>}
                    {assessment?.amentities_visualHearingImpaired && <p>Vision Hearing Impaired</p>}
                    {assessment?.amentities_woundCare && <p>Wound Treatment</p>}
                </div>

                {/* Cultural Needs */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Particular cultural or language needs?</h6>
                    {assessment?.checkCulturalNeed === 'no' && <p>No</p>}
                    {assessment?.checkCulturalNeed === 'yes' && <p>Yes: {assessment?.responseCulturalNeed}</p>}
                </div>

                {/* Diet Needs */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Particular diet or food needs?</h6>
                    {assessment?.checkDietNeeds === 'no' && <p>No</p>}
                    {assessment?.checkDietNeeds === 'yes' && <p>Yes: {assessment?.responseDietNeeds}</p>}
                </div>

                {/* Religion Needs */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Any racial or religous concerns/prejudices?</h6>
                    {assessment?.checkRaceReligion === 'no' && <p>No</p>}
                    {assessment?.checkRaceReligion === 'yes' && <p>Yes: {assessment?.responseRaceReligion}</p>}
                </div>

                {/* Child Restrictions */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Any issues with having children around?</h6>
                    {assessment?.checkEnjoyKids === 'no' && <p>No</p>}
                    {assessment?.checkEnjoyKids === 'yes' && <p>Yes: {assessment?.responseEnjoyKids}</p>}
                </div>

                {/* Pet Restrictions */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Any issues with having pets(dogs, cats, etc) around?</h6>
                    {assessment?.checkPetAdverse === 'no' && <p>No</p>}
                    {assessment?.checkPetAdverse === 'yes' && <p>Yes: {assessment?.responsePetAdverse}</p>}
                </div>

                {/* Sleeping Issues */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Any particular sleeping habits provider should be aware of? (wakes up frequently, napping throughout day, etc..)</h6>
                    {assessment?.checkSleepingHabits === 'no' && <p>No</p>}
                    {assessment?.checkSleepingHabits === 'yes' && <p>Yes: {assessment?.responseSleepingHabits}</p>}
                </div>

                {/* Wandering */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Does the client wander or exit seeking?</h6>
                    {assessment?.checkWandering === 'no' && <p>No</p>}
                    {assessment?.checkWandering === 'yes' && <p>Yes: {assessment?.responseWandering}</p>}
                </div>

                {/* Combative */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Is the client combative or agressive?</h6>
                    {assessment?.checkCombative === 'no' && <p>No</p>}
                    {assessment?.checkCombative === 'yes' && <p>Yes: {assessment?.responseCombative}</p>}
                </div>

                {/* Wandering */}
                <div className="row justify-content-start ml-1 mb-5">
                    <h6>Is the client currently working with other agencies?</h6>
                    {assessment?.checkOtherAgencies === 'no' && <p>No</p>}
                    {assessment?.checkOtherAgencies === 'yes' && <p>Yes: {assessment?.responseOtherAgencies}</p>}
                </div>

            </div>
        </>
    )
}
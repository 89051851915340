import { useNavigate } from "react-router-dom";
import { useState, useCallback } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import "../../styles/ImageGallery.css";
import { ReactComponent as FolderIcon } from "../../assets/icons/folderIcon.svg";
import LikeUnlike from "./LikeUnlike";
import ShareListing from "./ShareListing";

export default function ImageGallery({ photos, ad }) {
  // state
  const [current, setCurrent] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // hooks
  const navigate = useNavigate();

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrent(index);
    setIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrent(0);
    setIsOpen(false);
  };

  return (
    <div>
      <div className="hidden md:block">
        {photos.length > 1 ? (
          <div className="grid grid-cols-12 mt-3 !gap-2 lgTablet:!gap-5">
            <div className="col-span-8 max-h-96">
              <img
                src={photos[0].src}
                alt="Main"
                className="object-cover rounded-xl"
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                onClick={() =>
                  openLightbox(null, { photo: photos[0], index: 0 })
                }
              />
            </div>

            <div className="col-span-4 max-h-96  grid !gap-2 lgTablet:!gap-5 grid-cols-2 grid-rows-2  ">
              {photos.slice(1, 4).map((item, index) => (
                <div
                  className={`${index === 0 && "col-span-2"} ${
                    index > 0 && "col-span-1"
                  } relative`}
                >
                  <img
                    src={item.src}
                    alt="secondary"
                    className="object-cover rounded-xl"
                    style={{ width: "100%", height: "100%", cursor: "pointer" }}
                    onClick={() =>
                      openLightbox(null, { photo: photos[0], index: 0 })
                    }
                  />
                  {index === 2 && (
                    <button
                      onClick={() =>
                        openLightbox(null, { photo: photos[0], index: 0 })
                      }
                      className="btn-sm flex items-center py-1 gap-2 bg-white absolute px-3 bottom-2 left-1/2 -translate-x-1/2"
                    >
                      <FolderIcon className="h-6 w-6" />{" "}
                      <span className="!text-xs whitespace-nowrap  font-roboto-flex font-normal">
                        See all photos
                      </span>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            className="col-12 d-flex align-items-center"
            style={{ maxWidth: "100%", maxHeight: "300px", overflow: "hidden" }}
          >
            <img
              src={photos[0].src}
              alt="Main"
              style={{ width: "100%", height: "auto", cursor: "pointer" }}
              onClick={() => openLightbox(null, { photo: photos[0], index: 0 })}
            />
          </div>
        )}
      </div>
      <div className="[&_img]:object-contain block md:hidden relative adview-slider">
        <Carousel
          currentIndex={current}
          views={photos.map((x) => ({
            ...x,
            srcset: x.srcSet,
            caption: x.title,
          }))}
          styles={{ view: () => ({ height: 300 }) }}
        />
        <div className="flex items-center justify-end gap-2.5 absolute top-0 w-full px-2.5 py-2">
          <div className="flex items-center gap-2.5">
            <ShareListing ad={ad} />
            <div>
              <LikeUnlike ad={ad} />
            </div>
          </div>
        </div>
      </div>

      <ModalGateway>
        {isOpen ? (
          <Modal onClose={closeLightbox}>
            <div className="d-flex justify-content-center mx-auto text-center">
              <div
                className="col col-lg-3 col-md-2 d-none d-sm-block"
                onClick={closeLightbox}
              ></div>
              <Carousel
                currentIndex={current}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
              <div
                className="col col-lg-3 col-md-2 d-none d-sm-block"
                onClick={closeLightbox}
              ></div>
            </div>
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const careAssessment = {
    context: {
        visitorId: null,
        userId: null,
        referrerUrl: null,
        startStatus: false,
        startDatetime: null,
        endStatus: false,
        endDatetime: null,
        completedQuestions: {
            question1: false,
            question2: false,
            question3: false,
            question4: false,
            question5: false, 
            question6: false,
            question7: false,
            question8: false,
            question9: false,
            question10: false,
        },
    },
    question1: {
        type: 'Single Choice',
        question: "Who are you searching for?",
        hint: 'Helps providers know who they are speaking with.',
        options: [
            'Parent',
            'Spouse',
            'Myself',
            'Other'
        ],
        answer: {
            parent: false,
            spouse: false,
            myself: false,
            other: false,
        },
        skip: false,
    },
    question2: {
        type: 'Single Choice',
        question: "How quickly do you need to find care?",
        hint: 'Helps us prioritize personal service if necessary.',
        options: [
            'Immediately',
            'Within 30 days',
            'Within 90 days',
            'No rush'
        ],
        answer: {
            immediately: false,
            thirtyDays: false,
            ninetyDays: false,
            noRush: false,
        },
        skip: false,
    },
    question3: {
        type: 'GeoInput',
        question: "What is your preferred location?",
        hint: 'Please enter the primary location to your search even if you are open to a few different areas.',
        options: null,
        answer: {
            city: null,
            county: null,
            lat: null,
            lng: null,
            state: null,
        },
        skip: false,
    },
    question4: {
        type: 'Single Choice',
        questionA: "What is your level of mobility?",
        questionB: "What is your loved one's level of mobility?",
        hintA: "Understanding your mobility will help us match you to the right provider.",
        hintB: "Understanding your loved one's mobility will help us match them to the right provider.",
        options: [
            'Good (can get around independently)',
            'Can walk with help (cane/walker)',
            'Wheelchair',
            'Immobile (temporary/permanent)'
        ],
        answer: {
            independent: false,
            help: false,
            wheelchair: false,
            immobile: false,
        },
        skip: false,
    },
    question5: {
        type: 'Multiple Choice',
        questionA: "Do you need assistance with any of the following? (Select all that apply)",
        questionB: "Do they need assistance with any of the following? (Select all that apply)",
        hintA: 'Care & lifestyle are important considerations when searching for the right provider.',
        hintB: 'Care & lifestyle are important considerations when searching for the right provider.',
        options: [
            "Medication",
            "Toileting",
            "Bathing",
            "Diabetic Care",
            "Special Diet",
            "Housekeeping",
            "Social Activities",
            "None/I don’t know",
        ],
        answer: {
            medication: false,
            toileting: false,
            bathing: false,
            diabetic: false,
            diet: false,
            housekeeping: false,
            social: false,
            idk: false,
        },
        skip: false,
    },
    question6: {
        type: 'Multiple Choice',
        questionA: "Have you experienced any of the following? (Select all that apply)",
        questionB: "Have they experienced any of the following? (Select all that apply)",
        hintA: 'Understanding your current behaviours will help us identify the right provider.',
        hintB: 'Understanding their current behaviours will help us identify the right provider.',
        options: [
            "Wandering",
            "Aggressiveness",
            "Hallucinations",
            "Withdrawal",
            "Sundowning",
            "Judgment Loss",
            "Memory Loss",
            "Needs 24-hr Care",
            "Exit-seeking",
            "None/I don’t know",
        ],
        answer: {
            wander: false,
            aggressive: false,
            hallucination: false,
            withdrawl: false,
            sundown: false,
            judgement: false,
            memory: false,
            care24: false,
            exitSeek: false,
            idk: false,
        },
        skip: false,
    },
    question7: {
        type: 'Multiple Choice',
        questionA: "Preferred Accommodations? (Select all that apply)",
        questionB: "Preferred Accommodations? (Select all that apply)",
        hintA: 'There are a range of room types at each facility based on budget and lifestyle preferences.',
        hintB: 'There are a range of room types at each facility based on budget and lifestyle preferences.',
        options: [
            'Private Room, Private Bath, Private Shower',
            'Private Room, Private Bath, Shared Shower',
            'Private Room, Shared Bath, Shared Shower',
            'Shared Room, Shared Bath, Shared Shower',
        ],
        answer: {
            pR_pB_pS: false,
            pR_pB_sS: false,
            pR_sB_sS: false,
            sR_sB_sS: false,
        },
        skip: false,
    },
    question8: {
        type: 'Single Choice',
        questionA: "What is the total monthly budget you can afford for senior living?",
        questionB: "What is the total monthly budget they can afford for senior living?",
        hintA: "Senior living costs vary depending on location and services provided. Total costs of care typically include the cost of accommodations and food plus the costs of services such as personal care, social & recreational, health & wellness, transportation, and acute care needs. Discussing one on one with providers is the best way to determine exactly what's offered and for what price.",
        hintB: "Senior living costs vary depending on location and services provided. Total costs of care typically include the cost of accommodations and food plus the costs of services such as personal care, social & recreational, health & wellness, transportation, and acute care needs. Discussing one on one with providers is the best way to determine exactly what's offered and for what price.",
        options: [
            'Less than $2,000',
            '$2,000 - $2,499',
            '$2,500 - $2,999',
            '$3,000 - $3,999',
            '$4,000 - $4,999',
            '$5,000 - $5,999',
            '$6,000 - $6,999',
            'Over $7,000',
        ],
        answer: {
            L1: false,
            L2: false,
            L3: false,
            L4: false,
            L5: false,
            L6: false,
            L7: false,
            L8: false,
        },
        skip: false,
    },
    question9: {
        type: 'Multiple Choice',
        questionA: "How do you plan to pay for care? (Select all that apply)",
        questionB: "How do they plan to pay for care? (Select all that apply)",
        hintA: null,
        hintB: null,
        options: [
            'Medicaid',
            'Personal Savings',
            'Family Support',
            'Stocks, Bonds, CDs or IRAs',
            'Long-term Care Insurance',
            '401Ks',
            'Monthly Income',
            'Home to Sell',
            'VA Benefits',
            'Other Assets',
        ],
        answer: {
            medicaid: false,
            personalSavings: false,
            familySupport: false,
            portfolio: false,
            ltc: false,
            k401: false,
            income: false,
            sellHome: false,
            vaBenefits: false,
            other: false,
        },
        skip: false,
    },
    question10: {
        type: 'Contact',
        questionA: "Final information needed in order to share results.",
        questionB: "Final information needed in order to share results.",
        hintA: "We don't sell your information and only share it with Providers that match your specific needs.",
        hintB: "We don't sell your information and only share it with Providers that match your specific needs.",
        options: null,
        answer: {
            firstname: null,
            email: null,
            phone: null,
        },
    },
};
     
export { careAssessment };
import {Badge} from 'antd';
import {Link} from 'react-router-dom';
import genericListing from '../../listing.png';

export default function AdCardSavedListings({ad}) {
    return (
        <div className="p-2 gx-4 gy-4">
            <Link to={`/listing/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`}>
                <Badge.Ribbon 
                    text={`${ad?.licenseNumber === null ? "Unlicensed" : "Licensed"}`}
                    color={`${ad?.licenseNumber === null ? "red" : "blue"}`}
                    style={{position: 'absolute', top:40}}
                >
                    <Badge.Ribbon 
                        text={`${ad?.subscriptionType === null ? "Out-of-Network" : "In-Network"}`}
                        color={`${ad?.licenseNumber === null ? "gray" : "green"}`}
                        style={{position: 'absolute', top:10}}
                    >
                        <div className="card hoverable shadow">
                            <img 
                                src={`${ad?.photos?.length > 0 ? (ad?.photos?.[0].Location) : (genericListing)}`} 
                                alt={`${ad?.type}-${ad?.address}`} 
                                style={{height: '250px', objectFit: 'cover'}}
                            />

                            <div className="card-body">
                                <div>
                                    <h3>{ad?.title}</h3>
                                    <p className='card-text'>{`${ad?.googleMap?.[0].city}, ${ad?.googleMap?.[0].administrativeLevels?.level1short}`}</p>
                                    <p className='card-text'>{`${ad?.facilityType}`}</p>
                                </div>

                            </div>
                        </div>
                    </Badge.Ribbon>
                </Badge.Ribbon>
            </Link>
        </div>
        
    );
}
import { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import { useAuth } from '../../context/auth';


export default function ClientSettings() {

    // state
    const [auth, setAuth] = useAuth();

    const [loading, setLoading] = useState('');
    
    const [password, setPassword] = useState('');
    const [passwordRetype, setPasswordRetype] = useState("");

    const [emailAdvertise, setEmailAdvertise] = useState();
    const [textAdvertise, setTextAdvertise] = useState();
    const [voiceAdvertise, setVoiceAdvertise] = useState();

    const [emailMarket, setEmailMarket] = useState();
    const [textMarket, setTextMarket] = useState();
    const [voiceMarket, setVoiceMarket] = useState();

    const [emailEssential, setEmailEssential] = useState();
    const [textEssential, setTextEssential] = useState();
    const [voiceEssential, setVoiceEssential] = useState();
    


    useEffect(() => {
        getNotificationSettings();
    }, []);


    const getNotificationSettings = async () => {
        try {
            setLoading(true);
            const {data} = await axios.post('/users/data-privacy/get-options', {
                userId: auth.user._id,
            });

            if(data?.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                setEmailAdvertise(data.data.dataPrivacyOptions.advertising.email);
                setEmailEssential(data.data.dataPrivacyOptions.essential.email);
                setEmailMarket(data.data.dataPrivacyOptions.marketingAndAnalytics.email);

                setTextAdvertise(data.data.dataPrivacyOptions.advertising.text);
                setTextMarket(data.data.dataPrivacyOptions.marketingAndAnalytics.text);
                setTextEssential(data.data.dataPrivacyOptions.essential.text);

                setVoiceAdvertise(data.data.dataPrivacyOptions.advertising.voice);
                setVoiceMarket(data.data.dataPrivacyOptions.marketingAndAnalytics.voice);
                setVoiceEssential(data.data.dataPrivacyOptions.essential.voice);

                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        if (passwordRetype !== password) {
            toast.error("Passwords do not match. Please try again.");
            return;
        }

        try {
            setLoading(true);
            const {data} = await axios.put('/update-password', {
                password,
            });
            if(data?.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Password Updated");
                
                // Reload the current page
                window.location.href = '/client/settings';
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    
    const handleNotificationsUpdate = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const {data} = await axios.post('/users/data-privacy/update-options', {
                userId: auth.user._id,
                dataPrivacyOptions: {
                    advertising: {
                        email: emailAdvertise,
                        text: textAdvertise,
                        voice: voiceAdvertise,
                    },
                    marketingAndAnalytics: {
                        email: emailMarket,
                        text: textMarket,
                        voice: voiceMarket,
                    },
                },
            });

            if(data?.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                toast.success("Notification Settings Updated");
                setLoading(false);

                getNotificationSettings();
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };



    return (
        <div>
            {auth.user.credentialType === 'custom' &&
                <>
                    <div className="container text-center mt-5 mb-3">
                        <h3 className="display-4 text-dark">Change Password</h3>
                    </div>
                    <div className="container justify-content-center">
                        <div className="row justify-content-center">
                            <div className="col col-lg-6 col-md-9">
                                <form onSubmit={handlePasswordSubmit}>
                                    <input 
                                        type='password' 
                                        placeholder='Enter New Password' 
                                        className='form-control mb-4' 
                                        style={{ border: '1px solid black' }}
                                        value={password}
                                        required
                                        onChange={e => setPassword(e.target.value)} 
                                    />
                                    {password && (
                                        <input 
                                            type="password" 
                                            placeholder="Re-Enter Password" 
                                            className="form-control mb-4"
                                            style={{ border: '1px solid black' }}
                                            value={passwordRetype}
                                            required
                                            onChange={(e) => setPasswordRetype(e.target.value)}
                                        />
                                    )}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button className="btn btn-primary btn-sm col-6 p-1 mb-5" style={{ textTransform: "capitalize" }} disabled={loading}>
                                            {loading ? 'Processing' : 'Update Password'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div className="container text-center mt-5 mb-3">
                <h3 className="display-4 text-dark">Notification Settings</h3>
            </div>
            <div className="container justify-content-center">
                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-md-9">

{/*                         <div className='row'>
                            <h5 className="display-6 text-dark d-flex justify-content-center">Advertising</h5>
                        </div>
                        <div className='row'>
                            <div className='col text-right'>
                                <label htmlFor="emailAdvertise">Email</label>
                            </div>

                            <div className='col'>
                                <input
                                type="checkbox"
                                id="emailAdvertise"
                                name="emailAdvertise"
                                checked={emailAdvertise} // Use 'checked' for checkboxes
                                onChange={(e) => {
                                    setEmailAdvertise(e.target.checked);
                                }}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col text-right'>
                                <label htmlFor="textAdvertise">SMS</label>
                            </div>

                            <div className='col'>
                                <input
                                type="checkbox"
                                id="textAdvertise"
                                name="textAdvertise"
                                checked={textAdvertise} // Use 'checked' for checkboxes
                                onChange={(e) => {
                                    setTextAdvertise(e.target.checked);
                                }}
                                />
                            </div>
                        </div> */}


                        <div className='row'>
                            <h5 className="display-6 text-dark mt-4 d-flex justify-content-center">Promotional</h5>
                        </div>
                        <div className='row'>
                            <div className='col text-right'>
                                <label htmlFor="emailMarket">Email</label>
                            </div>

                            <div className='col'>
                                <input
                                type="checkbox"
                                id="emailMarket"
                                name="emailMarket"
                                checked={emailMarket} // Use 'checked' for checkboxes
                                onChange={(e) => {
                                    setEmailMarket(e.target.checked);
                                }}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col text-right'>
                                <label htmlFor="textMarket">SMS</label>
                            </div>

                            <div className='col'>
                                <input
                                type="checkbox"
                                id="textMarket"
                                name="textMarket"
                                checked={textMarket} // Use 'checked' for checkboxes
                                onChange={(e) => {
                                    setTextMarket(e.target.checked);
                                }}
                                />
                            </div>
                        </div>


                        <div className='row'>
                            <h5 className="display-6 text-dark mt-4 d-flex justify-content-center">Essentials</h5>
                        </div>
                        <div className='row'>
                            <div className='col text-right'>
                                <label htmlFor="emailEssential">Email</label>
                            </div>

                            <div className='col'>
                                <input
                                type="checkbox"
                                id="emailEssential"
                                name="emailEssential"
                                checked={emailEssential} // Use 'checked' for checkboxes
                                disabled
                                onChange={(e) => {
                                    setEmailEssential(e.target.checked);
                                }}
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col text-right'>
                                <label htmlFor="textEssential">SMS</label>
                            </div>

                            <div className='col'>
                                <input
                                type="checkbox"
                                id="textEssential"
                                name="textEssential"
                                checked={textEssential} // Use 'checked' for checkboxes
                                disabled
                                onChange={(e) => {
                                    setTextEssential(e.target.checked);
                                }}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={handleNotificationsUpdate} className="btn btn-primary btn-sm col-6 p-1 mt-5 mb-5" style={{ textTransform: "capitalize" }} disabled={loading}>
                                {loading ? 'Processing' : 'Update Notification Settings'}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
import { useEffect } from "react";
import {useParams, useNavigate} from "react-router-dom";
import toast from 'react-hot-toast';
import axios from "axios";
import {useAuth} from '../../context/auth';
import { getCookie } from "../../helpers/utilities/Cookie";


export default function AccessAccount() {
    // context
    const [auth, setAuth] = useAuth();

    // hooks
    const {token} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) requestAccess();
    }, [token]);

    const requestAccess = async () => {
        try {
            const {data} = await axios.post(`/access-account`, {
                resetCode: token,
                visitorId: getCookie('visitorId'),
            });
            if(data?.error) {
                toast.error(data.error);
            } else {
                // save in local storage
                localStorage.setItem('auth', JSON.stringify(data));
                // save in context
                setAuth(data);
                toast.success('Please update your password');

                const role = data?.user?.role;
                var url = '/';

                switch (role) {
                    case 'Client':
                        url = '/client/settings';
                        break;
                    case 'Provider':
                        url = '/provider/settings';
                        break;
                    default:
                        url = '/settings';
                        break;
                }

                navigate(url);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong. Please contact customer service for more support.')
        }
    }

    return (
        <div className="display-4 d-flex justify-content-center align-items-center vh-100" style={{marginTop: '-5%'}}>
            Please wait..
        </div>
    );
}
import { formatNumber } from '../../helpers/ad';
import {Link} from 'react-router-dom';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import { useEffect, useState } from 'react';


export default function ClaimListingCard({ad}) {

    const [auth, setAuth] = useAuth();
    const [loading, setLoading] = useState(false);


    
    const handleClaimListing = async () => {
        setLoading(true);

        try {
            const { data } = await axios.post(`/listings/claim/by-listing-id`, {
                userId: auth.user._id,
                listingId: ad.listing._id,
            });
        
            setLoading(false);

            // Reload the current page
            window.location.href = '/provider/listings';
        } catch (error) {
            console.log(error);

            setLoading(false);
        }
    };

    


    return (
        <>

                <div className="card mb-4">
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-9 d-flex align-items-center'>
                                <p className='card-text' style={{ textTransform: "capitalize" }}>{`${ad?.listing.title}`}</p>
                            </div>
                            <div className='col-3 d-flex justify-content-end align-items-center'>
                            <Link target='_blank' className='ml-3' to={`/listings/${ad.listing.slug.type}/${ad.listing.slug.state}/${ad.listing.slug.county}/${ad.listing.slug.city}/${ad.listing.slug.title}`}>
                                            <button className='btn btn-primary' style={{ textTransform: "capitalize" }}>View</button>
                                        </Link>
                                        <button onClick={handleClaimListing} className='btn btn-warning ml-3' style={{ textTransform: "capitalize" }}>Claim</button>
                            </div>
                        </div>
                    </div>
                </div>

        </>
    );
}
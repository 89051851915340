import  {useState, useEffect, useParams } from 'react';
import {useAuth} from "../../context/auth";

import FullMessager from '../../components/forms/FullMessager';


export default function ClientInbox() {

    return (
        <div>
            <div className='container-fluid'>
                <div className="container text-center mt-3">
                    <h3 className="display-4 text-dark">Inbox</h3>
                </div>
                <div className='row'>
                    <div className='container' style={{padding: '3%'}}>
                        <div className='row mb-2' style={{border: '1px solid black'}}>
                            <div className='container'>
                                <div className='row'>
                                    <FullMessager />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
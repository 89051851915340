import React, { useEffect } from "react";
import SocialRegister from "../../components/auth/SocialRegister";
import RegisterForm from "../../components/forms/RegisterForm";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthLayout from "../../components/misc/authLayout";
import { FaArrowRight } from "react-icons/fa";

function RegisterProvider() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Provider Sign Up</title>
        <meta
          name="description"
          content="Sign up as a Care Provider at SeniorCareHomes.Org"
        />
      </Helmet>
      <AuthLayout
        bgClass="bg-register-right"
        imageText={
          '"The platform connected us directly with local providers, allowing us to compare options and make the right decision. Highly recommend it to anyone seeking care for their loved ones!" - Chelsea (Port Orchard, WA)'
        }
        imageTextClass="lgTablet:text-xl desktop:text-3xl text-center font-normal italic font-roboto-flex"
      >
        <div className="!px-5 lgTablet:!px-10 pb-24 desktop:pb-0 desktop:!px-20 space-y-6 lgTablet:space-y-10 desktop:space-y-16 ">
          <div className="space-y-2.5 lgTablet:space-y-6 desktop:space-y-10">
            <h1 className="text-lg lgTablet:text-2xl desktop:text-5xl font-bold desktop:font-medium  text-primaryBlack ">
              Join as a Provider
            </h1>
            <p className="text-sm desktop:text-2xl font-roboto-flex text-grayy">
              This option is for anyone searching for care from themselves or
              their loved one. Continue using Google or fill out the form below.
            </p>
          </div>

          <div className="space-y-6 desktop:space-y-10 desktop:px-8">
            <div>
              <SocialRegister />
            </div>
            <div className="flex gap-3 items-center justify-between">
              <div className="h-[1px] bg-lightGray w-full" />
              <p className="m-0 text-primaryBlack text-xl italic">or</p>
              <div className="h-[1px] bg-lightGray w-full" />
            </div>
            <div>
              <RegisterForm />
            </div>
            <div className="text-center flex-align-center justify-center">
              <p className="p-16px font-roboto-flex italic text-primaryBlack mb-0">
                Already have an account
              </p>
              <div className="ml-[12px] flex-align-center">
                <Link
                  to="/login"
                  className="no-underline hover:no-underline flex-align-center gap-2.5"
                >
                  <span className="p-16px text-green italic">Login</span>
                  <FaArrowRight className="text-green" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default RegisterProvider;

import React, { useEffect } from "react";
import { getCookie } from "../helpers/utilities/Cookie.js";
import { useAuth } from "../context/auth.js";
import { saveToLocalStorage, getFromLocalStorage } from "../helpers/utilities/LocalStorage.js";
import axios from "axios";

export const UpdateLocalStorage = ({ children }) => {
    const [auth, setAuth] = useAuth();
    const visitorId = getCookie("visitorId");
    const userId = auth?.user ? auth?.user?._id : null;
    const actionSource = 'websiteLoading';

    // fetch remote care assessment
    const getRemoteCareAssessment = async () => {
        try {
            const { data } = await axios.post(
                `/assessments/create-update-assessment/findByVisitorUser`, {
                    userId,
                    visitorId,
                    actionSource,
                }
            );

            if (data?.error) {
                return null;
            } else {
                return data?.data;
            };

        } catch (error) {
        console.log(error);
        };

    };

    // pull assessment if exists from local storage or db on page load
    useEffect(() => {
        const fetchCareAssessment = async () => {
            // look locally first for an assessment, quicker load and if exists locally, certainly exists in db
            const localCareAssessment = getFromLocalStorage("care-assessment");

            if (!localCareAssessment) {
                try {
                    // will always return an assessment, either existing or brand new
                    const remoteCareAssessment = await getRemoteCareAssessment();

                    if(remoteCareAssessment) {
                        if (remoteCareAssessment.context.endStatus === true) {
                            saveToLocalStorage("care-assessment-complete", true);
                            saveToLocalStorage("care-assessment", remoteCareAssessment);
                        } else {
                            saveToLocalStorage("care-assessment-complete", false);
                            saveToLocalStorage("care-assessment", remoteCareAssessment);
                        };
                    } else {
                        saveToLocalStorage("care-assessment-complete", false);
                    };

                    return;
                } catch (error) {
                    console.error("Error fetching remote assessment:", error);
                };
            }
        };

        fetchCareAssessment();
    }, []);

    return <>{children}</>;
};
import React, { useEffect } from "react";
import anchor1 from "../assets/images/anchorProvider1.png";
import anchor2 from "../assets/images/anchorProvider2.png";
import anchor3 from "../assets/images/anchorProvider3.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnchorCard from "../components/cards/anchorCard";

function AnchorProvider() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Helmet>
        <title>Meet the Senior Care Needs Of Your Community</title>
        <meta
          name="description"
          content="Talk directly with clients & families, schedule tours, and place residents in your home, all in one place."
        />
      </Helmet>
      <div className="space-y-10 pb-5">
        <div className="bg-anchor-bg bg-auto md:bg-cover bg-no-repeat bg-right md:bg-center w-full  flex-center">
          <div className="bg-black/50 w-full py-24 flex items-center justify-center">
            <div className="max-container">
              <div className="px-[1rem] sm:px-[4rem] md:px-28 lg:px-40 text-center">
                <h1 className="text-2xl lgTablet:text-5xl  text-white text-center">
                  A New Way Of Filling Your Home’s Vacancy
                </h1>
                <p className="text-base lgTablet:text-2xl font-normal text-white text-center px-[10px] sm:px-[2.5rem] md:px-20 xl:px-56 mt-[1.5rem]">
                  Streamline your placements, attract more clients, and grow
                  your senior care business without agent fees
                </p>

                <Link to="/register/provider">
                  <button className="btn-sm bg-green text-white border-none">
                    Join our Network
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="max-container  lgTablet:bg-bgPink !p-5 lgTablet:!p-10 space-y-16 rounded-2xl.5">
          <AnchorCard
            heading="Why Choose SeniorCareHomes.org for Your Business?"
            para="SeniorCareHomes.org is your platform to boost your visibility, connect directly with potential clients, and manage your resident placements seamlessly, all without paying agent fees. Verified state-licensed listings ensures competition with only legitimate businesses, and the identity verification process makes sure only you can claim and access your listing."
            image={anchor1}
          />{" "}
          <AnchorCard
            heading="Direct Access to Families Seeking Care"
            para="Join our platform to reach families directly, eliminating intermediaries. Our flat fee structure ensures transparency and facilitates meaningful connections, enhancing your ability to provide top-notch care."
            image={anchor2}
            reverse
          />{" "}
          <AnchorCard
            heading="Grow Your Senior Care Business with Ease"
            para="Our platform simplifies client scheduling, communication, and listing management. Join today to elevate your business operations and connect with a community dedicated to excellence in senior care."
            image={anchor3}
          />
          <div className="flex items-center justify-center">
            <Link
              className="btn-sm block w-fit min-w-[300px] text-center bg-green text-white border-none"
              to="/register/provider"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnchorProvider;

import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export default function HomeAccordion({ data, title }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <div
        className="flex items-center justify-center gap-3 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <h6 className="text-base md:text-xl font-roboto-flex md:font-roboto-slab italic md:not-italic text-primaryBlack mb-0">
          {title}
        </h6>
        <FaChevronDown
          className={`${
            expanded ? "rotate-180" : ""
          } transition-transform duration-300`}
        />
      </div>

      <div
        className={`transition-all duration-300 overflow-hidden ${
          expanded ? "max-h-screen" : "max-h-0 "
        } `}
      >
        <ul className="p-3">
          {data.map((home, index) => (
            <li key={index}>
              <a href={home.href}>{home.text}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

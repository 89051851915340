import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import {useAuth} from './auth';
import { saveToLocalStorage, getFromLocalStorage } from "../helpers/utilities/LocalStorage.js"

export const TrackingProvider = ({ children }) => {
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();
    const [utmParams, setUTMParams] = useState(null);

    useEffect(() => {
        // Function to extract UTM parameters from the URL
        const extractUTMParameters = () => {
            const params = new URLSearchParams(window.location.search);
            const utmSource = params.get('utm_source');
            const utmMedium = params.get('utm_medium');
            const utmCampaign = params.get('utm_campaign');
            // Extract other UTM parameters as needed
            return { utmSource, utmMedium, utmCampaign };
        };
    
        // Extract UTM parameters and set them in the context
        const extractedUTMParams = extractUTMParameters();
        setUTMParams(extractedUTMParams);

        // Function to get a cookie
        const getCookie = (name) => {
            const cookieArray = document.cookie.split(';');
            for (let i = 0; i < cookieArray.length; i++) {
                const cookie = cookieArray[i].trim();
                if (cookie.startsWith(`${name}=`)) {
                    return cookie.substring(name.length + 1);
                }
            }
            return null;
        };
  
        // Usage
        const visitorId = getCookie('visitorId');
        const userId = auth?.user?._id ? auth?.user?._id : null;

        // Send UTM parameters to be stored in the database (replace with your actual logic)
        storeUTMInDatabase(extractedUTMParams, visitorId, userId);
        
        if (extractedUTMParams.utmCampaign !== null) {
            // reroute to standard url
            navigate(window.location.pathname);
        }
    }, [window.location.pathname]);
  
    const storeUTMInDatabase = async (utmParams, visitorId, userId) => {
        try {
            await axios.post('/analytics/user/track-session', {
                utmParams: utmParams,
                visitorId: visitorId,
                page: window.location.pathname,
                userId: userId,
            });
        } catch (error) {
            console.log(error);
        }
    };

  return <>{children}</>;
};

export const Referrer = ({ children }) => {
    const location = useLocation();  // This hook returns the location object that represents the current URL

    useEffect(() => {
        if (!getFromLocalStorage('sch-referrer')) {
            saveToLocalStorage('sch-referrer', {
                prevUrl: null,
                currentUrl: location.pathname,
            });
        } else {
            if(location.pathname !== getFromLocalStorage('sch-referrer')?.currentUrl) {
                saveToLocalStorage('sch-referrer', {
                    prevUrl: getFromLocalStorage('sch-referrer')?.currentUrl,
                    currentUrl: location.pathname,
                });
            };
        }
    }, [location.pathname]);  // This effect will now re-run every time the pathname changes

    return <>{children}</>;
};


// import {Link} from 'react-router-dom';
// import{useState} from 'react';
// import axios from "axios";
import moment from 'moment';
import { useAuth } from "../../context/auth";


export default function MessagerCardSingleThread({currentThread}) {
    // context
    const [auth, setAuth] = useAuth();

    const myId = auth.user._id;

    // console.log(currentThread);
    
    const date = moment(currentThread?.createdAt).format('DD MMM   |   hh:mm:ss');

    return (
        <>
        {
            (currentThread?.postedBy === myId) ? 
            (   
                <>
                    <div className='row justify-content-end'>
                        <div className="col-4 p-1 gx-4 gy-4">
                            <div className="card">
                                <div className="card-body">
                                        <div className='row'>{currentThread?.message}</div>
                                        <div className='row' style={{height: '15px'}}></div>
                                        <div className='row justify-content-end'>{date}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ):(
                <>
                    <div className='row'>
                        <div className="col-4 p-2 gx-4 gy-4">
                            <div className="card">
                                <div className="card-body">
                                        <p>{currentThread?.message}</p>
                                        <p>{date}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        </>
    );
}
export const validateConfirmPassword = ({ getFieldValue }) => ({
  validator(_, value) {
    const password = getFieldValue("password");
    if (value && value !== password) {
      return Promise.reject("Passwords do not match");
    }
    return Promise.resolve();
  },
});

export const validatePhoneNumber = (rule, value, callback) => {
  const cleanedNumber = ("" + value).replace(/\D/g, "");
  if (cleanedNumber.length !== 10) {
    callback("Please enter a valid 10-digit phone number");
  } else {
    callback();
  }
};

export const isValidPhoneNumber = (input) => {
  // Use a regular expression to check if the input is a valid phone number
  const phoneRegex = /^\d{10}$/; // Modify this regex based on your phone number validation requirements
  return phoneRegex.test(input);
};

export const isValidEmail = (input) => {
  // Use a regular expression to check if the input is a valid email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};
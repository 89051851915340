import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthLayout from "../../components/misc/authLayout";
import { FaArrowRight } from "react-icons/fa";

function Register() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const selectClient = () => {
    navigate("/register/client");
  };

  const selectProvider = () => {
    navigate("/register/provider");
  };

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
        <meta
          name="description"
          content="Choose what kind of account you want to sign up as."
        />
      </Helmet>
      <AuthLayout
        bgClass="bg-register-right"
        imageText={
          '"The platform connected us directly with local providers, allowing us to compare options and make the right decision. Highly recommend it to anyone seeking care for their loved ones!" - Chelsea (Port Orchard, WA)'
        }
        imageTextClass=" lgTablet:text-xl desktop:text-3xl text-center font-normal italic font-roboto-flex"
      >
        <div className="!px-5 lgTablet:!px-10 pb-10 desktop:pb-0 desktop:!px-20 space-y-14 lgTablet:space-y-16">
          <div className="space-y-2.5 lgTablet:space-y-10">
            <h1 className="text-lg lgTablet:text-2xl desktop:text-5xl font-bold desktop:font-medium text-primaryBlack ">
              Create an Account
            </h1>
            <p className="text-sm desktop:text-2xl font-roboto-flex text-grayy">
              Join SeniorCareHomes.Org and revolutionize the senior care search
              experience by connecting directly with quality care providers and
              a supportive community, without the salesperson.
            </p>
          </div>
          <div className=" flex flex-col desktop:flex-row justify-between  items-center !gap-5 lgTablet:!gap-[2.5rem] px-9 lgTablet:px-0">
            <div
              className=" w-full h-28 desktop:h-56 relative border-[3px] border-green rounded-2xl cursor-pointer flex flex-col items-center justify-center "
              onClick={selectClient}
            >
              <h3 className="text-center text-lg desktop:text-3xl font-normal text-primaryBlack">
                Client
              </h3>
              <p className="text-sm desktop:text-xl text-center font-normal text-primaryBlack  mb-0">
                I want to find and talk with Senior Care Home Providers
              </p>
            </div>

            <div
              className=" w-full h-28 desktop:h-56 relative border-[3px] border-green rounded-2xl cursor-pointer flex flex-col items-center justify-center"
              onClick={selectProvider}
            >
              <h3 className="text-lg desktop:text-3xl font-normal text-center text-primaryBlack">
                Provider
              </h3>
              <p className="text-sm desktop:text-xl text-primaryBlack max-w-xs font-normal text-center mb-0">
                I provide/manage a Senior Care Home
              </p>
            </div>
          </div>

          <div className="text-center flex-align-center justify-center">
            <p className="p-16px font-roboto-flex italic text-primaryBlack mb-0">
              Already have an account
            </p>
            <div className="ml-[12px] flex-align-center">
              <Link
                to="/login"
                className="no-underline hover:no-underline flex-align-center gap-2.5"
              >
                <span className="p-16px text-green italic">Login</span>
                <FaArrowRight className="text-green" />
              </Link>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default Register;

import React, { useEffect, useState } from "react";
import SocialRegister from "../../components/auth/SocialRegister";
import VerifyAccountForm from "../../components/forms/VerifyAccountMobileForm";
import { useParams, useNavigate } from "react-router-dom";
import Logo from "../../components/misc/Logo";
import AuthLayout from "../../components/misc/authLayout";

function VerifyAccountMobile() {
  // hooks
  const { token } = useParams();

  return (
    <AuthLayout
      bgClass="bg-register-right"
      imageText={
        '"The platform connected us directly with local providers, allowing us to compare options and make the right decision. Highly recommend it to anyone seeking care for their loved ones!" - Chelsea (Port Orchard, WA)'
      }
      imageTextClass="lgTablet:text-xl desktop:text-3xl text-center font-normal italic font-roboto-flex"
    >
      <div className="!px-5 lgTablet:!px-10 pb-24 desktop:pb-0 desktop:!px-20 space-y-5 lgTablet:space-y-10">
        <p className="m-0 heading-48px !font-medium text-primaryBlack">
          Verify Account
        </p>

        <div className="">
          <VerifyAccountForm token={token} />
        </div>
      </div>
    </AuthLayout>
  );
}

export default VerifyAccountMobile;

export const medicaidFilter = [
  {
    label: "Accepts Medicaid",
    value: "true",
  },
];

export const specialtyFilter = [
  {
    label: "Dementia",
    value: "Dementia",
  },
  {
    label: "Mental Health",
    value: "Mental Health",
  },
  {
    label: "Developmental Disability",
    value: "Developmental Disability",
  },
];

export const rangeFilter = [
  {
    label: "5 miles",
    value: "5 miles",
  },
  {
    label: "10 miles",
    value: "10 miles",
  },
  {
    label: "15 miles",
    value: "15 miles",
  },
  {
    label: "25 miles",
    value: "25 miles",
  },
];

export const budgetFilter = [
  {
    label: "$3,000 or less",
    value: "3000",
  },
  {
    label: "$4,000",
    value: "4000",
  },
  {
    label: "$5,000",
    value: "5000",
  },
  {
    label: "$6,000",
    value: "6000",
  },
  {
    label: "$7,000",
    value: "7000",
  },
  {
    label: "More than $7,000",
    value: "7000+",
  },
];
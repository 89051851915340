import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { getCookie } from "../../helpers/utilities/Cookie.js";
import { useAuth } from "../../context/auth.js";
import { careAssessment } from "../../helpers/careAssessment.jsx";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../helpers/utilities/LocalStorage.js";
import { Checkbox, Radio } from "antd";
import SearchFormCareAssessment from "./SearchFormCareAssessment.jsx";
import { useSearch } from "../../context/search.js";
import toast from "react-hot-toast";
import axios from "axios";

function CareAssessmentFormShort({ setCareAssessmentComplete, setModel }) {
  const navigate = useNavigate();

  const [auth, setAuth] = useAuth();
  const [search, setSearch] = useSearch();

  const [assessmentStep, setAssessmentStep] = useState(null);
  const [assessmentComplete, setAssessmentComplete] = useState(false);
  const [assessment, setAssessment] = useState({});
  const [loading, setLoading] = useState(false);

  const visitorId = getCookie("visitorId");
  const userId = auth?.user ? auth?.user?._id : null;
  const referrerUrl = getFromLocalStorage("sch-referrer")?.prevUrl;
  const currentUrl = getFromLocalStorage("sch-referrer")?.currentUrl;

  // fetch remote care assessment
  const getRemoteCareAssessment = async () => {
    try {
      setLoading(true);

      const { data } = await axios.post(
        `/assessments/create-update-assessment/findByVisitorUser`,
        {
          userId,
          visitorId,
          referrerUrl,
        }
      );

      if (data?.error) {
        setLoading(false);
        return null;
      } else {
        setLoading(false);
        return data?.data;
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong when trying to check for an existing care assessment. Please try to contact customer support for assistance."
      );
      setLoading(false);
    }
  };

  // update remote care assessment
  const updateRemoteCareAssessment = async (careAssessment) => {
    try {
      setLoading(true);

      const { data } = await axios.post(
        `/assessments/create-update-assessment/updateByVisitorUser`,
        {
          userId,
          visitorId,
          careAssessment,
        }
      );

      if (data?.error) {
        setLoading(false);
        return null;
      } else {
        setLoading(false);
        return data?.data;
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong when trying to update an existing care assessment. Please try to contact customer support for assistance."
      );
      setLoading(false);
    }
  };

  const processAssessmentSteps = (localCareAssessment) => {
    if (localCareAssessment?.context?.startStatus === true) {
      if (localCareAssessment?.context?.endStatus === false) {
        if (
          localCareAssessment?.context?.completedQuestions?.question6 === true
        ) {
          if (
            localCareAssessment?.context?.completedQuestions?.question8 === true
          ) {
            if (
              localCareAssessment?.context?.completedQuestions?.question9 ===
              true
            ) {
              if (
                localCareAssessment?.context?.completedQuestions?.question3 ===
                true
              ) {
                setAssessmentComplete(true);
              } else {
                setAssessmentStep(3);
              }
            } else {
              setAssessmentStep(9);
            }
          } else {
            setAssessmentStep(8);
          }
        } else {
          setAssessmentStep(6);
        }
      } else {
        setAssessmentComplete(true);
      }
    } else {
      setAssessmentStep(0);
    }
  };

  // pull assessment if exists from local storage or db on page load
  useEffect(() => {
    const fetchCareAssessment = async () => {
      // look locally first for an assessment, quicker load and if exists locally, certainly exists in db
      const localCareAssessment = getFromLocalStorage("care-assessment");
      if (localCareAssessment) {
        setAssessment(localCareAssessment);
        careAssessment.context = localCareAssessment.context;
        careAssessment.question3 = localCareAssessment.question3;
        careAssessment.question6 = localCareAssessment.question6;
        careAssessment.question8 = localCareAssessment.question8;
        careAssessment.question9 = localCareAssessment.question9;
        processAssessmentSteps(localCareAssessment);
        return;
      }

      try {
        // will always return an assessment, either existing or brand new
        const remoteCareAssessment = await getRemoteCareAssessment();
        setAssessment(remoteCareAssessment);
        saveToLocalStorage("care-assessment", remoteCareAssessment);
        careAssessment.context = remoteCareAssessment.context;
        careAssessment.question3 = remoteCareAssessment.question3;
        careAssessment.question6 = remoteCareAssessment.question6;
        careAssessment.question8 = remoteCareAssessment.question8;
        careAssessment.question9 = remoteCareAssessment.question9;
        processAssessmentSteps(remoteCareAssessment);
        return;
      } catch (error) {
        console.error("Error fetching remote assessment:", error);
      }
    };
    fetchCareAssessment();
  }, []);

  // check if careAssessment has fully completed steps, set assessment to complete
  const checkCompletion = async () => {
    if (
      careAssessment.context.completedQuestions.question3 === true &&
      careAssessment.context.completedQuestions.question6 === true &&
      careAssessment.context.completedQuestions.question8 === true &&
      careAssessment.context.completedQuestions.question9 === true
    ) {
      setLoading(true);
      careAssessment.context.endStatus = true;
      careAssessment.context.endDatetime = new Date().toISOString();

      const updated = await updateRemoteCareAssessment(careAssessment);
      if (updated !== null) {
        setAssessment(updated);
        saveToLocalStorage("care-assessment", updated);
        saveToLocalStorage("care-assessment-complete", true);
        setCareAssessmentComplete(true);
      }

      setAssessmentComplete(true);
      setLoading(false);
    }
  };

  const getStarted = async () => {
    careAssessment.context.startStatus = true;
    careAssessment.context.startDatetime = new Date().toISOString();
    if (userId) {
      careAssessment.context.userId = userId;
    }
    setAssessmentStep(6);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage("care-assessment", updated);
      checkCompletion();
    }
  };

  const handleSearch = async (value) => {
    try {
      const { address, results, page, ...rest } = search;

      if (value === "") {
        toast.error("Please enter a location");
      } else {
        const response = await axios.post(`/geocode/decode-address`, {
          address: value,
        });

        careAssessment.context.completedQuestions.question3 = true;
        careAssessment.question3.answer.city = response?.data?.data?.city;
        careAssessment.question3.answer.county = response?.data?.data?.county;
        careAssessment.question3.answer.lat = response?.data?.data?.lat;
        careAssessment.question3.answer.lng = response?.data?.data?.lng;
        careAssessment.question3.answer.state = response?.data?.data?.state;

        const updated = await updateRemoteCareAssessment(careAssessment);
        if (updated !== null) {
          setAssessment(updated);
          saveToLocalStorage("care-assessment", updated);
          checkCompletion();
        }
      }
    } catch (error) {
      console.log(error);
      setSearch({ ...search, loading: false });
    }
  };

  const handleSubmitQ6 = async () => {
    careAssessment.context.completedQuestions.question6 = true;

    if (assessment.question6.answer["Wandering"] === true) {
      careAssessment.question6.answer.wander = true;
    }
    if (assessment.question6.answer["Aggressiveness"] === true) {
      careAssessment.question6.answer.aggressive = true;
    }
    if (assessment.question6.answer["Hallucinations"] === true) {
      careAssessment.question6.answer.hallucination = true;
    }
    if (assessment.question6.answer["Withdrawal"] === true) {
      careAssessment.question6.answer.withdrawl = true;
    }
    if (assessment.question6.answer["Sundowning"] === true) {
      careAssessment.question6.answer.sundown = true;
    }
    if (assessment.question6.answer["Judgment Loss"] === true) {
      careAssessment.question6.answer.judgement = true;
    }
    if (assessment.question6.answer["Memory Loss"] === true) {
      careAssessment.question6.answer.memory = true;
    }
    if (assessment.question6.answer["Needs 24-hr Care"] === true) {
      careAssessment.question6.answer.care24 = true;
    }
    if (assessment.question6.answer["Exit-seeking"] === true) {
      careAssessment.question6.answer.exitSeek = true;
    }
    if (assessment.question6.answer["None/I don’t know"] === true) {
      careAssessment.question6.answer.idk = true;
    }

    setAssessmentStep(8);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage("care-assessment", updated);
      checkCompletion();
    }
  };

  const handleCheckboxChangeQ6 = (selectedValues) => {
    // Create a new object with all options set to false
    const updatedAnswers = assessment.question6.options.reduce(
      (acc, option) => {
        acc[option] = selectedValues.includes(option);
        return acc;
      },
      {}
    );

    // Update the assessment state
    setAssessment((prevAssessment) => ({
      ...prevAssessment,
      question6: {
        ...prevAssessment.question6,
        answer: updatedAnswers,
      },
    }));
  };

  const handleSubmitQ8 = async (e) => {
    if (e.target.value === "Less than $2,000") {
      careAssessment.question8.answerBoolean.L1 = true;
      careAssessment.question8.answerRange.rangeLow = 0;
      careAssessment.question8.answerRange.rangeHigh = 1999;
    }
    if (e.target.value === "$2,000 - $2,499") {
      careAssessment.question8.answerBoolean.L2 = true;
      careAssessment.question8.answerRange.rangeLow = 2000;
      careAssessment.question8.answerRange.rangeHigh = 2499;
    }
    if (e.target.value === "$2,500 - $2,999") {
      careAssessment.question8.answerBoolean.L3 = true;
      careAssessment.question8.answerRange.rangeLow = 2500;
      careAssessment.question8.answerRange.rangeHigh = 2999;
    }
    if (e.target.value === "$3,000 - $3,999") {
      careAssessment.question8.answerBoolean.L4 = true;
      careAssessment.question8.answerRange.rangeLow = 3000;
      careAssessment.question8.answerRange.rangeHigh = 3999;
    }
    if (e.target.value === "$4,000 - $4,999") {
      careAssessment.question8.answerBoolean.L5 = true;
      careAssessment.question8.answerRange.rangeLow = 4000;
      careAssessment.question8.answerRange.rangeHigh = 4999;
    }
    if (e.target.value === "$5,000 - $5,999") {
      careAssessment.question8.answerBoolean.L6 = true;
      careAssessment.question8.answerRange.rangeLow = 5000;
      careAssessment.question8.answerRange.rangeHigh = 5999;
    }
    if (e.target.value === "$6,000 - $6,999") {
      careAssessment.question8.answerBoolean.L7 = true;
      careAssessment.question8.answerRange.rangeLow = 6000;
      careAssessment.question8.answerRange.rangeHigh = 6999;
    }
    if (e.target.value === "Over $7,000") {
      careAssessment.question8.answerBoolean.L8 = true;
      careAssessment.question8.answerRange.rangeLow = 7000;
      careAssessment.question8.answerRange.rangeHigh = 20000;
    }

    careAssessment.context.completedQuestions.question8 = true;
    setAssessmentStep(9);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage("care-assessment", updated);
      checkCompletion();
    }
  };

  const handleSubmitQ9 = async () => {
    careAssessment.context.completedQuestions.question9 = true;

    if (assessment.question9.answer["Medicaid"] === true) {
      careAssessment.question9.answer.medicaid = true;
    }
    if (assessment.question9.answer["Personal Savings"] === true) {
      careAssessment.question9.answer.personalSavings = true;
    }
    if (assessment.question9.answer["Family Support"] === true) {
      careAssessment.question9.answer.familySupport = true;
    }
    if (assessment.question9.answer["Stocks, Bonds, CDs or IRAs"] === true) {
      careAssessment.question9.answer.portfolio = true;
    }
    if (assessment.question9.answer["Long-term Care Insurance"] === true) {
      careAssessment.question9.answer.ltc = true;
    }
    if (assessment.question9.answer["401Ks"] === true) {
      careAssessment.question9.answer.k401 = true;
    }
    if (assessment.question9.answer["Monthly Income"] === true) {
      careAssessment.question9.answer.income = true;
    }
    if (assessment.question9.answer["Home to Sell"] === true) {
      careAssessment.question9.answer.sellHome = true;
    }
    if (assessment.question9.answer["VA Benefits"] === true) {
      careAssessment.question9.answer.vaBenefits = true;
    }
    if (assessment.question9.answer["Other Assets"] === true) {
      careAssessment.question9.answer.other = true;
    }

    setAssessmentStep(3);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage("care-assessment", updated);
      checkCompletion();
    }
  };

  const handleCheckboxChangeQ9 = (selectedValues) => {
    // Create a new object with all options set to false
    const updatedAnswers = assessment.question9.options.reduce(
      (acc, option) => {
        acc[option] = selectedValues.includes(option);
        return acc;
      },
      {}
    );

    // Update the assessment state
    setAssessment((prevAssessment) => ({
      ...prevAssessment,
      question9: {
        ...prevAssessment.question9,
        answer: updatedAnswers,
      },
    }));
  };

  const viewRecommendations = () => {
    console.log('view recommendations function started');
    const localCareAssessment = getFromLocalStorage("care-assessment");
    console.log(localCareAssessment);
    setModel(0);

    navigate(localCareAssessment?.context?.recommendationUrl);
    console.log('view recommendations function started');
  };

  return (
    <>
      {!assessmentComplete && assessmentStep === null && (
        <div className="mt-[2rem] mb-[2rem] d-flex justify-content-center">Loading..</div>
      )}

      {!assessmentComplete && assessmentStep === 0 && (
        <div className="mt-[3rem] px-4 flex flex-col items-center">
          <h1 className="heading-42px text-primaryBlack text-center">
            Get Personalized Recommendations
          </h1>
          <p className="p-20px mt-3 text-grayy text-center">
            This 30 second free assessment helps narrow your search results and is
            also shared with Providers you contact in order to quickly determine
            if they can support your needs.
          </p>
          <div
            className="form-btn mt-5 mb-5 px-4 py-2 bg-blue-500 text-white rounded cursor-pointer text-center w-1/3 max-w-[66%]"
            onClick={getStarted}
          >
            Get Started
          </div>
        </div>
      )}

      {!assessmentComplete && assessmentStep === 3 && (
        <div className="mt-[2rem] mb-[2rem]">
          <ProgressBar
            completed={80}
            labelSize="13px"
            bgColor="#216017"
          />
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3 mb-3">
            Preferred Location?
          </h1>
          <p className="p-20px mb-5 text-grayy d-flex justify-content-center">
            Please enter the primary location to your search even if you are open to a few different areas.
          </p>
          <SearchFormCareAssessment
            handleSearch={handleSearch}
            search={search}
            setSearch={setSearch}
          />
        </div>
      )}

      {!assessmentComplete && assessmentStep === 6 && (
        <div className="mt-[2rem] mb-[2rem]">
          <ProgressBar
            completed={20}
            labelSize="13px"
            bgColor="#216017"
          />
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3">
            Any severe care needs?
          </h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
            Select all that apply. We're assuming you need help with ADL(Activities of Daily Living) at a minimum.
          </p>
          <div className="w-full ml-4">
            <Checkbox.Group
              className="dropdwn checkbox"
              style={{ display: "flex", flexDirection: "column" }}
              onChange={handleCheckboxChangeQ6}
              value={Object.keys(assessment.question6.answer).filter(
                (key) => assessment.question6.answer[key]
              )}
            >
              {assessment.question6.options.map((option) => (
                <Checkbox key={option} value={option}>
                  {option}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="form-btn mt-4 px-4 py-2 bg-blue-500 text-white rounded cursor-pointer text-center"
              onClick={handleSubmitQ6}
            >
              Next
            </div>
          </div>
        </div>
      )}

      {!assessmentComplete && assessmentStep === 8 && (
        <div className="mt-[2rem] mb-[2rem]">
          <ProgressBar
            completed={40}
            labelSize="13px"
            bgColor="#216017"
          />
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-4 mb-4">
            Total Monthly Budget?
          </h1>
          <div className="w-full ml-4">
            <Radio.Group
              className="dropdwn checkbox"
              style={{ display: "flex", flexDirection: "column" }}
              onChange={handleSubmitQ8}
              value={Object.keys(assessment.question8.answerBoolean).find(
                (key) => assessment.question8.answerBoolean[key]
              )}
            >
              {assessment.question8.options.map((option) => (
                <Radio key={option} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      )}

      {!assessmentComplete && assessmentStep === 9 && (
        <div className="mt-[2rem] mb-[2rem]">
          <ProgressBar
            completed={60}
            labelSize="13px"
            bgColor="#216017"
          />
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3">
            How do you plan to pay for care?
          </h1>
          <p className="p-20px mt-1 text-grayy d-flex justify-content-center">
            Select all that apply.
          </p>
          <div className="w-full ml-4">
            <Checkbox.Group
              className="dropdwn checkbox"
              style={{ display: "flex", flexDirection: "column" }}
              onChange={handleCheckboxChangeQ9}
              value={Object.keys(assessment.question9.answer).filter(
                (key) => assessment.question9.answer[key]
              )}
            >
              {assessment.question9.options.map((option) => (
                <Checkbox key={option} value={option}>
                  {option}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="form-btn mt-5 px-4 py-2 bg-blue-500 text-white rounded cursor-pointer text-center"
              onClick={handleSubmitQ9}
            >
              Next
            </div>
          </div>
        </div>
      )}

      {assessmentComplete && (
        <div className="mt-[2rem] mb-[2rem]">
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3">
            Thank you for completing your care assessment!
          </h1>
          <div className="d-flex justify-content-center">
            <div
              className="form-btn mt-5 px-4 py-2 bg-blue-500 text-white rounded cursor-pointer text-center"
              onClick={viewRecommendations}
            >
              View My Recommendations!
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CareAssessmentFormShort;

import {useState, createContext, useContext} from 'react';

const SearchContext = createContext();

const initialState = {
    address: '',
    range: [],
    specialty: [],
    medicaid: [],
    results: [],
    page: '',
    loading: false,
};

const SearchProvider = ({children}) => {
    const [search, setSearch] = useState(initialState);

    return(
        <SearchContext.Provider value={[search, setSearch, initialState]}>
            {children}
        </SearchContext.Provider>
    );
};

const useSearch = () => useContext(SearchContext);

export {useSearch, SearchProvider};
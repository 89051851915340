import { IoBedOutline, IoShieldCheckmark, IoShieldOutline } from 'react-icons/io5';
import { BiArea } from 'react-icons/bi';
import {Avatar} from 'antd';


export default function AdFeatures ({ad}) {
    return (
        <>
            <div>
                <h4 className='mt-3'>Operator Info</h4>
                <p className='card-text d-flex justify-content-between'>
                    Profile Pic
                </p>
                <p className='card-text d-flex justify-content-between'>
                    {ad?.ownerFirstName} {ad?.ownerLastName}
                </p>
            </div>

            <div>
                <h4 className='mt-3'>Facility Info</h4>
                <p className='card-text d-flex justify-content-between'>
                    {ad?.title}
                </p>
                <p className='card-text d-flex justify-content-between'>
                    {ad?.facilityType}
                </p>
                <p className='card-text d-flex justify-content-between'>
                    {ad?.address}
                </p>
                <p>
                    {ad?.subscriptionType ? (<span><IoShieldCheckmark /> Verified Provider</span>) : (<span><IoShieldOutline /> Unverified Provider</span>)}
                </p>
                <p className='card-text d-flex justify-content-between'>
                    {ad?.licensedBeds ? (<span><IoBedOutline /> {ad?.licensedBeds} Licensed Beds</span>) : ''}
                </p>

                {ad?.medicaidAccepted === true ? (
                    <>
                        <span>⚕️ Accepts Medicaid for Following Services:</span>
                        {ad?.medicaidContract?.expandedCommunityServices && <p className='ml-4'>✅ Expanded Community Services</p>}
                        {ad?.medicaidContract?.adultFamilyHome && <p className='ml-4'>✅ Adult Family Home</p>}
                        {ad?.medicaidContract?.meaningfulDay && <p className='ml-4'>✅ Meaningful Day</p>}
                        {ad?.medicaidContract?.specializedBehaviorSupport && <p className='ml-4'>✅ Specialized Behavior Support</p>}
                        {ad?.medicaidContract?.hcsMeaningfulDay && <p className='ml-4'>✅ HCS Meaningful Day</p>}
                        {ad?.medicaidContract?.privateDutyNursing && <p className='ml-4'>✅ Private Duty Nursing</p>}
                        {ad?.medicaidContract?.ddaMeaningfulDay && <p className='ml-4'>✅ DDA Meaningful Day</p>}
                    </>
                    
                ) : (
                    <p>⚕️ Does Not Accept Medicaid</p>
                )}
                
                {ad?.specialty?.none === false ? (
                    <>
                        <span>Specialty Services Offered:</span>
                        {ad?.specialty?.mentalHealth && <p className='ml-4'>✅ Mental Health</p>}
                        {ad?.specialty?.dementia && <p className='ml-4'>✅ Dementia</p>}
                        {ad?.specialty?.developmentalDisabilities && <p className='ml-4'>✅ Developmental Disabilities</p>}
                    </>
                ) : (
                    <p>No Speciality Services Offered</p>
                )}
            </div>

            <div>
                <h4 className='mt-3'>License Info</h4>
                <p className='card-text d-flex justify-content-between'>
                    State License Number: {ad?.licenseNumber}
                </p>
                <p>
                    State Filing Link: <a href={ad?.licenseLink} target="_blank" rel="noopener noreferrer">View</a>
                </p>
            </div>

            
        </>
    )
}
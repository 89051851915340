import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ProgressBar from "@ramonak/react-progress-bar";
import { getCookie } from "../../helpers/utilities/Cookie";
import { useAuth } from "../../context/auth";
import { careAssessment } from "../../helpers/careAssessment";
import { saveToLocalStorage, getFromLocalStorage } from "../../helpers/utilities/LocalStorage.js";
import { Checkbox, Radio } from "antd";
import SearchFormCareAssessment from "../forms/SearchFormCareAssessment.jsx";
import { useSearch } from "../../context/search";
import toast from "react-hot-toast";
import axios from "axios";

function CareAssessmentForm_Long() {
  const navigate = useNavigate();

  const [auth, setAuth] = useAuth();
  const [search, setSearch] = useSearch();

  const [assessmentStep, setAssessmentStep] = useState(null);
  const [assessmentComplete, setAssessmentComplete] = useState(false);
  const [assessment, setAssessment] = useState({});
  const [loading, setLoading] = useState(false);

  const visitorId = getCookie('visitorId');
  const userId = auth?.user ? auth?.user?._id : null;
  const referrerUrl = getFromLocalStorage('sch-referrer')?.prevUrl;
  const currentUrl = getFromLocalStorage('sch-referrer')?.currentUrl;

  // fetch remote care assessment
  const getRemoteCareAssessment = async () => {
    try {
      setLoading(true);
      
      const { data } = await axios.post(`/assessments/create-update-assessment/findByVisitorUser`, {
        userId,
        visitorId,
        referrerUrl,
      });

      if (data?.error) {
        setLoading(false);
        return null;
      } else {
        setLoading(false);
        return data?.data;
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong when trying to check for an existing care assessment. Please try to contact customer support for assistance.");
      setLoading(false);
    }
  };

  // update remote care assessment
  const updateRemoteCareAssessment = async (careAssessment) => {
    try {
      setLoading(true);
      
      const { data } = await axios.post(`/assessments/create-update-assessment/updateByVisitorUser`, {
        userId,
        visitorId,
        careAssessment,
      });

      if (data?.error) {
        setLoading(false);
        return null;
      } else {
        setLoading(false);
        return data?.data;
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong when trying to update an existing care assessment. Please try to contact customer support for assistance.");
      setLoading(false);
    }
  };

  const processAssessmentSteps = (localCareAssessment) => {
    if (localCareAssessment?.context?.startStatus === true) {
      if (localCareAssessment?.context?.endStatus === false) {
        if (localCareAssessment?.context?.completedQuestions?.question1 === true) {
          if (localCareAssessment?.context?.completedQuestions?.question2 === true) {
            if (localCareAssessment?.context?.completedQuestions?.question3 === true) {
              if (localCareAssessment?.context?.completedQuestions?.question4 === true) {
                if (localCareAssessment?.context?.completedQuestions?.question5 === true) {
                  if (localCareAssessment?.context?.completedQuestions?.question6 === true) {
                    if (localCareAssessment?.context?.completedQuestions?.question7 === true) {
                      if (localCareAssessment?.context?.completedQuestions?.question8 === true) {
                        if (localCareAssessment?.context?.completedQuestions?.question9 === true) {
                          setAssessmentComplete(true);
                        } else {
                          setAssessmentStep(9);
                        };
                      } else {
                        setAssessmentStep(8);
                      };
                    } else {
                      setAssessmentStep(7);
                    };
                  } else {
                    setAssessmentStep(6);
                  };
                } else {
                  setAssessmentStep(5);
                };
              } else {
                setAssessmentStep(4);
              };
            } else {
              setAssessmentStep(3);
            };
          } else {
            setAssessmentStep(2);
          };
        } else {
          setAssessmentStep(1);
        };
      } else {
        setAssessmentComplete(true);
      };

    } else {
      setAssessmentStep(0);
    };
  };

  // pull assessment if exists from local storage or db on page load
  useEffect(() => {
    const fetchCareAssessment = async () => {
      // look locally first for an assessment, quicker load and if exists locally, certainly exists in db
      const localCareAssessment = getFromLocalStorage('care-assessment');
      if (localCareAssessment) {
        setAssessment(localCareAssessment);
        careAssessment.context = localCareAssessment.context;
        careAssessment.question1 = localCareAssessment.question1;
        careAssessment.question2 = localCareAssessment.question2;
        careAssessment.question3 = localCareAssessment.question3;
        careAssessment.question4 = localCareAssessment.question4;
        careAssessment.question5 = localCareAssessment.question5;
        careAssessment.question6 = localCareAssessment.question6;
        careAssessment.question7 = localCareAssessment.question7;
        careAssessment.question8 = localCareAssessment.question8;
        careAssessment.question9 = localCareAssessment.question9;
        processAssessmentSteps(localCareAssessment);
        return;
      };

      try {
        // will always return an assessment, either existing or brand new
        const remoteCareAssessment = await getRemoteCareAssessment();
        setAssessment(remoteCareAssessment);
        saveToLocalStorage('care-assessment', remoteCareAssessment);
        careAssessment.context = remoteCareAssessment.context;
        careAssessment.question1 = remoteCareAssessment.question1;
        careAssessment.question2 = remoteCareAssessment.question2;
        careAssessment.question3 = remoteCareAssessment.question3;
        careAssessment.question4 = remoteCareAssessment.question4;
        careAssessment.question5 = remoteCareAssessment.question5;
        careAssessment.question6 = remoteCareAssessment.question6;
        careAssessment.question7 = remoteCareAssessment.question7;
        careAssessment.question8 = remoteCareAssessment.question8;
        careAssessment.question9 = remoteCareAssessment.question9;
        processAssessmentSteps(remoteCareAssessment);
        return;
      } catch (error) {
        console.error("Error fetching remote assessment:", error);
      };
    };
    fetchCareAssessment();
  }, []);

  // check if careAssessment has fully completed steps, set assessment to complete
  const checkCompletion = async () => {
    if(
      careAssessment.context.completedQuestions.question1 === true &&
      careAssessment.context.completedQuestions.question2 === true &&
      careAssessment.context.completedQuestions.question3 === true &&
      careAssessment.context.completedQuestions.question4 === true &&
      careAssessment.context.completedQuestions.question5 === true &&
      careAssessment.context.completedQuestions.question6 === true &&
      careAssessment.context.completedQuestions.question7 === true &&
      careAssessment.context.completedQuestions.question8 === true &&
      careAssessment.context.completedQuestions.question9 === true
    ) {
      setLoading(true);
      careAssessment.context.endStatus = true;
      careAssessment.context.endDatetime = new Date().toISOString();

      const updated = await updateRemoteCareAssessment(careAssessment);
      if (updated !== null) {
        setAssessment(updated);
        saveToLocalStorage('care-assessment', updated);
        saveToLocalStorage("care-assessment-complete", true);
      };
      
      setAssessmentComplete(true);
      setLoading(false);
    };
  };

  const getStarted = async () => {
    careAssessment.context.startStatus = true;
    careAssessment.context.startDatetime = new Date().toISOString();
    if(userId){
      careAssessment.context.userId = userId;
    };
    setAssessmentStep(1);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleRadioChangeQ1 = async (e) => {
    if (e.target.value === 'Parent') {
      careAssessment.question1.answer.parent = true;
    };
    if (e.target.value === 'Spouse') {
      careAssessment.question1.answer.spouse = true;
    };
    if (e.target.value === 'Myself') {
      careAssessment.question1.answer.myself = true;
    };
    if (e.target.value === 'Other') {
      careAssessment.question1.answer.other = true;
    };
    careAssessment.context.completedQuestions.question1 = true;
    setAssessmentStep(2);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleRadioChangeQ2 = async (e) => {
    if (e.target.value === 'Immediately') {
      careAssessment.question2.answer.immediately = true;
    };
    if (e.target.value === 'Within 30 days') {
      careAssessment.question2.answer.thirtyDays = true;
    };
    if (e.target.value === 'Within 90 days') {
      careAssessment.question2.answer.ninetyDays = true;
    };
    if (e.target.value === 'No rush') {
      careAssessment.question2.answer.noRush = true;
    };
    careAssessment.context.completedQuestions.question2 = true;
    setAssessmentStep(3);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleSearch = async (value) => {
    try {
      const { address, results, page, ...rest } = search;

      if (value === "") {
        toast.error("Please enter a location");
      } else {
        const response = await axios.post(`/geocode/decode-address`, { address: value });

        careAssessment.context.completedQuestions.question3 = true;
        careAssessment.question3.answer.city = response?.data?.data?.city;
        careAssessment.question3.answer.county = response?.data?.data?.county;
        careAssessment.question3.answer.lat = response?.data?.data?.lat;
        careAssessment.question3.answer.lng = response?.data?.data?.lng;
        careAssessment.question3.answer.state = response?.data?.data?.state;
        
        setAssessmentStep(4);
        const updated = await updateRemoteCareAssessment(careAssessment);
        if (updated !== null) {
          setAssessment(updated);
          saveToLocalStorage('care-assessment', updated);
          checkCompletion();
        };
      }
    } catch (error) {
      console.log(error);
      setSearch({ ...search, loading: false });
    }
  };

  const handleSubmitQ4 = async (e) => {
    if (e.target.value === "Good (can get around independently)") {
      careAssessment.question4.answer.independent = true;
    };
    if (e.target.value === "Can walk with help (cane/walker)") {
      careAssessment.question4.answer.help = true;
    };
    if (e.target.value === "Wheelchair") {
      careAssessment.question4.answer.wheelchair = true;
    };
    if (e.target.value === "Immobile (temporary/permanent)") {
      careAssessment.question4.answer.immobile = true;
    };
    careAssessment.context.completedQuestions.question4 = true;
    setAssessmentStep(5);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleSubmitQ5 = async () => {
    careAssessment.context.completedQuestions.question5 = true;

    if(assessment.question5.answer["Bathing"] === true) {
      careAssessment.question5.answer.bathing = true;
    };
    if(assessment.question5.answer["Diabetic Care"] === true) {
      careAssessment.question5.answer.diabetic = true;
    };
    if(assessment.question5.answer["Housekeeping"] === true) {
      careAssessment.question5.answer.housekeeping = true;
    };
    if(assessment.question5.answer["Medication"] === true) {
      careAssessment.question5.answer.medication = true;
    };
    if(assessment.question5.answer["None/I don’t know"] === true) {
      careAssessment.question5.answer.idk = true;
    };
    if(assessment.question5.answer["Social Activities"] === true) {
      careAssessment.question5.answer.social = true;
    };
    if(assessment.question5.answer["Special Diet"] === true) {
      careAssessment.question5.answer.diet = true;
    };
    if(assessment.question5.answer["Toileting"] === true) {
      careAssessment.question5.answer.toileting = true;
    };
    
    setAssessmentStep(6);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleCheckboxChangeQ5 = (selectedValues) => {
    // Create a new object with all options set to false
    const updatedAnswers = assessment.question5.options.reduce((acc, option) => {
        acc[option] = selectedValues.includes(option);
        return acc;
    }, {});

    // Update the assessment state
    setAssessment((prevAssessment) => ({
        ...prevAssessment,
        question5: {
            ...prevAssessment.question5,
            answer: updatedAnswers
        }
    }));
  };

  const handleSubmitQ6 = async () => {
    careAssessment.context.completedQuestions.question6 = true;
    
    if(assessment.question6.answer["Wandering"] === true) {
      careAssessment.question6.answer.wander = true;
    };
    if(assessment.question6.answer["Aggressiveness"] === true) {
      careAssessment.question6.answer.aggressive = true;
    };
    if(assessment.question6.answer["Hallucinations"] === true) {
      careAssessment.question6.answer.hallucination = true;
    };
    if(assessment.question6.answer["Withdrawal"] === true) {
      careAssessment.question6.answer.withdrawl = true;
    };
    if(assessment.question6.answer["Sundowning"] === true) {
      careAssessment.question6.answer.sundown = true;
    };
    if(assessment.question6.answer["Judgment Loss"] === true) {
      careAssessment.question6.answer.judgement = true;
    };
    if(assessment.question6.answer["Memory Loss"] === true) {
      careAssessment.question6.answer.memory = true;
    };
    if(assessment.question6.answer["Needs 24-hr Care"] === true) {
      careAssessment.question6.answer.care24 = true;
    };
    if(assessment.question6.answer["Exit-seeking"] === true) {
      careAssessment.question6.answer.exitSeek = true;
    };
    if(assessment.question6.answer["None/I don’t know"] === true) {
      careAssessment.question6.answer.idk = true;
    };
    
    setAssessmentStep(7);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleCheckboxChangeQ6 = (selectedValues) => {
    // Create a new object with all options set to false
    const updatedAnswers = assessment.question6.options.reduce((acc, option) => {
      acc[option] = selectedValues.includes(option);
      return acc;
    }, {});

    // Update the assessment state
    setAssessment((prevAssessment) => ({
        ...prevAssessment,
        question6: {
            ...prevAssessment.question6,
            answer: updatedAnswers
        }
    }));
  };

  const handleSubmitQ7 = async () => {
    careAssessment.context.completedQuestions.question7 = true;

    if(assessment.question7.answer["Private Room, Private Bath, Private Shower"] === true) {
      careAssessment.question7.answer.pR_pB_pS = true;
    };
    if(assessment.question7.answer["Private Room, Private Bath, Shared Shower"] === true) {
      careAssessment.question7.answer.pR_pB_sS = true;
    };
    if(assessment.question7.answer["Private Room, Shared Bath, Shared Shower"] === true) {
      careAssessment.question7.answer.pR_sB_sS = true;
    };
    if(assessment.question7.answer["Shared Room, Shared Bath, Shared Shower"] === true) {
      careAssessment.question7.answer.sR_sB_sS = true;
    };
    
    setAssessmentStep(8);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleCheckboxChangeQ7 = (selectedValues) => {
    // Create a new object with all options set to false
    const updatedAnswers = assessment.question7.options.reduce((acc, option) => {
      acc[option] = selectedValues.includes(option);
      return acc;
    }, {});

    // Update the assessment state
    setAssessment((prevAssessment) => ({
        ...prevAssessment,
        question7: {
            ...prevAssessment.question7,
            answer: updatedAnswers
        }
    }));
  };

  const handleSubmitQ8 = async (e) => {
    if (e.target.value === "Less than $2,000") {
      careAssessment.question8.answerBoolean.L1 = true;
      careAssessment.question8.answerRange.rangeLow = 0;
      careAssessment.question8.answerRange.rangeHigh = 1999;
    };
    if (e.target.value === "$2,000 - $2,499") {
      careAssessment.question8.answerBoolean.L2 = true;
      careAssessment.question8.answerRange.rangeLow = 2000;
      careAssessment.question8.answerRange.rangeHigh = 2499;
    };
    if (e.target.value === "$2,500 - $2,999") {
      careAssessment.question8.answerBoolean.L3 = true;
      careAssessment.question8.answerRange.rangeLow = 2500;
      careAssessment.question8.answerRange.rangeHigh = 2999;
    };
    if (e.target.value === "$3,000 - $3,999") {
      careAssessment.question8.answerBoolean.L4 = true;
      careAssessment.question8.answerRange.rangeLow = 3000;
      careAssessment.question8.answerRange.rangeHigh = 3999;
    };
    if (e.target.value === "$4,000 - $4,999") {
      careAssessment.question8.answerBoolean.L5 = true;
      careAssessment.question8.answerRange.rangeLow = 4000;
      careAssessment.question8.answerRange.rangeHigh = 4999;
    };
    if (e.target.value === "$5,000 - $5,999") {
      careAssessment.question8.answerBoolean.L6 = true;
      careAssessment.question8.answerRange.rangeLow = 5000;
      careAssessment.question8.answerRange.rangeHigh = 5999;
    };
    if (e.target.value === "$6,000 - $6,999") {
      careAssessment.question8.answerBoolean.L7 = true;
      careAssessment.question8.answerRange.rangeLow = 6000;
      careAssessment.question8.answerRange.rangeHigh = 6999;
    };
    if (e.target.value === "Over $7,000") {
      careAssessment.question8.answerBoolean.L8 = true;
      careAssessment.question8.answerRange.rangeLow = 7000;
      careAssessment.question8.answerRange.rangeHigh = 20000;
    };

    careAssessment.context.completedQuestions.question8 = true;
    setAssessmentStep(9);
    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleSubmitQ9 = async () => {
    careAssessment.context.completedQuestions.question9 = true;

    if(assessment.question9.answer["Medicaid"] === true) {
      careAssessment.question9.answer.medicaid = true;
    };
    if(assessment.question9.answer["Personal Savings"] === true) {
      careAssessment.question9.answer.personalSavings = true;
    };
    if(assessment.question9.answer["Family Support"] === true) {
      careAssessment.question9.answer.familySupport = true;
    };
    if(assessment.question9.answer["Stocks, Bonds, CDs or IRAs"] === true) {
      careAssessment.question9.answer.portfolio = true;
    };
    if(assessment.question9.answer["Long-term Care Insurance"] === true) {
      careAssessment.question9.answer.ltc = true;
    };
    if(assessment.question9.answer["401Ks"] === true) {
      careAssessment.question9.answer.k401 = true;
    };
    if(assessment.question9.answer["Monthly Income"] === true) {
      careAssessment.question9.answer.income = true;
    };
    if(assessment.question9.answer["Home to Sell"] === true) {
      careAssessment.question9.answer.sellHome = true;
    };
    if(assessment.question9.answer["VA Benefits"] === true) {
      careAssessment.question9.answer.vaBenefits = true;
    };
    if(assessment.question9.answer["Other Assets"] === true) {
      careAssessment.question9.answer.other = true;
    };

    const updated = await updateRemoteCareAssessment(careAssessment);
    if (updated !== null) {
      setAssessment(updated);
      saveToLocalStorage('care-assessment', updated);
      checkCompletion();
    };
  };

  const handleCheckboxChangeQ9 = (selectedValues) => {
    // Create a new object with all options set to false
    const updatedAnswers = assessment.question9.options.reduce((acc, option) => {
      acc[option] = selectedValues.includes(option);
      return acc;
    }, {});

    // Update the assessment state
    setAssessment((prevAssessment) => ({
        ...prevAssessment,
        question9: {
            ...prevAssessment.question9,
            answer: updatedAnswers
        }
    }));
  };

  const viewRecommendations = () => {
    const localCareAssessment = getFromLocalStorage('care-assessment');
    navigate(localCareAssessment.context.recommendationUrl);
  };

  const [count, setCount] = useState(3);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count === 1) {
        viewRecommendations();
      } else {
        setCount(count - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [count]);

  return (
    <>
      {!assessmentComplete && assessmentStep === null && 
      <div className="mt-[3rem]">
          Loading..
      </div>
      }
      
      {!assessmentComplete && assessmentStep === 0 && 
      <div>
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center">Take this quick assessment to get personalized results</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          This 3-5 minute assessment helps narrow your search results and is also shared with Providers you contact in order to quickly determine if they can support your needs.
          </p>
          <div className="d-flex justify-content-center">
            <button className="form-btn mt-5" type="submit" onClick={getStarted}>
            Get Started
            </button>
          </div>
      </div>
      }

      {!assessmentComplete && assessmentStep === 1 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={10} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={0} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3">{assessment?.question1?.question}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question1?.hint}
          </p>
          <div className="mt-5 ml-3">
            <Radio.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleRadioChangeQ1}
            value={Object.keys(assessment.question1.answer).find(key => assessment.question1.answer[key])}
            >
              {assessment.question1.options.map(option => (
                  <Radio key={option} value={option}>{option}</Radio>
              ))}
            </Radio.Group>
          </div>
      </div>
      }

      {!assessmentComplete && assessmentStep === 2 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={20} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={10} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question2?.question}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question2?.hint}
          </p>
          <div className="mt-5 ml-3">
            <Radio.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleRadioChangeQ2}
            value={Object.keys(assessment.question2.answer).find(key => assessment.question2.answer[key])}
            >
            {assessment.question2.options.map(option => (
                <Radio key={option} value={option}>{option}</Radio>
            ))}
            </Radio.Group>
          </div>
      </div>
      }

      {!assessmentComplete && assessmentStep === 3 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={30} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={20} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question3?.question}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question3?.hint}
          </p>
          <div className="mt-5">
            <SearchFormCareAssessment handleSearch={handleSearch} search={search} setSearch={setSearch}/>
          </div>
      </div>
      }

      {!assessmentComplete && assessmentStep === 4 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={40} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={30} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question1?.answer?.myself ? assessment?.question4?.questionA : assessment?.question4?.questionB}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question1?.answer?.myself ? assessment?.question4?.hintA : assessment?.question4?.hintB}
          </p>
          <div className="mt-5 ml-3">
            <Radio.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleSubmitQ4}
            value={Object.keys(assessment.question4.answer).find(key => assessment.question4.answer[key])}
            >
              {assessment.question4.options.map(option => (
                  <Radio key={option} value={option}>{option}</Radio>
              ))}
            </Radio.Group>
          </div>
      </div>
      }

      {!assessmentComplete && assessmentStep === 5 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={50} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={40} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question1?.answer?.myself ? assessment?.question5?.questionA : assessment?.question5?.questionB}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question1?.answer?.myself ? assessment?.question5?.hintA : assessment?.question5?.hintB}
          </p>
          <div className="mt-5 ml-3">
            <Checkbox.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleCheckboxChangeQ5}
            value={Object.keys(assessment.question5.answer).filter(key => assessment.question5.answer[key])}
            >
            {assessment.question5.options.map(option => (
                <Checkbox key={option} value={option}>{option}</Checkbox>
            ))}
            </Checkbox.Group>
          </div>
          <button className="form-btn mt-5" type="submit" onClick={handleSubmitQ5}>
          Next
          </button>
      </div>
      }

      {!assessmentComplete && assessmentStep === 6 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={60} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={50} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question1?.answer?.myself ? assessment?.question6?.questionA : assessment?.question6?.questionB}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question1?.answer?.myself ? assessment?.question6?.hintA : assessment?.question6?.hintB}
          </p>
          <div className="mt-5 ml-3">
            <Checkbox.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleCheckboxChangeQ6}
            value={Object.keys(assessment.question6.answer).filter(key => assessment.question6.answer[key])}
            >
              {assessment.question6.options.map(option => (
                  <Checkbox key={option} value={option}>{option}</Checkbox>
              ))}
            </Checkbox.Group>
          </div>
          <button className="form-btn mt-5" type="submit" onClick={handleSubmitQ6}>
            Next
          </button>
      </div>
      }

      {!assessmentComplete && assessmentStep === 7 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={70} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={60} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question1?.answer?.myself ? assessment?.question7?.questionA : assessment?.question7?.questionB}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question1?.answer?.myself ? assessment?.question7?.hintA : assessment?.question7?.hintB}
          </p>
          <div className="mt-5 ml-3">
            <Checkbox.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleCheckboxChangeQ7}
            value={Object.keys(assessment.question7.answer).filter(key => assessment.question7.answer[key])}
            >
              {assessment.question7.options.map(option => (
                  <Checkbox key={option} value={option}>{option}</Checkbox>
              ))}
            </Checkbox.Group>
          </div>
          <button className="form-btn mt-5" type="submit" onClick={handleSubmitQ7}>
            Next
          </button>
      </div>
      }

      {!assessmentComplete && assessmentStep === 8 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={80} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={70} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }
          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question1?.answer?.myself ? assessment?.question8?.questionA : assessment?.question8?.questionB}</h1>
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">
          {assessment?.question1?.answer?.myself ? assessment?.question8?.hintA : assessment?.question8?.hintB}
          </p>
          <div className="mt-5 ml-3">
            <Radio.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleSubmitQ8}
            value={Object.keys(assessment.question8.answerBoolean).find(key => assessment.question8.answerBoolean[key])}
            >
              {assessment.question8.options.map(option => (
                  <Radio key={option} value={option}>{option}</Radio>
              ))}
            </Radio.Group>
          </div>
      </div>
      }

      {!assessmentComplete && assessmentStep === 9 && 
      <div>
          {assessment?.context?.userId ? 
            <ProgressBar 
            completed={90} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          :
            <ProgressBar 
            completed={80} 
            labelSize='13px'
            bgColor='#216017' //#6a818c or #8abb82 or #a9cda3 or #216017
            />
          }

          <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">{assessment?.question1?.answer?.myself ? assessment?.question9?.questionA : assessment?.question9?.questionB}</h1>
          <div className="mt-5 ml-3">
            <Checkbox.Group
            className="dropdwn checkbox"
            style={{ display: "flex", flexDirection: "column" }}
            onChange={handleCheckboxChangeQ9}
            value={Object.keys(assessment.question9.answer).filter(key => assessment.question9.answer[key])}
            >
              {assessment.question9.options.map(option => (
                  <Checkbox key={option} value={option}>{option}</Checkbox>
              ))}
            </Checkbox.Group>
          </div>
          <button className="form-btn mt-5" type="submit" onClick={handleSubmitQ9}>
            Next
          </button>
      </div>
      }

      {assessmentComplete && 
      <div>
        <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-5">Thank you for completing your care assessment!</h1>
        {count > 0 && (
          <p className="p-20px mt-3 text-grayy d-flex justify-content-center">Gathering your recommended providers in {count}...</p>
        )}
      </div>
      }
    </>
  );
}

export default CareAssessmentForm_Long;

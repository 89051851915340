import {useAuth} from '../../context/auth';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useState } from "react";
import Share from "../../assets/images/share.png";
import { Modal } from "antd";
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ShareIcon } from "../../assets/icons/shareIcon.svg";


export default function ShareListing({ad}) {
    // context
    const [auth, setAuth] = useAuth();

    const [open, setOpen] = useState(false);
    const [receiverEmail, setReceiverEmail] = useState('');
    const [receiverPhone, setReceiverPhone] = useState('');
    const [shareLinkEmail, setShareLinkEmail] = useState(false);
    const [shareLinkSms, setShareLinkSms] = useState(false);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);


    const handleShareEmail = async () => {
        if(auth.user === null) {
            navigate("/login", {
                state: `/listings/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`,
            });
            return;
        }

        if (isValidEmail(receiverEmail)) {
            try {
                setLoading(true);

                const {data} = await axios.post('/listings/share/by-email', {
                    receiverEmail: receiverEmail,
                    senderEmail: auth?.user?.email,
                    listingUrl: window.location.href,
                    senderFirstName: auth?.user?.firstName,
                });
                
                if(data?.error) {
                    toast.error(data?.error);
                    setLoading(false);
                } else {
                    toast.success('Email Sent');
                    setOpen(false);

                    // analytics
                    await axios.post('/analytics/user/share-listing', {
                        listingId: ad._id,
                        userId: auth.user._id,
                        type: 'email',
                        receiverEmail: receiverEmail,
                        receiverPhone: null,
                        listingUrl: window.location.href,
                    });

                    setReceiverEmail('');
                    setShareLinkEmail(false)
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
            } else {
                // Handle invalid email case
                toast.error('Invalid email. Please enter a valid email address.');
            }
    };


    const handleSharePhone = async () => {
        if(auth.user === null) {
            navigate("/login", {
                state: `/listings/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`,
            });
            return;
        }

        try {
            setLoading(true);

            const {data} = await axios.post('/listings/share/by-sms', {
                receiverPhone: receiverPhone,
                listingUrl: window.location.href,
                senderFirstName: auth?.user?.firstName,
            });
            
            if(data?.error) {
                toast.error(data?.error);
                setLoading(false);
            } else {
                toast.success('SMS Sent');
                setOpen(false);

                // analytics
                await axios.post('/analytics/user/share-listing', {
                    listingId: ad._id,
                    userId: auth.user._id,
                    type: 'sms',
                    receiverEmail: null,
                    receiverPhone: receiverPhone,
                    listingUrl: window.location.href,
                });

                setReceiverPhone('');
                setShareLinkSms(false)
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const isValidEmail = (email) => {
        // Use a regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    return (
        <>
            <div
                className="pointer"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <div className="p-1 bg-[#d4e7dc] border border-black w-fit rounded-full mb-4">
                  <ShareIcon className="w-8 h-8 stroke-black" />
                </div>
            </div>

            <Modal
                open={open}
                onCancel={() => {
                    setOpen(false);
                }}
                title={null}
                footer={null}
            >
                <>
                    {auth.user === null ? (
                        <>
                            <h1 className="heading-48px text-primaryBlack d-flex justify-content-center mt-3 mb-3">
                                Share Home
                            </h1>
                            <p className="p-20px mb-5 text-grayy">
                                Please <a href="/login">log in</a> or <a href="/register">sign up</a> to share this {ad?.facilityType} via Email or SMS.
                            </p>
                        </>
                    ):(
                        <>
                            {shareLinkEmail === false && shareLinkSms === false && (
                                <>
                                    <h5 className="text-primaryBlack d-flex justify-content-center mt-3 mb-3">How do you want to share this home?</h5>
                                    <div className="d-flex justify-content-center mt-3">
                                        <button
                                            type="button"
                                            className="text-white px-3 py-1 rounded-md font-satoshiBold border-none text-[16px] mr-5"
                                            style={{ background: '#216017' }}
                                            onClick={() => setShareLinkEmail(true)}
                                        >
                                            Email
                                        </button>

                                        <button
                                            type="button"
                                            className="text-white px-3 py-1 rounded-md font-satoshiBold border-none text-[16px]"
                                            style={{ background: '#216017' }}
                                            onClick={() => setShareLinkSms(true)}
                                        >
                                            SMS
                                        </button>
                                    </div>
                                </>
                            )}

                            {shareLinkEmail === false && shareLinkSms === true && (
                                <>
                                    <form>
                                        <div className="d-flex justify-content-center mt-4">
                                            <input
                                            type="phone"
                                            placeholder="Enter Receiver Phone Number"
                                            value={receiverPhone}
                                            onChange={(e) => setReceiverPhone(e.target.value)}
                                            inputMode="phone"
                                            required
                                            className='col col-lg-6 col-md-9 col-sm-12'
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className='col-3 d-flex align-items-center text-left'>
                                                <button
                                                    type="button"
                                                    className="bg-white px-3 py-1 rounded-md font-satoshiBold border-none text-[12px]"
                                                    style={{ text: '#216017' }}
                                                    onClick={() => setShareLinkSms(false)}
                                                >
                                                Back
                                                </button>
                                            </div>
                                            <div className='col-6 d-flex justify-content-center'>
                                                <button
                                                    type="button"
                                                    className="text-white px-3 py-1 rounded-md font-satoshiBold border-none text-[16px]"
                                                    style={{ background: '#216017' }}
                                                    onClick={handleSharePhone}
                                                >
                                                Send SMS
                                                </button>
                                            </div>
                                            <div className='col-3'></div>
                                        </div>
                                    </form>
                                </>
                            )}

                            {shareLinkEmail === true && shareLinkSms === false && (
                                <>
                                    <form>
                                        <div className="d-flex justify-content-center mt-4">
                                            <input
                                            type="email"
                                            placeholder="Enter Receiver Email"
                                            value={receiverEmail}
                                            onChange={(e) => setReceiverEmail(e.target.value)}
                                            inputMode="email"
                                            required
                                            className='col col-lg-6 col-md-9 col-sm-12'
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className='col-3 d-flex align-items-center text-left'>
                                                <button
                                                    type="button"
                                                    className="bg-white px-3 py-1 rounded-md font-satoshiBold border-none text-[12px]"
                                                    style={{ text: '#216017' }}
                                                    onClick={() => setShareLinkEmail(false)}
                                                >
                                                Back
                                                </button>
                                            </div>
                                            <div className='col-6 d-flex justify-content-center'>
                                                <button
                                                    type="button"
                                                    className="text-white px-3 py-1 rounded-md font-satoshiBold border-none text-[16px]"
                                                    style={{ background: '#216017' }}
                                                    onClick={handleShareEmail}
                                                >
                                                Send Email
                                                </button>
                                            </div>
                                            <div className='col-3'></div>
                                        </div>
                                    </form>
                                </>
                            )}
                        </>
                    )}
                </>
            </Modal>
        </>
    );
}
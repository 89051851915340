import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import AdCardHome from "../components/cards/AdCardHome";
import SearchForm from "../components/forms/SearchForm.jsx";
import { GOOGLE_MAPS_KEY } from "../config";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useSearch } from "../context/search";
import queryString from "query-string";
import "../styles/map.css";
import { getCookie } from "./../helpers/utilities/Cookie.js";
import { useAuth } from "../context/auth.js";
import { ReactComponent as MapIcon } from "../assets/icons/mapIcon.svg";
import { ReactComponent as ListIcon } from "../assets/icons/listIcon.svg";
import { Modal } from "antd";
import CareAssessmentFormShort from "../components/forms/CareAssessmentForm_Short.jsx";
import { getFromLocalStorage } from "../helpers/utilities/LocalStorage.js";

function deSlugify(str) {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function Search() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const location = useLocation();
  const [auth, setAuth] = useAuth();
  const [listings, setListings] = useState([]);
  const [item, setItem] = useState("results");
  const [search, setSearch] = useSearch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [numOfListings, setNumOfListings] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(0);
  const [openModel, setModel] = useState(0);

  const [careAssessmentComplete, setCareAssessmentComplete] = useState(false);
  const [recommendedSelected, setRecommendedSelected] = useState(false);
  const [recommendedUrl, setRecommendedUrl] = useState("");

  const { type, state, county, city } = useParams();
  const [filterParameters, setFilterParameters] = useState({
    specialty: [],
    medicaid: [],
    budget: [],
    range: [],
  });

  const [markers, setMarkers] = useState([]);

  // checks assessment completion status whenever modal is opened or closed
  useEffect(() => {
    setCareAssessmentComplete(getFromLocalStorage("care-assessment-complete"));
  }, [setModel]);

  useEffect(() => {
    // Update parameters based on the URL
    const specialty = searchParams.getAll("specialty");
    const medicaid = searchParams.getAll("medicaid");
    const budget = searchParams.getAll("budget");
    const range = searchParams.getAll("range");

    setFilterParameters({
      specialty: specialty,
      medicaid: medicaid,
      budget: budget,
      range: range,
    });

    // Fetch listings based on the parameters in the URL
    setCareAssessmentComplete(getFromLocalStorage("care-assessment-complete"));
    fetchAds(specialty, medicaid, budget, range);
  }, [type, state, county, city, searchParams]);

  useEffect(() => {
    // Map listings to markers format
    const newMarkers = listings.map((ad) => ({
      title: ad?.title,
      link: `/listing/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`,
      lat: ad?.location?.coordinates[1],
      lng: ad?.location?.coordinates[0],
    }));

    // Update the markers state
    setMarkers(newMarkers);
  }, [listings]);

  const fetchAds = async (specialty, medicaid, budget, range) => {
    setLoading(true);
    try {
      const visitorId = getCookie("visitorId");
      const userId = auth?.user?._id ? auth?.user?._id : null;

      const query = queryString.stringify({
        type,
        state,
        county,
        city,
        specialty,
        medicaid,
        budget,
        range,
        visitorId,
        userId,
      });

      const { data } = await axios.get(
        `/listings/search/by-address-and-type?${query}`
      );

      if (data) {
        // Set the fetched listings in the state
        setListings(data?.data?.searchResults);
        setNumOfListings(data?.data?.numberOfListings);
        setLoading(false);
      } else {
        console.log(data.error);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentUrl = location.pathname + location.search;
    const recommendedUrlExists = getFromLocalStorage("care-assessment")?.context?.recommendationUrl;

    if ( recommendedUrlExists ) {
      setRecommendedUrl(recommendedUrlExists);
      setRecommendedSelected(false);

      if (recommendedUrlExists === currentUrl) {
        setRecommendedSelected(true);
      };
    };

  }, [fetchAds]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
  });

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, title, link) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, title, link });
    setIsOpen(true);
  };

  useEffect(() => {
    // If the map reference and markers are both available
    if (mapRef && markers.length > 0) {
      // Fit the bounds to include all markers
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      mapRef.fitBounds(bounds);
    }
  }, [mapRef, markers]);

  return (
    <div
      className="lgTablet:flex justify-between lgTablet:h-screen max-h-screen"
      onClick={(e) => {
        if (open !== 0) {
          setOpen(0);
        }
      }}
    >
      <div
        className={`w-full grow shrink basis-1/6 lgTablet:block ${
          item === "results" ? "block" : "hidden"
        } mx-auto lgTablet:w-[60%]   txt-left overflow-auto`}
        style={{height: '100%'}}
      >
        <div className="lgTablet:fixed lgTablet:bg-bgCard lgTablet:z-20 lgTablet:pt-8 lgTablet:w-[57%]  lgTablet:!px-12 !px-2">
          <div>
            <SearchForm
              setSearchParams={setSearchParams}
              setSearch={setSearch}
              search={search}
              open={open}
              setOpen={setOpen}
              recommendedFilterActive={careAssessmentComplete}
              recommendedSelected={recommendedSelected}
              recommendedUrl={recommendedUrl}
            />
          </div>

          {/* Show and hide this section depending on whether care assessment is complete or not */}
          {!careAssessmentComplete && 
            <div className="lgTablet:col-span-4 flex flex-col gap-2.5 top-48 lgTablet:gap-4 !py-5 items-center justify-center max-lgTablet:fixed max-lgTablet:z-50 max-lgTablet:bg-bgCard left-0 max-lgTablet:px-2 max-lgTablet:w-full max-lgTablet:!py-1.5">
              <h1 className=" whitespace-nowrap text-xl lgTablet:text-3xl  font-medium text-center text-primaryBlack ">
                Get Personalized Recommendations
              </h1>

              <button
                className="btn-sm bg-green text-white border-white "
                onClick={() => {
                  setModel(1);
                }}
              >
                Complete Care Assessment
              </button>
            </div>
          }
        </div>
        {/* Change the padding top programatically according to care assessment completion*/}
        {/* <div className="pt-52 lgTablet:pt-[26rem] desktop:pt-80 pb-8 !px-2 lgTablet:!px-12 bigScreen:pl-[6%]"> */}
        <div className={`${careAssessmentComplete ? "pt-36 lgTablet:pt-[16rem] desktop:pt-27 pb-8 !px-2 lgTablet:!px-10 bigScreen:pl-[6%]" : "pt-52 lgTablet:pt-[26rem] desktop:pt-80 pb-8 !px-2 lgTablet:!px-12 bigScreen:pl-[6%]"}`}>
          <div className="space-y-2.5">
            <p className="text-4xl m-0 !font-normal text-primaryBlack">
              Results for {deSlugify(city)}
            </p>
            <p className="text-2xl text-primaryBlack">
              {numOfListings} Results
            </p>
          </div>
          <div className="mt-[2rem] grid grid-cols-2 gap-y-5 gap-x-5 lgTablet:gap-x-10">
            {loading ? (
              <>
                <h3 className="p-20px text-center d-flex justify-content-center">
                  Loading..
                </h3>
              </>
            ) : (
              <>
                {listings?.length < 1 ? (
                  <h3 className="p-20px text-center">No listings found</h3>
                ) : (
                  listings?.map((ad) => <AdCardHome ad={ad} key={ad._id} />)
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={` w-full flex-1 ${
          item === "map" ? "block" : "hidden"
        } lgTablet:block`}
      >
        <div className="!py-5 lgTablet:hidden">
          <SearchForm
            setSearchParams={setSearchParams}
            setSearch={setSearch}
            search={search}
            open={open}
            setOpen={setOpen}
            recommendedFilterActive={careAssessmentComplete}
            recommendedSelected={recommendedSelected}
            recommendedUrl={recommendedUrl}
          />
        </div>
        {/* Show and hide this section depending on whether care assessment is complete or not */}
        {!careAssessmentComplete && 
          <div className="lgTablet:col-span-4 flex flex-col gap-2.5 top-52 md:top-[220px] lgTablet:gap-8 py-10 items-center justify-center max-lgTablet:fixed max-lgTablet:z-50 lgTablet:hidden max-lgTablet:bg-bgCard left-0 max-lgTablet:px-2 max-lgTablet:w-full max-lgTablet:py-1.5">
            <h1 className=" text-xl lgTablet:text-3xl  font-medium text-center text-primaryBlack ">
              Get Personalized Recommendations
            </h1>

            <button
              className="btn-sm bg-green text-white border-white "
              onClick={() => {
                setModel(1);
              }}
            >
              Complete Care Assessment
            </button>
          </div>
        }
        <div className={`${careAssessmentComplete ? "w-full h-screen sticky top-0 max-lgTablet:mt-30" : "w-full h-screen sticky top-0 max-lgTablet:mt-40"}`}>
  {!isLoaded ? (
    <h1>Loading...</h1>
  ) : (
    <GoogleMap
      mapContainerClassName="map-container h-full"
      onLoad={onMapLoad}
      onClick={() => setIsOpen(false)}
    >
      {markers.map(({ title, link, lat, lng }, ind) => (
        <Marker
          key={ind}
          position={{ lat, lng }}
          icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
          onClick={() => {
            handleMarkerClick(ind, lat, lng, title, link);
          }}
        >
          {isOpen && infoWindowData?.id === ind && (
            <InfoWindow
              onCloseClick={() => {
                setIsOpen(false);
              }}
            >
              <a
                href={infoWindowData.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5>{infoWindowData.title}</h5>
              </a>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  )}
</div>

      </div>
      <div className="flex justify-center lgTablet:hidden fixed left-0 right-0 bottom-8 m-auto  ">
        {item === "results" && (
          <div
            className={`flex py-3 gap-1.5 px-8 bg-green rounded-2xl.5 items-center justify-center cursor-pointer mr-2`}
            onClick={() => {
              setItem("map");
            }}
          >
            <MapIcon />
            <p className="text-sm font-roboto-flex m-0 text-white">Map</p>
          </div>
        )}
        {item === "map" && (
          <div
            className={` flex gap-1.5 py-3 px-8 bg-green rounded-2xl.5 justify-center items-center cursor-pointer`}
            onClick={() => {
              setItem("results");
            }}
          >
            <ListIcon />
            <p className="text-sm font-roboto-flex m-0 text-white">List </p>
          </div>
        )}
      </div>
      <Modal
        open={openModel === 1}
        onCancel={() => {
          setModel(0);
        }}
        footer={null}
      >
        <div className="mt-3">
          <CareAssessmentFormShort 
            setCareAssessmentComplete={setCareAssessmentComplete}
            setModel={setModel}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Search;

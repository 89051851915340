import Logo from "../../components/misc/Logo";
import { Helmet } from "react-helmet";
import CareAssessmentForm_Long from "../../components/forms/CareAssessmentForm_Long.jsx";


function BasicCareAssessment() {

  return (
    <>
      <Helmet>
        <title>Personal Care Assessment</title>
        <meta name="description" content="Complete a quick care assessment to receive personalized recommendations!" />
      </Helmet>
      <div className="block lgTablet:mr-auto mt-10 d-flex justify-content-center ml-3">
        <Logo />
      </div>
      <div className="flex justify-between">
        <div className="w-full max-w-lg lgTablet:max-w-full mx-auto lgTablet:w-1/2 px-[1rem] sm:px-[1.5rem] lg:px-[4rem] 2xl:px-[8%] py-12 txt-left">
          <CareAssessmentForm_Long />
        </div>
      </div>
    </>
  );
}

export default BasicCareAssessment;

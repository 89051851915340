import { formatNumber } from '../../helpers/ad';
import {Badge} from 'antd';
import {Link} from 'react-router-dom';
import AdFeatures from '../../components/cards/AdFeatures'
import genericListing from '../../listing.png';

export default function UpdateAdCard({ad}) {
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 p-4 gx-4 gy-4">
            <Link to={`/user/ad/${ad.slug}`}>
                <Badge.Ribbon 
                text={`${ad?.type} for ${ad.action}`}
                color={`${ad?.action === "Sell" ? "green" : "gray"}`}
                >
                    <div className="card hoverable shadow">
                        <img 
                            src={`${ad?.photos?.length > 0 ? (ad?.photos?.[0].Location) : (genericListing)}`} 
                            alt={`${ad?.type}-${ad?.address}-${ad?.action}-${ad?.price}`} 
                            style={{height: '250px', objectFit: 'cover'}}
                        />

                        <div className="card-body">
                            <div>
                                <h3>${formatNumber(ad?.price)}</h3>
                                <p className='card-text'>{ad?.address}</p>
                            </div>

                            <AdFeatures ad={ad} />

                        </div>
                    </div>
                </Badge.Ribbon>
            </Link>
        </div>
        
    );
}
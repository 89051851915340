import { useState } from "react";
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../helpers/utilities/Cookie";


export default function ClientHelp() {
    const navigate = useNavigate();

    const [supportMessage, setSupportMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const visitorId = getCookie('visitorId');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            setLoading(true);
            const {data} = await axios.post(`/support/create-ticket/client`, {
                supportMessage,
                visitorId,
            });
            
            if (data?.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                toast.success("Support ticket created.");
                setLoading(false);
                navigate("/client/help");
            }
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong. Please try again.");
            setLoading(false);
        }

    };


    return (
        <div>
            <div className="container text-center mt-5 mb-5">
                <h3 className="display-4 text-dark">Contact Support</h3>
            </div>

            <div className="container justify-content-center">
                <div className="row justify-content-center">
                    <div className="col col-lg-9 col-md-9">
                        <form onSubmit={handleSubmit}>
                            <textarea 
                                rows={5} 
                                placeholder='What do you need help with?' 
                                className='form-control mb-3' 
                                value={supportMessage} 
                                required 
                                onChange={(e) => setSupportMessage(e.target.value )} 
                            />

                            <div className="d-flex justify-content-center">
                                <button 
                                    disabled={loading} 
                                    className="btn btn-primary btn-sm col-4 p-1 mb-5" 
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {loading ? "Processing.." : "Submit"}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
import  {useState, useEffect } from 'react';
import {useAuth} from "../../context/auth";
import axios from 'axios';
import AdCardSavedListings from '../../components/cards/AdCardSavedListings';


export default function Engagements() {

    const [auth, setAuth] = useAuth();

    const [ads, setAds] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAds();
    }, [auth.token !== '']);

    const fetchAds = async () => {
        try {
            const {data} = await axios.get('/wishlist');
            setAds(data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className="container text-center mt-5 mb-5">
                <h3 className="display-4 text-dark">Saved Homes</h3>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-9">
                        {/* Liked Homes */}
                        <div className='row mb-5'>
                            {!ads?.length ? (
                                <div className='row'>
                                    <div className='col-lg-12 text-center'>
                                        <h4>0 Liked Homes.</h4>
                                    </div>
                                </div>
                            ) : (
                                <div className='container'>
                                    <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3'>
                                        {ads?.map(ad => (
                                            <div className='col mb-4' key={ad._id}>
                                                <AdCardSavedListings ad={ad} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}
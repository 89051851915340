import { useAuth } from '../../context/auth'
import { useState, useEffect } from 'react';
import axios from 'axios';

import ClientSidebar from '../../components/nav/client/ClientSidebar';
import NewAssessmentForm from '../../components/forms/NewAssessmentForm';
import CurrentAssessmentForm from '../../components/forms/CurrentAssessmentForm';



export default function CreateAssessment() {
    // context
    const [auth, setAuth] = useAuth();
    const [assessment, setAssessment] = useState({});
    const [loading, setLoading] = useState(true);

    const isMobile = window.innerWidth < 600;


    const [isNewAssessmentOpen, setIsNewAssessmentOpen] = useState(false);
    const [isCurrentAssessmentOpen, setIsCurrentAssessmentOpen] = useState(false);

    // checks if assessment is completed by user
    const assessmentCompleted = auth.user?.validCareAssessment  === 'Yes';

    
    
    useEffect(() => {
            if (assessmentCompleted) fetchAssessment();
        }, [assessmentCompleted]);
    
    const fetchAssessment = async () => {
            try {
                // console.log(params.title)
                const {data} = await axios.get(`/current-care-assessment/${auth.user?.careAssessmentId}`);
                
                setAssessment(data?.assessment);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };


    const toggleNewAssessment = () => {
        setIsNewAssessmentOpen(!isNewAssessmentOpen);
    };

    const toggleCurrentAssessment = () => {
        setIsCurrentAssessmentOpen(!isCurrentAssessmentOpen);
    };


    return (
        <div>
            <div className="container text-center mt-5 mb-5">
                <h3 className="display-4 text-dark">Care Assessment</h3>
            </div>

            <div className="container justify-content-center">
                <div className="row justify-content-center">
                    <div className="col col-lg-9 col-md-9 text-center">
                        {assessmentCompleted ? (
                            <a className="btn btn-primary mb-5" style={{ textTransform: "capitalize" }} onClick={toggleCurrentAssessment}>
                                {isCurrentAssessmentOpen ? (
                                    <>
                                        {isMobile ? (
                                            <div style={{
                                                position: 'fixed',
                                                top: '0',
                                                left: '0',
                                                height: '100vh',
                                                width: '100vw',
                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                                cursor: 'default',
                                            }} onClick={toggleCurrentAssessment}>
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                    width: '100%',
                                                    height: '90vh',
                                                }}
                                                >
                                                    <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                                                        <div className="card-header text-primary">View Care Assessment</div>
                                                            <div className="card-body" style={{ overflowY: 'auto', maxHeight: 'calc(50vh - 80px)' }}>
                                                                <CurrentAssessmentForm  assessment={assessment}/>
                                                            </div>
                                                        <div className="card-footer text-muted">SeniorCareHomes.Org</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ):(
                                            <div style={{
                                                position: 'fixed',
                                                top: '0',
                                                left: '0',
                                                height: '100vh',
                                                width: '100vw',
                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                                cursor: 'default',
                                            }} onClick={toggleCurrentAssessment}>

                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                    width: '70%',
                                                    height: '75vh', // 50% of the viewport height
                                                    margin: '15vh 15vw', // 25% offset from top and left
                                                    padding: '20px' // Add padding as needed
                                                }}
                                                >

                                                    <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                                                        <div className="card-header text-primary">View Care Assessment</div>
                                                            <div className="card-body" style={{ overflowY: 'auto', maxHeight: 'calc(50vh - 80px)' }}>
                                                                <CurrentAssessmentForm  assessment={assessment}/>
                                                            </div>
                                                        <div className="card-footer text-muted">SeniorCareHomes.Org</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    'View Care Assessment'
                                )}
                            </a>
                        ) : (
                            <>
                                <div className='container text-center mt-3 mb-3' style={{ padding: '1%' }}>
                                    <p className='mb-3'>Take our 3-5 minute free assessment today and send it over to Providers for a quick evaluation on whether you're compatible with their facility!</p>
                                    <p>*** Sharing a complete and accurate care assessment with a provider is required before requesting a tour.***</p>
                                </div>
                                <a className="btn btn-primary mb-5" style={{ textTransform: "capitalize" }} onClick={toggleNewAssessment}>
                                    {isNewAssessmentOpen ? (
                                        <>
                                            {isMobile ? (
                                                <div style={{
                                                    position: 'fixed',
                                                    top: '0',
                                                    left: '0',
                                                    height: '100vh',
                                                    width: '100vw',
                                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                                    cursor: 'default',
                                                }} onClick={toggleNewAssessment}>

                                                    <div
                                                        className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                        width: '100%',
                                                        height: '90vh',
                                                    }}
                                                    >

                                                        <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                                                            <div className="card-header text-primary">New Care Assessment</div>
                                                                <div className="card-body" style={{ overflowY: 'auto', maxHeight: 'calc(60vh - 80px)' }}>
                                                                    <NewAssessmentForm />
                                                                </div>
                                                            <div className="card-footer text-muted">SeniorCareHomes.Org</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ):(
                                                <div style={{
                                                    position: 'fixed',
                                                    top: '0',
                                                    left: '0',
                                                    height: '100vh',
                                                    width: '100vw',
                                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                                    cursor: 'default',
                                                }} onClick={toggleNewAssessment}>

                                                    <div
                                                        className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                        width: '70%',
                                                        height: '75vh', // 50% of the viewport height
                                                        margin: '15vh 15vw', // 25% offset from top and left
                                                        padding: '20px' // Add padding as needed
                                                    }}
                                                    >

                                                        <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                                                            <div className="card-header text-primary">New Care Assessment</div>
                                                                <div className="card-body" style={{ overflowY: 'auto', maxHeight: 'calc(50vh - 80px)' }}>
                                                                    <NewAssessmentForm />
                                                                </div>
                                                            <div className="card-footer text-muted">SeniorCareHomes.Org</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        'New Care Assessment'
                                    )}
                                </a>
                            </>
                        )}      
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useState } from "react";
import { Checkbox, Form, Input } from "antd";
import tick from "../../assets/images/tick.svg";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../helpers/utilities/Cookie";
import { FaArrowRight } from "react-icons/fa";

function VerifyAccountForm({ token }) {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [checkTerm, setCheckTerms] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");

  const handleSendCode = async (e) => {
    e.preventDefault();

    if (!checkTerm) {
      setShowError(true);
      return;
    }

    try {
      setLoading(true);

      const { data } = await axios.post(`/register`, {
        token: token,
        phone: phoneNumber,
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        setCodeSent(true);
        toast.success("Code sent!");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again", error);
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { data } = await axios.post(`/post-register`, {
        token: token,
        phone: phoneNumber,
        code: code,
        visitorId: getCookie("visitorId"),
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        setAuth(data);
        localStorage.setItem("auth", JSON.stringify(data));
        toast.success("Account Successfully Created!");
        setLoading(false);

        if (data?.user?.role === "Client") {
          location?.state !== null
                ? navigate(location.state)
                : navigate("/client/engagements");
        }

        if (data?.user?.role === "Provider") {
          if (data?.user?.identity?.googleGeocoder?.formattedAddress === null) {
            location?.state !== null
              ? navigate(location.state)
              : navigate("/provider/listings");
          } else {
            location?.state !== null
              ? navigate(location.state)
              : navigate("/provider/inbox");
          }
        }

        if (data?.user?.role === "Agent") {
          location?.state !== null
            ? navigate(location.state)
            : navigate("/agent/dashboard");
        }
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again", error);
      setLoading(false);
    }
  };

  return (
    <>
      {codeSent ? (
        <div className="space-y-12 lgTablet:space-y-24">
          <p className="text-sm lgTablet:text-2xl font-roboto-flex text-grayy">
            Please enter the code you received via text message.
          </p>
          <form
            onSubmit={(event) => handleVerifyCode(event)}
            className="!px-0 lgTablet:!px-5 space-y-6 lgTablet:space-y-12"
          >
            <div>
              <label className="text-lg lgTablet:text-2xl font-roboto-flex">
                Code
              </label>
              <input
                type="text"
                id="code"
                name="code"
                className="custom-input auth-input w-full"
                onChange={(event) => setCode(event.target.value)}
                value={code}
                required
              />
            </div>

            <div>
              <button className="form-btn !w-full" type="submit">
                Confirm Code
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="space-y-12 lgTablet:space-y-24">
          <p className="text-sm lgTablet:text-2xl font-roboto-flex text-grayy">
            In order to use this platform, we require a valid phone number
            capable of receiving text messages. We don’t sell your contact
            information and only send critical transactional text messages(i.e.
            someone you reached out to is responding to your message, etc).
          </p>
          <form
            onSubmit={(e) => handleSendCode(e)}
            className="!px-0 lgTablet:!px-5"
          >
            <div className="">
              <label className="text-lg lgTablet:text-2xl font-roboto-flex">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                className="custom-input auth-input w-full"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                required
              />
            </div>

            <div>
              <div>
                <div className="flex items-start !pt-5 lgTablet:!pt-10 !pb-5">
                  <Checkbox onChange={(e) => setCheckTerms(e.target.checked)} />

                  <span className="text-xs lgTablet:text-base italic text-grayy ml-[12px] text-left">
                    By checking this box, you agree to our Terms of Service and
                    Privacy Policy. You agree to receive automated Transactional
                    & Account Activity messages. Consent is not a condition of
                    purchase. Txt and data rates may apply. Reply STOP to
                    unsubscribe or disable notification directly in dashboard
                    account settings.
                  </span>
                </div>
                {showError && (
                  <div className="d-flex justify-content-center mt-2">
                    <span className="p-14px text-red-500">
                      Please accept Terms of Use & Privacy Policy
                    </span>
                  </div>
                )}
                <div className="">
                  <button className="form-btn !w-full" type="submit">
                    Send Code
                  </button>
                </div>
              </div>
            </div>
            <div className="text-center flex-align-center justify-center !pt-5 lgTablet:!pt-10">
              <p className="p-16px font-roboto-flex italic text-primaryBlack mb-0">
                Already have an account
              </p>
              <div className="ml-[12px] flex-align-center">
                <Link
                  to="/login"
                  className="no-underline hover:no-underline flex-align-center gap-2.5"
                >
                  <span className="p-16px text-green italic">Login</span>
                  <FaArrowRight className="text-green" />
                </Link>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default VerifyAccountForm;

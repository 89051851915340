import Resizer from "react-image-file-resizer";
import axios from 'axios';
import {Avatar} from 'antd';
import { useAuth } from "../../context/auth";



export default function ProfileUpload ({photo, setPhoto, uploading, setUploading,}) {
    // context
    const [auth, setAuth] = useAuth();

    const handleUpload = async (e) => {
        try {
            let file = e.target.files[0];

            if(file) {
                // console.log(files);
                setUploading(true);

                new Promise(() => {
                    Resizer.imageFileResizer(
                        file,
                        1080,
                        720,
                        "JPEG",
                        100,
                        0,
                        async (uri) => {
                            try {
                                const {data} = await axios.post('/upload-image', {
                                    image: uri,
                                });
                                setPhoto(data);
                                setUploading(false);
                            } catch (error) {
                                console.log(error);
                                setUploading(false);
                            }
                        },
                        "base64"
                    );
                });

            }

            
        } catch (error) {
            console.log(error);
            setUploading(false);
        }
    };

    const handleDelete = async (file) => {
        const answer = window.confirm('Delete Image?');
        if (!answer) return;
        setUploading(true);
        try {
            const {data} = await axios.post('/remove-image', photo);
            if(data?.ok) {
                setPhoto(null);
                setUploading(false);
            }
        } catch (error) {
            console.log(error);
            setUploading(false);
        }
    };

    return (
        <div className="d-flex align-items-center position-relative"> {/* Center the content */}
            {photo?.Location ? (
                <div className="position-relative">
                    <Avatar
                        src={photo.Location}
                        shape="circle"
                        size="large"
                        className="ml-2 mb-2"
                        onClick={() => handleDelete()}
                    />
                    <div 
                        className="delete-icon" 
                        onClick={() => handleDelete()}
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                    >
                        <span>X</span>
                    </div>
                </div>
            ) : (
                <label className="btn btn-warning btn-sm col-3 p-1" style={{ textTransform: "capitalize" }}>
                    {uploading ? "Processing.." : "Upload"}
                    <input
                        onChange={handleUpload}
                        type="file"
                        accept="image/*"
                        hidden
                    />
                </label>
            )}
        </div>
    );
}
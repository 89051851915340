import { useState, useEffect, useParams, useRef, useLayoutEffect } from "react";
import { useAuth } from "../../context/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { format } from "date-fns";
import { useMediaQuery } from 'react-responsive';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';


import MessagerCardContacts from "../cards/MessagerCardContacts";
import MessagerCardSingleThread from "../cards/MessagerCardSingleThread";



export default function FullMessager() {
    // context
    const [auth, setAuth] = useAuth();

    const isSmallScreen = useMediaQuery({ query: '(max-width: 775px)' }); // Adjust the breakpoint as needed
    const [threadOpen, setThreadOpen] = useState(false);


    const [selectedSlots, setSelectedSlots] = useState({});

    const [newDate, setNewDate] = useState(new Date());

    // state
    const [message, setMessage] = useState();
    const [listingId, setListingId] = useState();
    const [receivedBy, setRecievedBy] = useState();
    const [allThreads, setAllThreads] = useState();
    const [currentThread, setCurrentThread] = useState();

    const [engagement, setEngagement] = useState();
    const [assessmentShared, setAssessmentShared] = useState(false);
    const [assessmentRequested, setAssessmentRequested] = useState(false);
    const [assessmentViewed, setAssessmentViewed] = useState(false);
    const [assessmentCompatible, setAssessmentCompatible] = useState(false);


    const [isTourFeatureOpen, setisTourFeatureOpen] = useState(false);
    const [tourRequestedStatus, setTourRequestedStatus] = useState(false);
    const [tourOfferClientStatus, setTourOfferClientStatus] = useState(false);
    const [tourOfferClientSchedule, setTourOfferClientSchedule] = useState();
    const [tourOfferProviderStatus, setTourOfferProviderStatus] = useState(false);
    const [tourOfferProviderSchedule, setTourOfferProviderSchedule] = useState();
    const [tourAcceptedStatus, setTourAcceptedStatus] = useState(false);
    const [tourRejectedStatus, setTourRejectedStatus] = useState(false);
    const [tourAcceptedSchedule, setTourAcceptedSchedule] = useState();
    const [tourCompletedStatus, setTourCompletedStatus] = useState(false);
    const [tourReviewAcceptedPrivateStatus, setTourReviewAcceptedPrivateStatus] = useState(false);
    const [tourReviewAcceptedPrivateFeedback, setTourReviewAcceptedPrivateFeedback] = useState();
    const [tourReviewAcceptedPublicStatus, setTourReviewAcceptedPublicStatus] = useState(false);
    const [tourReviewAcceptedPublicFeedback, setTourReviewAcceptedPublicFeedback] = useState();

    const [selectedDate, setSelectedDate] = useState(null);


    const [loading, setLoading] = useState();
    const [ad, setAd] = useState();
    
    // hooks
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const containerRef = useRef(null);
    

    const loggedIn = auth.user !== null && auth.token !== ""; // returns true if user is logged in and session is valid
    // use something similar for paid accounts and additional features, for both client and owner

    // checks if assessment is completed by user
    const assessmentCompleted = auth.user?.validCareAssessment  === "Yes";

    const handleBack = () => {
        navigate('/client/inbox');
        setThreadOpen(false);
    };

    // fetches all different conversations(relationships) which include the logged in user
    useEffect(() => {
        fetchAllThreads();
    }, [auth.token !== '']);

    const fetchAllThreads = async () => {
        setLoading(true);

        try {
            const {data} = await axios.get('/get-all-threads');
            setAllThreads(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    // checks for whether specific thread is selected by user and gathers all messages in that thread
    useEffect(() => {
        if (searchParams.get('thread')) {
            fetchThread();
            setThreadOpen(true);
        };
    }, [searchParams]);

    useLayoutEffect(() => {
        // Ensure the container opens up at the bottom by setting scrollTop to the maximum value
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, [currentThread]);


    const fetchThread = async () => {
        try {
            const { data } = await axios.get(`/get-single-thread/${searchParams.get('thread')}`);

            setCurrentThread(data);
            setListingId(data[0]?.listingId);
            
            // check user type
            const userType = await axios.get(`/get-user-type/${auth.user._id}`);

            if (userType?.data?.role === 'Client') {
                setRecievedBy(data[0]?.receivedBy);
            };
            if (userType?.data?.role === 'Provider') {
                setRecievedBy(data[0]?.postedBy);
            };

            const data1 = await axios.get(`/get-engagement/${searchParams.get('thread')}`);
            setEngagement(data1?.data);

            setAssessmentShared(data1?.data?.assessmentShared_status);
            setAssessmentRequested(data1?.data?.assessmentRequested_status);
            setAssessmentViewed(data1?.data?.assessmentViewed_status);
            setAssessmentCompatible(data1?.data?.assessmentCompatible_status);

            setTourRequestedStatus(data1?.data?.tourRequested_status);
            setTourOfferClientStatus(data1?.data?.tourOfferClient_status);
            setTourOfferClientSchedule(data1?.data?.tourOfferClient_schedule);
            setTourOfferProviderStatus(data1?.data?.tourOfferProvider_status);
            setTourOfferProviderSchedule(data1?.data?.tourOfferProvider_schedule);
            setTourAcceptedStatus(data1?.data?.tourAccepted_status);
            setTourAcceptedSchedule(data1?.data?.tourAccepted_schedule);
            setTourRejectedStatus(data1?.data?.tourRejected_status);
            setTourCompletedStatus(data1?.data?.tourCompleted_status);
            setTourReviewAcceptedPrivateStatus(data1?.data?.tourReviewAcceptedPrivate_status);
            setTourReviewAcceptedPrivateFeedback(data1?.data?.tourReviewAcceptedPrivate_feedback);
            setTourReviewAcceptedPublicStatus(data1?.data?.tourReviewAcceptedPublic_status);
            setTourReviewAcceptedPublicFeedback(data1?.data?.tourReviewAcceptedPublic_feedback);


            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const {data} = await axios.post('/new-message', {
                receivedBy, 
                listingId,
                message,
            });
            if(data?.error) {
                toast.error(data?.error);
                setLoading(false);
            } else {
                toast.success('Message Sent');
                setMessage('');
                setLoading(false);
                fetchThread();
                fetchAllThreads();
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong. Try again');
            setLoading(false);
        }
    };

    const takeAssessment = async () => {
        navigate("/client/assessment");
    };

    const shareAssessment = async () => {
        setLoading(true);

        try {
            const careAssessmentId = auth.user?.careAssessmentId;

            const {data} = await axios.post('/share-assessment-with-provider', {
                receivedBy, 
                listingId,
                careAssessmentId,
            });

            if(data?.error) {
                toast.error(data?.error);
                setLoading(false);
            } else {
                toast.success('Care Assessment Sent');
                fetchThread();
                fetchAllThreads();
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong. Try again');
            setLoading(false);
        }
    };

    // toggles the view for scheduling & responding to a tour
    const toggleViewTour = () => {
        setisTourFeatureOpen(!isTourFeatureOpen);
    };

    const handleTourRequestSubmit = async () => {
        const trueValuesCount = Object.values(selectedSlots).filter(value => value === true).length;

        if (trueValuesCount < 3) {
            toast.error('Please select at least 3 time slots.');

        } else {
            setLoading(true);

            try {
                const engagementId = engagement?._id;

                const { data } = await axios.post('/initiate-tour-request', {
                    engagementId,
                    selectedSlots,
                });

                if(data?.error) {
                    toast.error(data?.error);
                    setLoading(false);
                } else {
                    toast.success('Tour Request Sent to Provider');
                    fetchThread();
                    fetchAllThreads();
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong. Try again');
                setLoading(false);
            }
        }
    };

    // for formatting dates & times from client tour offer to visually render nicely to user
    function formatDateForDisplay(datetimeStr) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const dateObj = new Date(datetimeStr.replace("T", " "));
    
        const day = dateObj.getDate();
        const monthIndex = dateObj.getMonth();
        const year = dateObj.getFullYear();
        const hours24 = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const hours12 = hours24 % 12 || 12; // Convert to 12-hour format and handle the case for 12 noon/midnight
        const ampm = hours24 >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
    
        return `${monthNames[monthIndex]} ${day}, ${year} at ${formattedTime}`;
    };

    const handleSubmitTourResponse_Accept = async () => {
        if(!selectedDate) {
            toast.error('Please select a time slot offered by the Provider!');
        } else {
            setLoading(true);

            try {
                const engagementId = engagement?._id;

                const {data} = await axios.post('/accept-tour-request', {
                    engagementId,
                    selectedDate,
                });

                if(data?.error) {
                    toast.error(data?.error);
                    setLoading(false);
                } else {
                    toast.success('Tour Date & Time Accepted!');
                    fetchThread();
                    fetchAllThreads();
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong. Try again');
                setLoading(false);
            }
        }
    };

    
    const handleSubmitTourResponse_Reject = async () => {
        setLoading(true);

        try {
            const engagementId = engagement?._id;

            const {data} = await axios.post('/reject-tour-request', {
                engagementId,
            });

            if(data?.error) {
                toast.error(data?.error);
                setLoading(false);
            } else {
                toast.success('Tour Rejected.');
                fetchThread();
                fetchAllThreads();
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong. Try again');
            setLoading(false);
        }
    };

    const handleAddNewDateTime = () => {
        const key = format(newDate, "yyyy-MM-dd'T'HH:mm");
        setSelectedSlots(prevState => ({ ...prevState, [key]: true }));
    };

    const removeSelectedDate = (key) => {
        setSelectedSlots(prevState => ({ ...prevState, [key]: false }));
    };




    return (
        <div className="container">
            <div className="row">
                {isSmallScreen ? (
                    <>
                        {threadOpen ? (
                            
                                <div className="col-lg-8 col-md-8" style={{border: '1px solid black'}}>
                                    <div className="row" style={{border: '1px solid black'}}>
                                        <div className="col-4 text-left"> {/* Adjust the width and alignment as needed */}
                                            <a className="btn btn-primary text-capitalize mt-2 mb-2" onClick={handleBack}>
                                                Back
                                            </a>
                                        </div>
                                    </div>
                                    <div className="container" style={{height: 400, overflow: 'auto'}} ref={containerRef}>
                                        {currentThread?.map(currentThread => (
                                            <MessagerCardSingleThread currentThread={currentThread} key={currentThread._id}/>
                                        ))}                     
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <textarea 
                                            name='message' 
                                            className="form-control mb-2 mt-2" 
                                            placeholder="Message"
                                            value={message} 
                                            onChange={(e) => setMessage(e.target.value)}
                                            disabled={!loggedIn}
                                            style={{height: 100, overflow: 'auto'}}
                                        >
                                        </textarea>

                                        <button 
                                            className="btn btn-primary mt-2 mb-3 pointer float-right text-capitalize" 
                                            disabled={loading} 
                                            style={{height: 40, width: 120}}
                                        >
                                            {loggedIn ? (loading ? 'Please Wait' : 'Send') : ('LogIn/SignUp to Send a Message')}
                                        </button>
                                    </form>
                                    
                                    <div className="row mt-3">
                                        <div className="col">
                                                {assessmentShared ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {assessmentCompleted ? (
                                                            <a className="btn btn-primary text-capitalize" onClick={shareAssessment}>
                                                                Share Care Assessment
                                                            </a>
                                                        ) : (
                                                            <a className="btn btn-primary text-capitalize" onClick={takeAssessment}>
                                                                Complete & Share Care Assessment
                                                            </a>
                                                        )}
                                                    </>
                                                    
                                                )}
                                        </div>
                                        
                                        <div className="col">
                                            {assessmentCompatible ? (
                                                <a className="btn btn-primary text-capitalize" onClick={toggleViewTour}>
                                                    {isTourFeatureOpen ? (
                                                            <div style={{
                                                                position: 'fixed',
                                                                top: '0',
                                                                left: '0',
                                                                height: '100vh',
                                                                width: '100vw',
                                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                                                cursor: 'default',
                                                                }} onClick={toggleViewTour}>

                                                                <div
                                                                className="d-flex justify-content-center align-items-center"
                                                                style={{
                                                                width: '100%',
                                                                height: '75vh', // 50% of the viewport height
                                                                padding: '5px' // Add padding as needed
                                                                }}
                                                                >

                                                                    <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="card-header text-primary text-capitalize">Tour Request</div>

                                                                        {tourRequestedStatus ? (
                                                                            <>
                                                                                {tourAcceptedStatus ? (
                                                                                    <>
                                                                                        <div className="card-body text-secondary">
                                                                                            <p>Tour Scheduled</p>
                                                                                            {formatDateForDisplay(tourAcceptedSchedule[0])}
                                                                                        </div>
                                                                                        <div className="card-footer text-muted">
                                                                                            <p>SeniorCareHomes.org</p>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {tourOfferClientStatus ? (
                                                                                            <>
                                                                                                <div className="card-body text-secondary">
                                                                                                    <p>Waiting on Provider's Response</p>
                                                                                                </div>

                                                                                                <div className="card-footer text-muted">
                                                                                                    <p>SeniorCareHomes.org</p>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <div className="card-body text-secondary">
                                                                                                    <p>Provider's Requested Dates & Time:</p>
                                                                                                    {tourOfferProviderSchedule.map(datetime => (
                                                                                                        <div className="row mb-1">
                                                                                                            <button 
                                                                                                                key={datetime}
                                                                                                                className={`btn ${selectedDate === datetime ? 'btn-success' : 'btn-light'}`}
                                                                                                                onClick={() => setSelectedDate(datetime)}
                                                                                                            >
                                                                                                                {formatDateForDisplay(datetime)}
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>

                                                                                                <div className="card-footer text-muted">
                                                                                                    <div className="row mb-3">
                                                                                                        <button className="btn btn-success text-capitalize" onClick={handleSubmitTourResponse_Accept}>
                                                                                                            Accept
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="card-body text-secondary">
                                                                                    <h4 className="mt-2">Choose your availability</h4>
                                                                                    
                                                                                    <p>Please select 3 or more available dates & times to tour.</p>

                                                                                    <DateTimePicker 
                                                                                        onChange={setNewDate} 
                                                                                        value={newDate} 
                                                                                    />

                                                                                    <button className="btn btn-warning text-capitalize ml-2 mb-1" onClick={handleAddNewDateTime}>
                                                                                        Add
                                                                                    </button>

                                                                                    <>
                                                                                        {(Object.values(selectedSlots).filter(value => value === true).length !== 0) &&
                                                                                            <>
                                                                                                <h5 className="mt-4">Selected Times</h5>
                                                                                                <p>Click to Remove from List</p>
                                                                                                {Object.entries(selectedSlots)
                                                                                                .filter(([datetime, value]) => value === true)
                                                                                                .map(([datetime, value]) => (
                                                                                                    <div className="row mb-1" key={datetime}>
                                                                                                        <button 
                                                                                                            className='btn btn-light'
                                                                                                            onClick={() => removeSelectedDate(datetime)}
                                                                                                        >
                                                                                                            {formatDateForDisplay(datetime)}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <div className="mb-4"></div>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                </div>

                                                                                <div className="card-footer text-muted">
                                                                                    <div className="row">
                                                                                        <a className="btn btn-success" onClick={handleTourRequestSubmit}>Submit Request</a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            'Tour Request'
                                                        )
                                                    }
                                                </a>
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-lg-4 col-md-4" style={{height: 585, overflow: 'auto', border: '1px solid black'}}>
                                    {allThreads?.map(allThreads => (
                                        <MessagerCardContacts allThreads={allThreads} key={allThreads._id}/>
                                    ))}
                                </div>
                            )}
                    </>
                ) : (
                    <>
                        {/* this column displays all the unique persons user has engaged with via messaging */}
                        <div className="col-lg-4 col-md-4" style={{height: 585, overflow: 'auto', border: '1px solid black'}}>
                            {allThreads?.map(allThreads => (
                                <MessagerCardContacts allThreads={allThreads} key={allThreads._id}/>
                            ))}

                        </div>

                        {/* this column displays the unique conversation of selected user */}
                        {searchParams.get('thread') ? (
                            <div className="col-lg-8 col-md-8" style={{border: '1px solid black'}}>
                                <div className="container" style={{height: 400, overflow: 'auto'}} ref={containerRef}>

                                    {currentThread?.map(currentThread => (
                                        <MessagerCardSingleThread currentThread={currentThread} key={currentThread._id}/>
                                    ))}
                                                            
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <textarea 
                                        name='message' 
                                        className="form-control mb-2 mt-2" 
                                        placeholder="Message"
                                        value={message} 
                                        onChange={(e) => setMessage(e.target.value)}
                                        disabled={!loggedIn}
                                        style={{height: 100, overflow: 'auto'}}
                                    >
                                    </textarea>

                                    <button 
                                        className="btn btn-primary mt-2 mb-3 pointer float-right text-capitalize" 
                                        disabled={loading} 
                                        style={{height: 40, width: 120}}
                                    >
                                        {loggedIn ? (loading ? 'Please Wait' : 'Send') : ('LogIn/SignUp to Send a Message')}
                                    </button>
                                </form>
                                
                                <div className="row">
                                    <div className="col-lg-4 text-capitalize">
                                        <div className="mt-2">
                                            {assessmentShared ? (
                                                    <></>
                                            ) : (
                                                <>
                                                    {assessmentCompleted ? (
                                                        <a className="btn btn-primary text-capitalize" onClick={shareAssessment}>
                                                            Share Care Assessment
                                                        </a>
                                                    ) : (
                                                        <a className="btn btn-primary text-capitalize" onClick={takeAssessment}>
                                                            Complete & Share Care Assessment
                                                        </a>
                                                    )}
                                                </>
                                                
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 text-capitalize">
                                        <div className="mt-2">
                                            {assessmentCompatible ? (
                                                <a className="btn btn-primary text-capitalize" onClick={toggleViewTour}>
                                                    {isTourFeatureOpen ? (
                                                            <div style={{
                                                                position: 'fixed',
                                                                top: '0',
                                                                left: '0',
                                                                height: '100vh',
                                                                width: '100vw',
                                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                                                cursor: 'default',
                                                                }} onClick={toggleViewTour}>

                                                                <div
                                                                className="d-flex justify-content-center align-items-center"
                                                                style={{
                                                                    width: '100%',
                                                                    height: '75vh', // 50% of the viewport height
                                                                    padding: '5px' // Add padding as needed
                                                                }}
                                                                >

                                                                    <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="card-header text-primary text-capitalize">Tour Request</div>

                                                                        {tourRequestedStatus ? (
                                                                            <>
                                                                                {tourAcceptedStatus ? (
                                                                                    <>
                                                                                        <div className="card-body text-secondary">
                                                                                            <p>Tour Scheduled</p>
                                                                                            {formatDateForDisplay(tourAcceptedSchedule[0])}
                                                                                        </div>
                                                                                        <div className="card-footer text-muted">
                                                                                            <p>SeniorCareHomes.org</p>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {tourOfferClientStatus ? (
                                                                                            <>
                                                                                                <div className="card-body text-secondary">
                                                                                                    <p>Waiting on Provider's Response</p>
                                                                                                </div>

                                                                                                <div className="card-footer text-muted">
                                                                                                    <p>SeniorCareHomes.org</p>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {tourRejectedStatus ? (
                                                                                                    <>
                                                                                                        <div className="card-body text-secondary">
                                                                                                            <p>Tour has been rejected.</p>
                                                                                                        </div>

                                                                                                        <div className="card-footer text-muted">
                                                                                                            <p>SeniorCareHomes.org</p>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className="card-body text-secondary">
                                                                                                            <p>Provider's Requested Dates & Time:</p>
                                                                                                            {tourOfferProviderSchedule.map(datetime => (
                                                                                                                <div className="row mb-1">
                                                                                                                    <button 
                                                                                                                        key={datetime}
                                                                                                                        className={`btn ${selectedDate === datetime ? 'btn-success' : 'btn-light'}`}
                                                                                                                        onClick={() => setSelectedDate(datetime)}
                                                                                                                    >
                                                                                                                        {formatDateForDisplay(datetime)}
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>

                                                                                                        <div className="card-footer text-muted">
                                                                                                            <div className="row mb-3">
                                                                                                                <button className="btn btn-success text-capitalize mt-2" onClick={handleSubmitTourResponse_Accept}>
                                                                                                                    Accept
                                                                                                                </button>
                                                                                                                <button className="btn btn-warning text-capitalize mt-3" onClick={handleSubmitTourResponse_Reject}>
                                                                                                                    Reject
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="card-body text-secondary">
                                                                                    <h4 className="mt-2">Choose your availability</h4>
                                                                                    
                                                                                    <p>Please select 3 or more available dates & times to tour.</p>

                                                                                    <DateTimePicker 
                                                                                        onChange={setNewDate} 
                                                                                        value={newDate} 
                                                                                    />

                                                                                    <button className="btn btn-warning text-capitalize ml-2 mb-1" onClick={handleAddNewDateTime}>
                                                                                        Add
                                                                                    </button>

                                                                                    <>
                                                                                        {(Object.values(selectedSlots).filter(value => value === true).length !== 0) &&
                                                                                            <>
                                                                                                <h5 className="mt-4">Selected Times</h5>
                                                                                                <p>Click to Remove from List</p>
                                                                                                {Object.entries(selectedSlots)
                                                                                                .filter(([datetime, value]) => value === true)
                                                                                                .map(([datetime, value]) => (
                                                                                                    <div className="row mb-1" key={datetime}>
                                                                                                        <button 
                                                                                                            className='btn btn-light'
                                                                                                            onClick={() => removeSelectedDate(datetime)}
                                                                                                        >
                                                                                                            {formatDateForDisplay(datetime)}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <div className="mb-4"></div>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                </div>

                                                                                <div className="card-footer text-muted">
                                                                                    <div className="row">
                                                                                        <a className="btn btn-success" onClick={handleTourRequestSubmit}>Submit Request</a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            'Tour Request'
                                                        )
                                                    }
                                                </a>
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-8 col-md-8" style={{border: '1px solid black'}}></div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

import React, { useState } from "react";
import { Form, Input } from "antd";
import tick from "../../assets/images/tick.svg";
import { validateConfirmPassword } from "../../helpers/utilities/Validation";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function RegisterForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    let role;
    if (location.pathname === "/register/provider") {
      role = "Provider";
    } else if (location.pathname === "/register/client") {
      role = "Client";
    }

    try {
      setLoading(true);
      const { data } = await axios.post(`/pre-register`, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        role: role,
        credentialType: "custom",
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        setLoading(false);
        navigate(`/auth/verify-account/${data.token}`);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Form onFinish={handleSubmit} className="custom-form">
        <div className="flex items-center gap-[2.5rem]">
          <div className="text-left w-full">
            <label className="mb-[12px] !text-lg lgTablet:text-xl desktop:!text-2xl font-roboto-flex">
              Name
            </label>
            <Form.Item
              name="firstName"
              rules={[{ required: "true", message: "First name is required" }]}
            >
              <Input className="custom-input auth-input" />
            </Form.Item>
          </div>
        </div>

        <div className="text-left">
          <label className="!text-lg lgTablet:text-xl desktop:!text-2xl font-roboto-flex mb-[12px]">
            Email
          </label>
          <Form.Item
            name="email"
            rules={[{ required: "true", message: "Email is required" }]}
          >
            <Input className="custom-input auth-input" />
          </Form.Item>
        </div>

        <div className="text-left">
          <label className="!text-lg lgTablet:text-xl desktop:!text-2xl font-roboto-flex mb-[12px]">
            Password
          </label>
          <Form.Item
            name="password"
            rules={[{ required: "true", message: "Password is required" }]}
          >
            <Input.Password className="custom-input auth-input-password auth-input" />
          </Form.Item>
        </div>

        <div className="text-left">
          <label className="!text-lg lgTablet:text-xl desktop:!text-2xl font-roboto-flex mb-[12px]">
            Re-enter Password
          </label>
          <Form.Item
            name="confirmpassword"
            dependencies={["password"]}
            rules={[
              { required: "true", message: "Password is required" },
              validateConfirmPassword,
            ]}
          >
            <Input.Password className="custom-input-password auth-input" />
          </Form.Item>
        </div>

        {showError && (
          <div>
            <span className="p-14px text-red-500">
              Please accept terms and services
            </span>
          </div>
        )}

        <div className="mt-[40px]">
          <Form.Item>
            <button className="form-btn !w-full" type="submit">
              Register
            </button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

export default RegisterForm;

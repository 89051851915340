import { useState } from "react";
import { GOOGLE_MAPS_KEY } from "../../config";
import Streetview from "react-google-streetview";
import ShareListing from "../misc/ShareListing";
import LikeUnlike from "../misc/LikeUnlike";

import { ReactComponent as LikeIcon } from "../../assets/icons/likeIconCard.svg";
import { ReactComponent as LikedIcon } from "../../assets/icons/likedIcon.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/shareIcon.svg";

export default function StreetView({ lat, lng, width, height, ad }) {
  const StreetMapOptions = {
    position: { lat: lat, lng: lng },
    // position: { lat: 37.7749295, lng: -122.4194155 },
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
  };

  const [fav, setFav] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        style={{
          width: width,
          height: height,
          backgroundColor: "#cccccc",
        }}
        className="relative "
      >
        <Streetview
          apiKey={GOOGLE_MAPS_KEY}
          streetViewPanoramaOptions={StreetMapOptions}
        />
        <div className="flex items-center justify-end gap-2.5 absolute top-0 z-10 w-full px-2.5 py-2.5 block md:hidden">
          <div className="flex items-center gap-2.5">
            <ShareListing ad={ad} />
            <div>
              <LikeUnlike ad={ad} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useAuth } from "../context/auth";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import star from "../assets/images/star.svg";
import license from "../assets/images/license.svg";
import dollar from "../assets/images/cash.png";
import amenties from "../assets/images/amenties.svg";
import activities from "../assets/images/activities.svg";
import arrow from "../assets/images/arrow-down.png";
import arrowUp from "../assets/images/arrow-up.png";
import OwnerProfile from "../components/Listings/OwnerProfile";
import StreetView from "../components/Listings/StreetView";
import {
  capitalizeFirstLetter,
  formatKeyWithSpaces,
} from "../components/misc/utilty";
import ImageGallery from "../components/misc/imageGallery";
import { Helmet } from "react-helmet";
import { getCookie } from "./../helpers/utilities/Cookie.js";
import ShareListing from "../components/misc/ShareListing.jsx";
import LikeUnlike from "../components/misc/LikeUnlike.jsx";
import Slider from "../components/misc/slider.jsx";
import AdCardHome from "../components/cards/AdCardHome.jsx";
import { ReactComponent as LikeIcon } from "../assets/icons/likeIcon.svg";
import { ReactComponent as LikedIcon } from "../assets/icons/likedIcon.svg";
import MapContainer from "../components/misc/adViewMarkerMap.jsx";
import { ReactComponent as ShareIcon } from "../assets/icons/shareIcon.svg";

function Listings() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [auth, setAuth] = useAuth();

  const [ad, setAd] = useState();
  const [related, setRelated] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(0);

  const elementRef = useRef(null);
  const [isPastElement, setIsPastElement] = useState(false);

  //   implementation
  // hooks
  const params = useParams();

  const [listings, setListings] = useState();

  const [streetViewHeight, setStreetViewHeight] = useState("400px");

  useEffect(() => {
    if (window.innerWidth >= 768) setStreetViewHeight("400px");
    if (window.innerWidth < 768) setStreetViewHeight("290px");
    fetchAds();
  }, []);

  // To check if the user has scrolled till the owner profile section
  const handleScroll = () => {
    const element = document.getElementById("profileSection");
    if (element) {
      const rect = element.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
      setIsPastElement(isVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchAds = async () => {
    try {
      const { data } = await axios.get(`/ads`);
      setListings(data.listings);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (params?.title) {
      fetchAd();
    }
  }, [params?.title]);

  const fetchAd = async () => {
    try {
      // console.log(params.title)
      const { data } = await axios.get(`/ad/${params.title}`);

      await axios.post("/analytics/user/view-listing", {
        listingId: data?.ad?._id,
        userId: auth?.user?._id,
        visitorId: getCookie("visitorId"),
      });

      setAd(data?.ad);
      setRelated(data?.related);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const generatePhotosArray = (photos) => {
    if (photos?.length > 0) {
      const x = photos?.length === 1 ? 2 : 4;
      let arr = [];

      photos.map((p) =>
        arr.push({
          src: p.Location,
          width: x,
          height: x,
        })
      );
      return arr;
    } else {
      return null;
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ marginTop: "-10%" }}
      >
        <div className="display-4">Loading...</div>
      </div>
    );
  }

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,

          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{ad?.metaTitle}</title>
        <meta name="description" content={ad?.metaDescription} />
        <meta name="keywords" content={ad?.metaKeywords} />
      </Helmet>
      <div className=" mb-16  divide-black">
        <div className="">
          {/* Name and address for desktop */}
          <div className="py-10 space-y-3 hidden md:block max-container">
            <div>
              <h1 className="text-3xl lgTablet:text-5xl font-medium text-primaryBlack ">
                {ad.title}
              </h1>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="text-xl lgTablet:text-4xl font-normal m-0">
                  {ad.googleMap[0].city},{" "}
                  {ad.googleMap[0].administrativeLevels.level1short}
                </p>
              </div>
              <div className="flex items-center gap-10">
                <div>
                  <ShareListing ad={ad}>
                    <div className="flex items-center !gap-4">
                      <ShareIcon className="h-8 w-8 " />
                      <p className="underline m-0 text-2xl font-normal font-roboto-flex leading-none text-primaryBlack">
                        Share
                      </p>
                    </div>
                  </ShareListing>
                </div>
                <div className="">
                  <div className="">
                    <LikeUnlike ad={ad} showText>
                      {({ isLiked, handleLike, loggedIn, handleUnlike }) => (
                        <>
                          {loggedIn && isLiked && (
                            <button
                              onClick={handleUnlike}
                              className="flex items-center !gap-4 "
                            >
                              <LikedIcon />
                              <p className="underline m-0 text-2xl font-normal font-roboto-flex leading-none text-primaryBlack">
                                Unsave
                              </p>
                            </button>
                          )}
                          {(!loggedIn || !isLiked) && (
                            <button
                              onClick={handleLike}
                              className="flex items-center !gap-4"
                            >
                              <LikeIcon className="w-8 h-8 fill-black" />
                              <p className="underline m-0 text-2xl font-normal font-roboto-flex leading-none text-primaryBlack">
                                Save
                              </p>
                            </button>
                          )}
                        </>
                      )}
                    </LikeUnlike>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {ad?.photos.length > 0 ? (
            <div className="max-container max-lg:px-0">
              <ImageGallery photos={generatePhotosArray(ad?.photos)} ad={ad} />
            </div>
          ) : (
            <div className="max-container max-lg:!px-0 mb-2">
              <StreetView
                lat={ad?.location?.coordinates[1]}
                lng={ad?.location?.coordinates[0]}
                width={"100%"}
                height={streetViewHeight}
                ad={ad}
              />
            </div>
          )}
          {/* Name and Address for mobile */}
          <div className="max-container">
            <div className="!pt-5 space-y-2.5 block md:hidden">
              <div>
                <h1 className="text-lg font-normal text-primaryBlack ">
                  {ad.title}
                </h1>
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <p className="text-base font-normal m-0">{ad.address}</p>
                </div>
              </div>
            </div>
            <div className=" grid grid-cols-12 lgTablet:gap-x-20 w-full relative">
              <div className="divide-y col-span-12 lgTablet:col-span-8 flex-auto divide-black">
                <div className="!py-5 lgTablet:!py-10 w-full">
                  <div className="flex items-center gap-3">
                    <img
                      src={dollar}
                      alt="icon"
                      className="w-[2rem] sm:w-[2.3rem] lg:w-[3rem] h-[2rem] sm:h-[2.3rem] lg:h-[3rem]"
                    />

                    <div className="space-y-5">
                      <p className="text-base lgTablet:text-4xl font-medium m-0 text-primaryBlack">
                        Monthly Cost
                      </p>
                      <div>
                        <ul className="pl-2">
                          <li className="m-0 text-start">
                            <div className="flex flex-col justify-between">
                              {ad?.careCost?.actual?.sharePublic ? (
                                <p className="m-0 text-sm lgTablet:text-3xl font-normal font-roboto-flex">
                                  Confirmed: $
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })
                                    .format(
                                      ad?.careCost?.actual?.rangeLowAmount
                                    )
                                    .replace(/^(\$)/, "")}{" "}
                                  - $
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })
                                    .format(
                                      ad?.careCost?.actual?.rangeHighAmount
                                    )
                                    .replace(/^(\$)/, "")}
                                </p>
                              ) : (
                                <p className="m-0 text-sm lgTablet:text-3xl font-normal font-roboto-flex">
                                  Estimated: $
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })
                                    .format(
                                      ad?.careCost?.estimated?.rangeLowAmount
                                    )
                                    .replace(/^(\$)/, "")}{" "}
                                  - $
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })
                                    .format(
                                      ad?.careCost?.estimated?.rangeHighAmount
                                    )
                                    .replace(/^(\$)/, "")}
                                </p>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="pl-[72px]">
                    <p className=" font-roboto-flex text-sm lgTablet:text-3xl font-medium m-0">
                      Accepts Medicaid for:
                    </p>
                    <ul className="list-disc m-0">
                      {Object.keys(ad?.medicaidContract)
                        .filter((item) => ad.medicaidContract[item])
                        .map((item) => (
                          <li className="text-sm lgTablet:text-3xl capitalize font-normal font-roboto-flex">
                            {item.replace(/([A-Z])/g, " $1").trim()}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="space-y-5 !py-5 lgTablet:!py-10 w-full">
                  <div className="flex items-center w-full max-w-[400px] gap-3">
                    <img
                      src={star}
                      alt="icon"
                      className="w-[2rem] sm:w-[2.3rem] lg:w-[3rem] h-[2rem] sm:h-[2.3rem] lg:h-[3rem]"
                    />
                    <p className="text-lg lgTablet:text-4xl font-medium text-primaryBlack m-0">
                      Specialty
                    </p>
                  </div>

                  <div className="list text-start ">
                    <ul className="pl-24 list-disc m-0">
                      {Object.keys(ad?.specialty).map((item, index) => {
                        if (ad?.specialty[item] === true) {
                          return (
                            <li
                              className="font-roboto-flex text-sm lgTablet:text-3xl"
                              key={index}
                            >
                              {capitalizeFirstLetter(formatKeyWithSpaces(item))}
                            </li>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  </div>
                </div>

                <div className="space-y-5 !py-5 lgTablet:!py-10 w-full">
                  <div className="flex items-center gap-3">
                    <img
                      src={license}
                      alt="icon"
                      className="w-[2rem] sm:w-[2.3rem] lg:w-[3rem] h-[2rem] sm:h-[2.3rem] lg:h-[3rem]"
                    />
                    <p className="text-lg lgTablet:text-4xl font-medium text-primaryBlack m-0">
                      License Information
                    </p>
                  </div>

                  <div className="">
                    <ul className="list-disc pl-24 m-0 w-full">
                      <li className="font-roboto-flex text-sm lgTablet:text-3xl">
                        <a
                          href={ad?.licenseLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-roboto-flex text-sm lgTablet:text-3xl"
                        >
                          License Application Link
                        </a>
                      </li>
                      {ad?.reports?.status === "Yes" && (
                        <li className="font-roboto-flex text-sm lgTablet:text-3xl">
                          <a
                            href={ad?.reports?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-roboto-flex text-sm lgTablet:text-3xl"
                          >
                            Reports Link
                          </a>
                        </li>
                      )}
                      {ad?.reports?.status === "Yes" && (
                        <li className="font-roboto-flex text-sm lgTablet:text-3xl">
                          License Number: {ad?.licenseNumber}
                        </li>
                      )}
                      {ad?.reports?.status === "Yes" && (
                        <li className="font-roboto-flex text-sm lgTablet:text-3xl">
                          Licensed Number of Beds: {ad?.licensedBeds}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="!py-5 lgTablet:!py-10 w-full">
                  <div className="max-w-3xl w-full">
                    <p className="m-0 text-sm lgTablet:text-4xl  font-medium">
                      {" "}
                      About this home
                    </p>
                    <p className="text-sm lgTablet:text-2xl font-normal font-roboto-flex text-primaryBlack mt-[2rem]">
                      {ad?.description}
                    </p>
                  </div>
                </div>
                {ad.subscriptionType && ad.amenties && (
                  <div className="flex flex-col items-start">
                    <div
                      className="flex-justify-between h-20 w-full max-w-[400px] bg-[#fafafa] rounded-[10px] px-[16px] cursor-pointer"
                      onClick={() => (open !== 3 ? setOpen(3) : setOpen(300))}
                    >
                      <div className="flex-align-center">
                        <img
                          src={amenties}
                          alt="icon"
                          className="w-[2rem] sm:w-[2.3rem] lg:w-[3rem] h-[2rem] sm:h-[2.3rem] lg:h-[3rem]"
                        />
                        <span className="ml-[12px] p-24px text-primaryBlack">
                          Home Amenities
                        </span>
                      </div>
                      <div>
                        <img
                          src={open === 3 ? arrowUp : arrow}
                          alt="chevron"
                          className="w-5"
                        />{" "}
                      </div>
                    </div>

                    <div className="list max-w-[400px]">
                      <ul>
                        {Object.keys(ad?.amenities).map((item, index) => {
                          if (ad?.amenities[item] === true) {
                            return (
                              <li
                                className="p-16px list-disc w-full"
                                key={index}
                              >
                                {capitalizeFirstLetter(
                                  formatKeyWithSpaces(item)
                                )}
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  </div>
                )}
                {ad.subscriptionType && ad.activities && (
                  <div className="flex flex-col items-start">
                    <div
                      className="flex-justify-between h-20 w-full max-w-[400px] bg-[#fafafa] rounded-[10px] px-[16px] cursor-pointer"
                      onClick={() => (open !== 4 ? setOpen(4) : setOpen(400))}
                    >
                      <div className="flex-align-center">
                        <img
                          src={activities}
                          alt="icon"
                          className="w-[2rem] sm:w-[2.3rem] lg:w-[3rem] h-[2rem] sm:h-[2.3rem] lg:h-[3rem]"
                        />
                        <span className="ml-[12px] p-24px text-primaryBlack">
                          Home Activities
                        </span>
                      </div>
                      <div>
                        <img
                          src={open === 4 ? arrowUp : arrow}
                          alt="chevron"
                          className="w-5"
                        />{" "}
                      </div>
                    </div>
                    <div className="list max-w-[400px]">
                      <ul>
                        {Object.keys(ad?.services).map((item, index) => {
                          if (ad?.services[item] === true) {
                            return (
                              <li className="p-16px list-disc" key={index}>
                                {capitalizeFirstLetter(
                                  formatKeyWithSpaces(item)
                                )}
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="hidden col-span-4 lgTablet:flex items-start justify-start lg:justify-center py-10 sticky top-0">
                <div className={`sticky ${isPastElement ? "top-28" : "top-0"}`}>
                  <div id="profileSection">
                    <OwnerProfile ad={ad} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-container divide-y divide-black ">
          <div className="!py-5 lgTablet:!py-10 border-t border-black">
            <div className="">
              <p className="m-0 text-lg lgTablet:text-4xl  font-medium">
                Where you’ll be
              </p>

              <p className="text-sm lgTablet:text-3xl font-normal  text-primaryBlack mt-[2rem]">
                {ad?.address}
              </p>

              <MapContainer
                lat={ad?.location?.coordinates[1]}
                lng={ad?.location?.coordinates[0]}
              />
            </div>
          </div>
          <div className="!py-5 lgTablet:!py-10">
            <div className="">
              <p className="m-0 text-lg lgTablet:text-4xl  font-medium">
                Recommended homes in {ad.googleMap[0].city},
                {ad.googleMap[0].administrativeLevels.level1short}
              </p>
              <Slider
                settings={carouselSettings}
                className="w-full slider py-3"
              >
                {listings?.map((ad) => (
                  <AdCardHome ad={ad} key={ad._id} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex max-container col-span-4 lgTablet:hidden z-50 items-start justify-center"
        style={{ position: "sticky", bottom: 0 }}
      >
        <OwnerProfile ad={ad} />
      </div>
    </>
  );
}

export default Listings;

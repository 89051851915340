import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const CookieProvider = ({ children }) => {
  const [cookieSet, setCookieSet] = useState(false);

  useEffect(() => {
    // Function to set a cookie
    const setCookie = (name, value, days) => {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    };

    // Check if the visitor has a unique ID cookie
    let visitorId = document.cookie.replace(/(?:(?:^|.*;\s*)visitorId\s*\=\s*([^;]*).*$)|^.*$/, '$1');

    // If not, generate a new ID and set it in a cookie
    if (!visitorId) {
      visitorId = uuidv4().replace(/-/g, '').substring(0, 30); // Generate a random UUID and extract the first 30 characters
      setCookie('visitorId', visitorId, 730);
    }

    // Update state to indicate that the cookie has been set
    setCookieSet(true);
  }, []);

  // Render content only if the cookie has been set
  return <>{cookieSet && children}</>;
};

export default CookieProvider;

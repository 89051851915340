import {NavLink} from 'react-router-dom';

export default function Sidebar() {
    return (
        <>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink className="nav-link" to='/dashboard'>
                        Dashboard
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/user/wishlist'>
                        Wishlist
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/user/inquiries'>
                        Inquiries
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/listing/create'>
                        Create Listing
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/user/profile'>
                        Profile
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to='/user/settings'>
                        Settings
                    </NavLink>
                </li>
            </ul>
        </>
    );
}
import Resizer from "react-image-file-resizer";
import axios from 'axios';
import {Avatar} from 'antd';


export default function ImageUpload ({ad, setAd}) {
    const handleUpload = async (e) => {
        try {
            let files = e.target.files;
            files = [...files];
            if(files?.length) {
                // console.log(files);
                setAd({ ...ad, uploading: true });

                files.map((file) => {
                    new Promise(() => {
                        Resizer.imageFileResizer(
                            file,
                            1080,
                            720,
                            "JPEG",
                            100,
                            0,
                            async (uri) => {
                                try {
                                    const {data} = await axios.post('/upload-image', {
                                        image: uri,
                                    });
                                    setAd((prev) => ({
                                        ...prev,
                                        photos: [data, ...prev.photos],
                                        uploading: false
                                    }));
                                } catch (error) {
                                    console.log(error);
                                    setAd({...ad, uploading: false});
                                }
                            },
                            "base64"
                        );
                    });
                });

            }

            
        } catch (error) {
            console.log(error);
            setAd({ ...ad, uploading: false });
        }
    };

    const handleDelete = async (file) => {
        const answer = window.confirm('Delete Image?');
        if (!answer) return;
        setAd({ ...ad, uploading: true });
        try {
            const {data} = await axios.post('/remove-image', file);
            if(data?.ok) {
                setAd((prev) => ({
                    ...prev, 
                    photos: prev.photos.filter((p) => p.Key !== file.Key),
                    uploading: false,
                }));
            }
        } catch (error) {
            console.log(error);
            setAd({ ...ad, uploading: false });
        }
    };

    return (
        <>
            <label className="btn btn-warning btn-sm col-3 p-1" style={{ textTransform: "capitalize" }}>
                {ad.uploading ? "Processing.." : "Upload Listing Photos"}
                <input
                    onChange={handleUpload}
                    type="file"
                    accept="image/*"
                    multiple
                    hidden
                />
            </label>

            <div className="d-flex align-items-center position-relative">
                {ad.photos?.map((file, index) => (
                    <div key={index} className="position-relative">
                        <Avatar
                            src={file?.Location}
                            shape="square"
                            size="46"
                            className="ml-2 mb-2"
                            onClick={() => handleDelete(file)}
                        />
                        <div
                            className="delete-icon"
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                backgroundColor: "red",
                                color: "white",
                                borderRadius: "50%",
                                width: "12px",
                                height: "12px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => handleDelete(file)}
                        >
                            <span>X</span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
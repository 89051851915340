import React, { useState } from "react";
import arrowleft from "../../assets/images/arrow-left.svg";
import { Link } from "react-router-dom";
import Logo from "../../components/misc/Logo";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  isValidPhoneNumber,
  isValidEmail,
} from "../../helpers/utilities/Validation.js";
import AuthLayout from "../../components/misc/authLayout";

function ForgetPassword() {
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the input is a valid email or phone number
    if (isValidEmail(userInput) || isValidPhoneNumber(userInput)) {
      try {
        setLoading(true);

        var type;
        if (isValidEmail(userInput)) {
          type = "email";
        } else {
          type = "phone";
        }

        const { data } = await axios.post(`/forgot-password`, {
          userInput,
          type,
        });

        if (data?.error) {
          toast.error(data.error);
        } else {
          if (data.ok === true && data.type === "custom") {
            toast.success("Please check email for password reset link.");
            navigate("/");
          }
          if (data.ok === false && data.type === "custom") {
            toast.error(
              "Issue sending email link. Please contact customer service."
            );
            navigate("/contact-us");
          }
          if (data.ok === true && data.type === "google") {
            toast.success("Please sign in using your Google account");
            navigate("/login");
          }
          if (data.ok === true && data.type === "phone") {
            toast.success("Please sign in using your cell phone number");
            navigate("/login");
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(
        "Please enter a valid email(example@email.com) or 10 digit phone number(1234567890)."
      );
    }
  };

  return (
    <AuthLayout bgClass="bg-reset-left">
      <div className="!px-5 lgTablet:!px-10 desktop:!px-28 space-y-10">
        <h1 className="heading-48px text-primaryBlack">Forgot Password</h1>

        <form onSubmit={handleSubmit}>
          <div className="mt-10">
            <div className="text-left">
              <label
                htmlFor="#userInput"
                className="!text-lg lgTablet:!text-2xl font-roboto-flex"
              >
                Enter your email or phone number
              </label>
              <input
                type="text"
                id="userInput"
                name="userInput"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                className="custom-input auth-input"
                style={{ width: "100%" }}
              />
            </div>

            <div className="mt-[36px] lgTablet:mt-16">
              <button
                className="form-btn !w-full"
                type="submit"
                disabled={loading}
              >
                {loading ? "Resetting..." : "Reset Password"}
              </button>
            </div>

            <div className="mt-[24px] lgTablet:mt-[30px] flex-align-center justify-center">
              <Link
                to="/login"
                className="no-underline hover:no-underline flex-align-center"
              >
                {" "}
                <div className="mr-[16px] w-[20px] h-[12px]">
                  <img src={arrowleft} alt="arrow" className="w-full h-full" />
                </div>
                <span className="p-20px text-lightBrown">Back to log in</span>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default ForgetPassword;

import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import Sidebar from "../../components/nav/Sidebar";


export default function Settings() {

    // state
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState('');

    // hook
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const {data} = await axios.put('/update-password', {
                password,
            });
            if(data?.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Password Updated");
                navigate("/dashboard");
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <>
            <h1 className='display-4 bg-primary text-light p-2'>My Settings</h1>
            <div className='container-fluid'>
                <Sidebar />
                <div className='container mt-2'>
                    {/* <pre>{JSON.stringify({username, name, email, company, address, phone, about, photo})}</pre> */}

                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2 mt-2'>
                            <form onSubmit={handleSubmit}>
                                <input 
                                    type='password' 
                                    placeholder='Enter New Password' 
                                    className='form-control mb-4' 
                                    value={password}
                                    onChange={e => setPassword(e.target.value)} 
                                />
                                <button className='btn btn-primary col-12 mb-4' disabled={loading}>
                                    {loading ? 'Processing' : 'Update Password'}
                                </button>

                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}


import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function ProviderRedirectRoute () {
    const [count, setCount] = useState(3);

    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((currentCount) => --currentCount);
        }, 1000);
        
        // redirect once count is equal to 0
        count === 0 && navigate("/");

        // cleanup
        return() => clearInterval(interval);
    }, [count]);

    return (
        <div className='d-flex justify-content-center align-items-center vh-100'>
            <h2>Please login as a Provider to view this page. Redirecting in {count}..</h2>
        </div>
    );
}
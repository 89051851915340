import { useSearch } from "../../context/search";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_PLACES_KEY } from "../../config";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import searchIcon from "../../assets/images/search-icon.svg";

export default function SearchFormHomepage() {
  // context
  const [search, setSearch] = useSearch();

  // hooks
  const navigate = useNavigate();

  const handleSearch = async (value) => {
    try {
      const { address, results, page, ...rest } = search;

      if (value === "") {
        toast.error("Please enter a location");
      } else {
        const response = await axios.post(`/geocode/decode-address`, {
          address: value,
        });

        const state = response.data.data?.state
          ?.toLowerCase()
          ?.replaceAll(" ", "-");
        const county = response.data.data?.county
          ?.toLowerCase()
          ?.replaceAll(" ", "-");
        const city = response.data.data?.city
          ?.toLowerCase()
          ?.replaceAll(" ", "-");
        const type = "adult-family-home";

        let path = "/listings";
        path = `${path}/${type}`;

        // add state
        if (state) {
          path = `${path}/${state}`;
          // add county
          if (county) {
            path = `${path}/${county}`;
            // add city
            if (city) path = `${path}/${city}`;
          }
        }
        // add end slash
        path = `${path}`;

        navigate(path);
      }
    } catch (error) {
      console.log(error);
      setSearch({ ...search, loading: false });
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="searchbar relative ">
          <div className="input-autocomplete cursor-pointer">
            <GooglePlacesAutocomplete
              apiKey={GOOGLE_PLACES_KEY}
              apiOptions="us"
              selectProps={{
                placeholder: "Enter address, city, or zipcode",
                onChange: ({ value }) => {
                  setSearch((prevSearch) => ({
                    ...prevSearch,
                    address: value.description,
                  }));
                  handleSearch(value.description);
                },
              }}
              className="bg-black "
            />
          </div>
          <div
            className="img-btn absolute top-[50%] -translate-y-1/2 right-5 cursor-pointer"
            onClick={handleSearch}
          >
            <img src={searchIcon} alt="icon" />
          </div>
        </div>
      </div>
    </>
  );
}

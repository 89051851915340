import {useState, useEffect} from 'react';
import {useAuth} from "../../context/auth";
import axios from 'axios';



export default function ClientDashboard() {
    const [auth, setAuth] = useAuth();

    const [ads, setAds] = useState();
    const [total, setTotal] = useState();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    

    return (
        <div>
            <div className="container-lg d-flex justify-content-center">
                <div className="row">
                    <div className="col-lg-12 mt-3">
                        <p> Action Items</p>
                    </div>
                </div>
            </div>
        </div>
    );
}


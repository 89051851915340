import { useState, useEffect } from 'react';
import {useAuth} from '../../context/auth';
import axios from 'axios';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import ProfileUpload from "../../components/forms/ProfileUpload";


export default function ProviderProfile() {

    // context
    const [auth, setAuth] = useAuth();

    // state
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState('');
    const [photo, setPhoto] = useState('');
    const [uploading, setUploading] = useState('');

    // hook
    const navigate = useNavigate();

    useEffect(() => {
        if(auth.user) {
            setUsername(auth.user?.username);
            setFirstName(auth.user?.firstName)
            setLastName(auth.user?.lastName);
            setEmail(auth.user?.email);
            setPhone(auth.user?.phone);
            setPhoto(auth.user?.photo);
            
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const {data} = await axios.put('/update-profile', {
                username,
                firstName,
                lastName,
                email,
                phone,
                photo,
            });
            if(data?.error) {
                toast.error(data.error)
            } else {
                setAuth({...auth, user: data});

                let fromLS = JSON.parse(localStorage.getItem('auth'));
                fromLS.user = data;
                localStorage.setItem('auth', JSON.stringify(fromLS));
                setLoading(false);
                toast.success("Profile Updated");
                // navigate("/provider/dashboard");
            }
        } catch (error) {
            console.log(error);
        }
    };
    

    const formatPhoneNumber = (input) => {
        // Remove all non-digit characters
        var cleaned = ('' + input).replace(/\D/g, '');
    
        // Limit the input to 10 digits
        cleaned = cleaned.substring(0, 10);
    
        // Check for a valid 10-digit US phone number format
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})/);
    
        if (match) {
            return '(' + match[1] + ')' + match[2] + '-' + match[3];
        }
    
        return null;
    };
    
    
    


    return (
        <div>
            <div className="container text-center mt-5 mb-5">
                <h3 className="display-4 text-dark">Profile</h3>
            </div>

            <div className="container justify-content-center">
                <div className="row justify-content-center">
                    <div className="col col-lg-9 col-md-9">
                            <div className='mb-4'>
                                <label htmlFor="pP">Profile Picture</label>
                                <ProfileUpload 
                                    photo={photo}
                                    id="pP" 
                                    setPhoto={setPhoto}
                                    uploading={uploading}
                                    setUploading={setUploading}
                                />
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className='mb-4'>
                                    <label htmlFor="fN">First Name</label>
                                    <input 
                                        type='text' 
                                        id="fN" 
                                        placeholder='First Name' 
                                        className='form-control mb-4' 
                                        value={firstName}
                                        disabled={true} 
                                    />
                                </div>

                                <div className='mb-4'>
                                    <label htmlFor="lN">Last Name</label>
                                    <input 
                                        type='text' 
                                        id="lN" 
                                        placeholder='Last Name' 
                                        className='form-control mb-4' 
                                        value={lastName}
                                        disabled={true}
                                    />
                                </div>

                                <div className='mb-4'>
                                    <label htmlFor="mail">Email Address</label>
                                    <input 
                                        type='email' 
                                        id="mail"
                                        className='form-control mb-4' 
                                        value={email}
                                        disabled={true}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="cell">Cell Phone Number</label>
                                    <input
                                        type="text"
                                        id="cell"
                                        placeholder="Cellphone Number"
                                        className="form-control mb-4"
                                        value={phone}
                                        disabled={true} 
                                        onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
                                    />
                                </div>

                                <div className='d-flex justify-content-center mb-5'>
                                    <button className="btn btn-primary btn-sm col-4 p-1" style={{ textTransform: "capitalize" }} disabled={loading}>
                                        {loading ? 'Processing' : 'Update Profile'}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
    );
}
// importing npm modules
import axios from "axios";
import { format } from "date-fns";
import slugify from "slugify";
// importing react hooks and libraries
import { useState, useEffect } from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
// importing components
import CurrentAssessmentForm from "../../components/forms/CurrentAssessmentForm";
import Admin_CreateAssessmentForm from '../../components/forms/Admin_CreateAssessmentForm';

// -------------------------

export default function MatchAssessments() {
  // --- state ---

  const [assessments, setAssessments] = useState([]);
  const [listings, setListings] = useState([]);
  const [viewAssessment, setViewAssessment] = useState(null);
  const [currentAssessment, setCurrentAssessment] = useState(null);
  const [newAssessment, setNewAssessment] = useState(null);
  const [isLoadingAssessments, setIsLoadingAssessments] = useState(false);
  const [isLoadingListings, setIsLoadingListings] = useState(false);

  // --- functions ---

  const getAssessments = async () => {
    // remove previous results
    setAssessments([]);
    // show loading
    setIsLoadingAssessments(true);
    // make the request
    const { data } = await axios.get(`/assessments/valid-assessments/get-all`);
    // set state if successful
    if (data.success) {
      // sort by crated time
      data.data.allValidAssessmentsWithUserDetails.sort(
        (a, b) => new Date(a.assessment.createdAt).getTime() - new Date(b.assessment.createdAt).getTime()
      );
      // set to state
      setAssessments(data.data.allValidAssessmentsWithUserDetails);
    }
    // hide loading
    setIsLoadingAssessments(false);
  };

  const getListings = async () => {
    // remove previous results
    setListings([]);
    // show loading
    setIsLoadingListings(true);
    // currentAssessment not set
    if (!currentAssessment) {
      setListings([]);
      return;
    }
    // currentAssessment is set
    const query = queryString.stringify({
      type: "adult-family-home",
      state: currentAssessment.googleMap[0].administrativeLevels.level1long,
      county: currentAssessment.googleMap[0].administrativeLevels.level2long,
      city: currentAssessment.googleMap[0].city,
      // specialty,
      // medicaid,
      range: "5 miles",
      // visitorId,
      // userId,
    });
    // make the query
    const { data } = await axios.get(`/listings/search/by-address-and-type?${query}`);
    // set data to state
    if (data.success) setListings(data.data.searchResults);
    // hide loading
    setIsLoadingListings(false);
  };

  const showViewAssessment = (assessment) => {
    setViewAssessment(assessment);
    // console.log(`showAssessment(${assessment._id})`);
  };
  const hideViewAssessment = () => {
    setViewAssessment(null);
  };

  const showNewAssessment = () => {
    setNewAssessment(true);
  };
  const hideNewAssessment = () => {
    setNewAssessment(null);
  };

  // --- business logic ---

  useEffect(() => {
    getAssessments();
  }, []);

  useEffect(() => {
    if (currentAssessment) getListings();
  }, [currentAssessment]);

  // --- template ---

  return (
    <>
      {/* page title */}
      <h1 className="p-4">
        Match Clients' assessments to Providers' listings 
      </h1>

      {/* page content */}
      <div className="flex flex-row">
        {/* clients */}
        <div className="basis-2/5 py-4 pl-4">
        <div className="header-wrapper" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 className="mb-4">
            Clients' assessments ({assessments.length})
          </h2>
          <button type="button" className="btn btn-primary" onClick={() => showNewAssessment()}>
            Create Assessment
          </button>
        </div>

          <div className="pr-4 border-r border-red-900 h-[70vh] overflow-scroll">
            {isLoadingAssessments ? (
              <span>loading ...</span>
            ) : (
              assessments.map((assessment) => {
                return (
                  // assessment card
                  <div className="p-2 mb-2 border border-black" key={assessment.assessment._id}>
                    {/* header */}
                    <div className="flex flex-row justify-between mb-2">
                      <span className="text-lg font-bold">
                        {assessment.userDetails.firstName} {assessment.userDetails.lastName}
                      </span>
                      {/*  */}
                      <div className="flex flex-row gap-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setCurrentAssessment(assessment.assessment)}
                        >
                          Select {/* Providers */}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => showViewAssessment(assessment.assessment)}
                        >
                          View Assessment{/*Assessment */}
                        </button>
                      </div>
                    </div>

                    {/* details */}
                    <div>
                      <p className="mb-0">
                        started: {format(new Date(assessment.assessment.createdAt), "dd MMM yyyy")}
                      </p>
                      <p className="mb-0">
                        last updated: {format(new Date(assessment.assessment.updatedAt), "dd MMM yyyy")}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {/* providers */}
        <div className="basis-3/5 p-4">
          <h2 className="mb-4">Providers' listings ({listings.length})</h2>
          <div className="h-[70vh] overflow-scroll">
            {isLoadingListings ? (
              <span>loading ...</span>
            ) : (
              listings.map((listing) => {
                return (
                  // listing card
                  <div className="p-2 mb-2 border border-black" key={listing._id}>
                    {/* header */}
                    <div className="flex flex-row justify-between mb-2">
                      <span className="text-lg font-bold">{listing.title}</span>
                      {/* actions */}
                      <div className="flex flex-row gap-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            // onClick={}
                          >
                            Send SMS
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            // onClick={}
                          >
                            Send Email
                          </button>
                        </div>
                        <Link
                          className="btn btn-primary"
                          to={`/listings/${listing.slug.type}/${listing.slug.state}/${listing.slug.county}/${listing.slug.city}/${listing.slug.title}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Listing {/* Listing */}
                        </Link>
                      </div>
                    </div>
                    {/* details */}
                    <div>
                      <p className="mb-0">address: {listing.address}</p>
                      <p className="mb-0">owner: {listing.ownerFirstName} {listing.ownerLastName}</p>
                      <p className="mb-0">
                        phone: 
                        <a href={`tel:${listing.phoneNumber}`} style={{ marginLeft: '5px' }}>
                          {listing.phoneNumber}
                        </a>
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {/* dynamic content */}
      {newAssessment && (
        <>
          <div style={{
              position: 'fixed',
              top: '0',
              left: '0',
              height: '100vh',
              width: '100vw',
              backgroundColor: 'rgba(0,0,0,0.7)',
              cursor: 'default',
          }} onClick={() => hideNewAssessment()}>

              <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                  width: '70%',
                  height: '75vh', // 50% of the viewport height
                  margin: '15vh 15vw', // 25% offset from top and left
                  padding: '20px' // Add padding as needed
              }}
              >

                  <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                      <div className="card-header text-primary">New Care Assessment</div>
                          <div className="card-body" style={{ overflowY: 'auto', maxHeight: 'calc(50vh - 80px)' }}>
                              <Admin_CreateAssessmentForm />
                          </div>
                      <div className="card-footer text-muted">SeniorCareHomes.Org</div>
                  </div>
              </div>
          </div>
        </>
      )}

      {viewAssessment && (
        <>
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0,0,0,0.7)",
              cursor: "default",
            }}
            onClick={() => hideViewAssessment()}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "70%",
                height: "75vh", // 50% of the viewport height
                margin: "15vh 15vw", // 25% offset from top and left
                padding: "20px", // Add padding as needed
              }}
            >
              <div className="card text-center" onClick={(e) => e.stopPropagation()}>
                <div className="card-header text-primary">View Care Assessment</div>
                <div className="card-body" style={{ overflowY: "auto", maxHeight: "calc(50vh - 80px)" }}>
                  <CurrentAssessmentForm assessment={viewAssessment} />
                </div>
                <div className="card-footer text-muted">SeniorCareHomes.Org</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import '../styles/map.css';
import { getCookie } from './../helpers/utilities/Cookie.js';
import { useAuth } from "../context/auth.js";
import { Helmet } from "react-helmet";
import Logo from "../components/misc/Logo";

function Unsubscribe() {
    // scroll to top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [validLink, setValidLink] = useState();
    const [alreadyUnsubscribed, setAlreadyUnsubscribed] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Update parameters based on the URL
        const userID = searchParams.getAll("uid");
        const emailID = searchParams.getAll("mid");

        // Check if unsubscribe request is legit
        unsubRequest( userID, emailID );
    }, [searchParams]);

    const unsubRequest = async (userID, emailID) => {
        setLoading(true);
        try {
            const visitorId = getCookie('visitorId');

            const { data } = await axios.post(`/users/unsubscribe/email`, {
                userID,
                emailID,
                visitorId,
            });

            if (data?.success === true) {
                setValidLink(true);
                setAlreadyUnsubscribed(false);
                setLoading(false);
            } else if (data?.success === false) {
                if (data?.messages?.content === "unsubscribed") {
                    setValidLink(true);
                    setAlreadyUnsubscribed(true);
                };
                
                if (data?.messages?.content === "badLink") {
                    setValidLink(false);
                };
                
                setLoading(false);
            } else {
                console.log(data.error);
                setLoading(false);
            };
        } catch (error) {
        console.error(error);
        setLoading(false);
        };
    };

  return (
    <>
        <Helmet>
            <title>Unsubscribe</title>
            <meta name="description" content="Sign up as a Client seeking care at SeniorCareHomes.Org" />
        </Helmet>
        <div className="flex justify-between">
            <div className="w-full max-w-lg lgTablet:max-w-full mx-auto lgTablet:w-1/2 px-[1rem] sm:px-[1.5rem] lg:px-[4rem] 2xl:px-[8%] py-12 txt-left">
                <div className="block lgTablet:mr-auto mb-[3rem] d-flex justify-content-center">
                    <Logo />
                </div>

                <h1 className="heading-48px text-primaryBlack mt-[3rem] d-flex justify-content-center">
                    Unsubscribe
                </h1>

                {loading && (
                    <div className="mt-10 d-flex justify-content-center">
                        Loading
                    </div>
                )}

                {validLink && !alreadyUnsubscribed && (
                    <div className="mt-10 d-flex justify-content-center">
                        You have been successfully unsubscribed from all email messages!
                    </div>
                )}

                {validLink && alreadyUnsubscribed && (
                    <div className="mt-10 d-flex justify-content-center">
                        You are already unsubscribed from all email messages!
                    </div>
                )}

                {!validLink && (
                    <div className="mt-10 d-flex justify-content-center">
                        Bad link. Please reach out to Customer Support for further help.
                    </div>
                )}
                

            </div>
        </div>
    </>
  );
}

export default Unsubscribe;

import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "../../assets/images/left.png";
import right from "../../assets/images/right.png";

export default function Slider({ settings, children }) {
  return (
    <SlickSlider
      {...settings}
      prevArrow={<CustomArrow direction="prev" />}
      nextArrow={<CustomArrow direction="next" />}
      className="w-full slider py-3"
    >
      {children}
    </SlickSlider>
  );
}

const CustomArrow = ({ onClick, direction }) => (
  <div
  className={`custom-arrow custom-${direction} w-12 h-12 p-3 rounded-full flex justify-center items-center bg-[#d4e7dc] cursor-pointer`}
    onClick={onClick}
  >
    {direction === "prev" ? (
      <img src={left} alt="left" className="w-2" />
    ) : (
      <img src={right} alt="right" className="w-2" />
    )}
  </div>
);

import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";
import Logo from "../misc/Logo";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";

export default function Footer() {
  // context
  const [auth, setAuth] = useAuth();

  const location = useLocation();
  const [providerDashboard, setProviderDashboard] = useState(false);
  const [clientDashboard, setClientDashboard] = useState(false);
  const [adminDashboard, setAdminDashboard] = useState(false);
  const [publicSite, setPublicSite] = useState(false);

  const loggedIn =
    auth.user !== null && auth.token !== "" && auth.refreshToken !== "";
  const role = auth?.user?.role;
  let url;
  switch (role) {
    case "Client":
      url = "/client/inbox";
      break;
    case "Provider":
      url = "/provider/inbox";
      break;
    case "Agent":
      url = "/agent/inbox";
      break;
    case "Admin":
      url = "/admin/manage-providers";
      break;
    default:
      url = "/inbox";
      break;
  }

  const isDashboard = () => {
    const path = location.pathname;
    if (path.startsWith("/provider")) {
      setProviderDashboard(true);
      setClientDashboard(false);
      setAdminDashboard(false);
      setPublicSite(false);
    } else if (path.startsWith("/client")) {
      setProviderDashboard(false);
      setClientDashboard(true);
      setAdminDashboard(false);
      setPublicSite(false);
    } else if (path.startsWith("/admin")) {
      setProviderDashboard(false);
      setClientDashboard(false);
      setAdminDashboard(true);
      setPublicSite(false);
    } else {
      setProviderDashboard(false);
      setClientDashboard(false);
      setAdminDashboard(false);
      setPublicSite(true);
    }
  };

  useEffect(() => {
    isDashboard(); // Call the function whenever the location changes
  }, [location.pathname]); // Dependency on location.pathname ensures it runs on URL changes

  return (
    <>
      {publicSite && (
        <footer className="bg-bgCard">
          <div className="max-container flex !gap-5 flex-col md:flex-row items-center justify-between py-7">
            <Logo />
            <div className="flex items-center !gap-5 md:gap-10 flex-col md:flex-row">
              <div className="menu ">
                <ul className="flex p-0 items-center m-0 gap-x-6 lg:gap-x-10 xl:gap-x-14 2xl:gap-x-[60px]">
                  <Link
                    to="/policies/terms-of-use"
                    className="no-underline hover:no-underline"
                  >
                    <li className="text-sm md:text-2xl font-roboto-flex text-primaryBlack">
                      Terms of Use
                    </li>{" "}
                  </Link>
                  <Link
                    to="/policies/privacy-policy"
                    className="no-underline hover:no-underline"
                  >
                    <li className="text-sm md:text-2xl font-roboto-flex text-primaryBlack">
                      Privacy Policy
                    </li>{" "}
                  </Link>
                  <Link
                    to="/contact-us"
                    className="no-underline hover:no-underline"
                  >
                    <li className="text-sm md:text-2xl font-roboto-flex text-primaryBlack">
                      Contact us
                    </li>{" "}
                  </Link>
                </ul>
              </div>
              <div className="social-icon ">
                <ul className="flex items-center p-0 m-0 gap-x-4 lg:gap-x-6 xl:gap-x-8">
                  <li>
                    <a
                      href="https://www.facebook.com/people/Senior-Care-Homes/61551814229973/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={facebook}
                        alt="Facebook"
                        className="h-10 w-10"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/seniorcarehomes-org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={linkedin}
                        alt="LinkedIn"
                        className="h-10 w-10"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      )}

      {providerDashboard && (
        <footer className="bg-slate-800">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center mt-2">
              <Logo light={true} />
            </div>
            <div className="social-icon">
              <ul className="flex items-center gap-x-4 lg:gap-x-6 xl:gap-x-8 mt-4">
                <li>
                  <a
                    href="https://www.facebook.com/people/Senior-Care-Homes/61551814229973/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/seniorcarehomes-org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="LinkedIn" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      )}

      {clientDashboard && (
        <footer className="bg-slate-800">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center mt-2">
              <Logo light={true} />
            </div>
            <div className="social-icon">
              <ul className="flex items-center gap-x-4 lg:gap-x-6 xl:gap-x-8 mt-4">
                <li>
                  <a
                    href="https://www.facebook.com/people/Senior-Care-Homes/61551814229973/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/seniorcarehomes-org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="LinkedIn" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      )}

      {adminDashboard && (
        <footer className="bg-red-900">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center mt-2">
              <Logo light={true} />
            </div>
            <div className="social-icon">
              <ul className="flex items-center gap-x-4 lg:gap-x-6 xl:gap-x-8 mt-4">
                <li>
                  <a
                    href="https://www.facebook.com/people/Senior-Care-Homes/61551814229973/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/seniorcarehomes-org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="LinkedIn" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Checkbox, Dropdown, Radio } from "antd";
import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import chevronDown from "../../assets/images/chevron-down.svg";
import {
  medicaidFilter,
  specialtyFilter,
  rangeFilter,
  budgetFilter,
} from "../filters/Filters";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_PLACES_KEY } from "../../config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/utilities/LocalStorage.js";

function SearchForm({ setSearchParams, setSearch, search, open, setOpen, recommendedFilterActive, recommendedSelected, recommendedUrl }) {
  const navigate = useNavigate();
  const [insideFilter, setInsideFilter] = useState(false);

  const clear = () => {
    setSearch({
      ...search,
      medicaid: null,
      specialty: null,
      budget: null,
      range: null,
    });
  };

  useEffect(() => {
    const params = {};

    if (search.range) {
      params.range = search.range;
    }

    if (search.budget) {
      params.budget = search.budget;
    }

    if (search.specialty) {
      params.specialty = search.specialty;
    }

    if (search.medicaid) {
      params.medicaid = search.medicaid;
    }

    setSearchParams(params);
  }, [search]);

  const medicaid = [
    {
      key: "checkboxes",
      label: (
        <Checkbox.Group
          options={medicaidFilter}
          className="dropdwn checkbox"
          value={search.medicaid}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onChange={(selectedValues) =>
            setSearch({
              ...search,
              medicaid: selectedValues,
            })
          }
        />
      ),
    },
  ];

  const specialty = [
    {
      key: "checkboxes",
      label: (
        <Checkbox.Group
          options={specialtyFilter}
          className="dropdwn checkbox"
          value={search.specialty}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onChange={(selectedValues) =>
            setSearch({
              ...search,
              specialty: selectedValues,
            })
          }
        />
      ),
    },
  ];

  const budget = [
    {
      key: "checkboxes",
      label: (
        <Radio.Group
          options={budgetFilter}
          className="dropdwn checkbox"
          value={search.budget}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onChange={(val) =>
            setSearch({
              ...search,
              budget: val.target.value,
            })
          }
        />
      ),
    },
  ];

  const range = [
    {
      key: "checkboxes",
      label: (
        <Radio.Group
          options={rangeFilter}
          className="dropdwn checkbox"
          value={search.range}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onChange={(val) =>
            setSearch({
              ...search,
              range: val.target.value,
            })
          }
        />
      ),
    },
  ];

  const handleSearch = async (value) => {
    try {
      const { address, results, page, ...rest } = search;

      if (value === "") {
        toast.error("Please enter a location");
      } else {
        const response = await axios.post(`/geocode/decode-address`, {
          address: value,
        });

        const state = response.data.data?.state
          ?.toLowerCase()
          ?.replaceAll(" ", "-");
        const county = response.data.data?.county
          ?.toLowerCase()
          ?.replaceAll(" ", "-");
        const city = response.data.data?.city
          ?.toLowerCase()
          ?.replaceAll(" ", "-");
        const type = "adult-family-home";

        let path = "/listings";
        path = `${path}/${type}`;

        // add state
        if (state) {
          path = `${path}/${state}`;
          // add county
          if (county) {
            path = `${path}/${county}`;
            // add city
            if (city) path = `${path}/${city}`;
          }
        }
        // add end slash
        path = `${path}`;

        navigate(path);
      }
    } catch (error) {
      console.log(error);
      setSearch({ ...search, loading: false });
    }
  };

  const navigateToRecommendations = () => {
    navigate(recommendedUrl);
  }

  return (
    <>
      <div className="flex flex-col desktop:flex-row !gap-5">
        <div className="flex-1 w-full hidden lgTablet:block">
          <div className=" flex items-center border-[1px] border-borderGray rounded-full py-[0.5rem] px-[1rem] ">
            <div className="input-searchPage w-full">
              <GooglePlacesAutocomplete
                style={{ width: "100%" }}
                apiKey={GOOGLE_PLACES_KEY}
                apiOptions="us"
                selectProps={{
                  placeholder: "Enter address, city, or zipcode",
                  onChange: ({ value }) => {
                    setSearch((prevSearch) => ({
                      ...prevSearch,
                      address: value.description,
                    }));
                    handleSearch(value.description);
                  },
                }}
              />
            </div>
            <SearchIcon />
          </div>
        </div>

        <div className="max-lgTablet:fixed max-lgTablet:z-50 max-lgTablet:bg-bgCard left-0 max-lgTablet:px-2 max-lgTablet:w-full max-lgTablet:py-1.5 -mt-2">
          <div className="flex py-1.5  lgTablet:grid lgTablet:grid-cols-3 w-full max-lgTablet:max-w-4xl overflow-x-scroll lgTablet:overflow-hidden !gap-5">
            <Dropdown
              menu={{
                items: specialty,
              }}
              placement="bottomLeft"
              arrow={false}
              className="mr-10px "
              open={open === 1}
              overlayStyle={{ width: "300px", borderRadius: "30px" }}
            >
              <button
                className="border-[1px] border-borderGray rounded-full py-[0.5rem] px-[1rem] cursor-pointer  text-primaryBlack"
                onClick={() => setOpen((prev) => (prev === 1 ? 0 : 1))}
              >
                Specialty
              </button>
            </Dropdown>

            <Dropdown
              menu={{
                items: medicaid,
              }}
              placement="bottomLeft"
              arrow={false}
              className="mr-10px "
              open={open === 2}
              overlayStyle={{ width: "300px", borderRadius: "30px" }}
            >
              <button
                className="border-[1px] border-borderGray rounded-full py-[0.5rem] px-[1rem] cursor-pointer  text-primaryBlack"
                onClick={() => setOpen((prev) => (prev === 2 ? 0 : 2))}
              >
                Medicaid
              </button>
            </Dropdown>

            <Dropdown
              menu={{
                items: budget,
              }}
              placement="bottomLeft"
              arrow={false}
              className="mr-10px "
              open={open === 3}
              overlayStyle={{ width: "300px", borderRadius: "30px" }}
            >
              <button
                className=" border-[1px] border-borderGray rounded-full py-[0.5rem] px-[1rem] cursor-pointer  text-primaryBlack"
                onClick={() => setOpen((prev) => (prev === 3 ? 0 : 3))}
              >
                Budget
              </button>
            </Dropdown>

            <Dropdown
              menu={{
                items: range,
              }}
              placement="bottomLeft"
              arrow={false}
              className="mr-10px "
              open={open === 4}
              overlayStyle={{ width: "300px", borderRadius: "30px" }}
            >
              <button
                className=" border-[1px] border-borderGray rounded-full py-[0.5rem] px-[1rem] cursor-pointer  text-primaryBlack"
                onClick={() => setOpen((prev) => (prev === 4 ? 0 : 4))}
              >
                Distance
              </button>
            </Dropdown>
            {recommendedFilterActive && 
              <button 
                className={recommendedSelected ? "border-[1px] border-borderGray rounded-full py-[0.5rem] px-[1rem] cursor-pointer text-white bg-[#216017]" : "border-[1px] border-borderGray rounded-full py-[0.5rem] px-[1rem] cursor-pointer text-primaryBlack"} 
                onClick={navigateToRecommendations}
              >
                Recommended
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchForm;

export default function AnchorCard({ heading, para, image, reverse }) {
  return (
    <div
      className={`flex flex-col-reverse lgTablet:flex-row bg-bgCard shadow-anchor rounded-2xl.5 overflow-hidden lgTablet:max-h-[350px] border-[3px] border-bgPink lgTablet:border-none ${
        reverse && "lgTablet:flex-row-reverse"
      }`}
    >
      <div className="w-full !p-5 lgTablet:!p-10  grow shrink basis-1/6 flex flex-col justify-center">
        <h3 className=" text-lg lgTablet:text-2xl desktop:text-4xl text-primaryBlack">
          {heading}
        </h3>
        <p className="text-base lgTablet:text-lg desktop:text-2xl font-normal text-primaryBlack mt-[1.2rem]">
          {para}
        </p>
      </div>
      <div className="w-full max-h-52 lgTablet:max-h-none grow shrink basis-0">
        <img src={image} alt="anchor" className="w-full h-full object-cover" />
      </div>
    </div>
  );
}

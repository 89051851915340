import React, { useState } from "react";
import { Form, Input } from "antd";
import arrowright from "../../assets/images/arrow-right.svg";
import arrowleft from "../../assets/images/arrow-left.svg";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import RecaptchaForm from "../auth/RecaptchaForm";
import { useAuth } from "../../context/auth";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../helpers/utilities/Cookie";
import {
  isValidPhoneNumber,
  isValidEmail,
} from "../../helpers/utilities/Validation.js";

function LoginForm() {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [recaptchaPass, setRecaptchaPass] = useState(false);
  const [loginVerifyStage, setLoginVerifyStage] = useState(false);
  const [loginType, setLoginType] = useState(null);
  const [loginContact, setLoginContact] = useState(null);

  const preVerifyStageSubmit = async (values) => {
    setLoading(true);
    if (isValidEmail(values.login) || isValidPhoneNumber(values.login)) {
      setLoginContact(values.login);

      if (isValidEmail(values.login)) {
        setLoginType("email");

        const { data } = await axios.post(`/verify-email-tx`, {
          email: values.login,
        });

        if (data?.error) {
          toast.error(data.error);
        } else {
          setLoginVerifyStage(true);
        }
      } else {
        setLoginType("phone");

        const { data } = await axios.post(`/verify-phone-number-tx`, {
          phone: values.login,
        });

        if (data?.error) {
          toast.error(data.error);
        } else {
          setLoginVerifyStage(true);
        }
      }
    } else {
      toast.error(
        "Please enter a valid email(example@email.com) or 10 digit phone number(1234567890)."
      );
    }
    setLoading(false);
  };

  const loginSubmit = async (values) => {
    if (loginType === "email") {
      if (!recaptchaPass) {
        return;
      }
      try {
        setLoading(true);
        const { data } = await axios.post(`/login`, {
          credentialType: "custom",
          email: loginContact,
          password: values.password,
          visitorId: getCookie("visitorId"),
        });

        if (data?.error) {
          toast.error(data.error);
          setLoading(false);
        } else {
          setAuth(data);
          localStorage.setItem("auth", JSON.stringify(data));
          toast.success("Login Successful.");
          setLoading(false);

          if (data?.user?.role === "Client") {
            location?.state !== null
                ? navigate(location.state)
                : navigate("/client/engagements");
          }

          if (data?.user?.role === "Provider") {
            if (
              data?.user?.identity?.googleGeocoder?.formattedAddress === null
            ) {
              location?.state !== null
                ? navigate(location.state)
                : navigate("/provider/listings");
            } else {
              location?.state !== null
                ? navigate(location.state)
                : navigate("/provider/inbox");
            }
          }

          if (data?.user?.role === "Admin") {
            location?.state !== null
              ? navigate(location.state)
              : navigate("/admin/manage-providers");
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again.");
        setLoading(false);
      }
    }

    if (loginType === "phone") {
      try {
        setLoading(true);
        const { data } = await axios.post(`/verify-phone-number-rx`, {
          phone: loginContact,
          code: values.code,
          visitorId: getCookie("visitorId"),
        });

        if (data?.error) {
          toast.error(data.error);
          setLoading(false);
        } else {
          setAuth(data);
          localStorage.setItem("auth", JSON.stringify(data));
          toast.success("Login Successful.");
          setLoading(false);

          if (data?.user?.role === "Client") {
            location?.state !== null
                ? navigate(location.state)
                : navigate("/client/engagements");
          }

          if (data?.user?.role === "Provider") {
            if (
              data?.user?.identity?.googleGeocoder?.formattedAddress === null
            ) {
              location?.state !== null
                ? navigate(location.state)
                : navigate("/provider/listings");
            } else {
              location?.state !== null
                ? navigate(location.state)
                : navigate("/provider/inbox");
            }
          }

          if (data?.user?.role === "Admin") {
            location?.state !== null
              ? navigate(location.state)
              : navigate("/admin/manage-providers");
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again.");
        setLoading(false);
      }
    }
  };

  const prevStep = async () => {
    setLoginVerifyStage(false);
  };

  return (
    <>
      {loginVerifyStage ? (
        <>
          {loginType === "email" && (
            <Form onFinish={loginSubmit} className="custom-form space-y-12">
              <div className="space-y-4">
                <div className="mt-10 text-left">
                  <label className="!text-lg lgTablet:text-xl desktop:!text-2xl mb-[12px] font-roboto-flex">
                    Password
                  </label>
                  <Form.Item
                    name="password"
                    className="m-0"
                    rules={[
                      { required: "true", message: "Password is required" },
                    ]}
                  >
                    <Input.Password className="custom-input-password auth-input" />
                  </Form.Item>
                </div>

                <div className="text-right flex-align-center justify-between">
                  <div
                    className="flex items-center pointer gap-1.5"
                    onClick={prevStep}
                  >
                    <FaArrowLeft className="text-green" />

                    <span className=" text-base text-green font-bold font-roboto-flex">
                      Back
                    </span>
                  </div>
                  <Link
                    to="/auth/forgot-password"
                    className="no-underline hover:no-underline"
                  >
                    <span className="text-base font-roboto-flex font-bold text-green ml-[6px]">
                      Forgot Password?
                    </span>
                  </Link>
                </div>
              </div>
              <div className=" d-flex justify-content-center">
                <RecaptchaForm setRecaptchaPass={setRecaptchaPass} />
              </div>

              <div className="">
                <Form.Item>
                  <button
                    className="form-btn !w-full"
                    type="submit"
                    disabled={loading || !recaptchaPass}
                  >
                    {loading ? "Processing" : "Login"}
                  </button>
                </Form.Item>
              </div>
            </Form>
          )}

          {loginType === "phone" && (
            <Form onFinish={loginSubmit} className="custom-form">
              <div className="space-y-4">
                <div className="mt-10 text-left">
                  <label className="!text-lg lgTablet:text-xl desktop:!text-2xl mb-[12px] font-roboto-flex">
                    Code
                  </label>
                  <Form.Item
                    name="code"
                    rules={[
                      {
                        required: "true",
                        message: "Authentication code is required",
                      },
                    ]}
                  >
                    <Input.Password className="custom-input-password auth-input" />
                  </Form.Item>
                </div>
                <div className="text-right flex-align-center justify-between">
                  <div
                    className="flex items-center pointer gap-1.5"
                    onClick={prevStep}
                  >
                    <FaArrowLeft className="text-green" />

                    <span className=" text-base text-green font-bold font-roboto-flex">
                      Back
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-[24px]">
                <Form.Item>
                  <button className="form-btn !w-full" type="submit">
                    {loading ? "Processing" : "Login"}
                  </button>
                </Form.Item>
              </div>
            </Form>
          )}
        </>
      ) : (
        <Form onFinish={preVerifyStageSubmit} className="custom-form">
          <div className="space-y-2.5 lgTablet:space-y-4 text-left">
            <label
              htmlFor="#email"
              className="!text-lg lgTablet:text-xl desktop:!text-2xl font-roboto-flex m-0"
            >
              Email or Phone Number
            </label>
            <Form.Item
              name="login"
              id="email"
              rules={[
                {
                  required: "true",
                  message: "Email or Phone Number is required",
                },
              ]}
            >
              <Input className="custom-input auth-input" />
            </Form.Item>
          </div>

          <div className="text-left"></div>

          <div className="mt-[24px]">
            <Form.Item>
              <button className="form-btn !w-full" type="submit">
                {loading ? "Processing" : "Next"}
              </button>
            </Form.Item>
          </div>

          <div className="mt-6 text-center flex-align-center justify-center">
            <p className="p-16px text-primaryBlack text-base mb-0 font-roboto-flex italic">
              Don’t have an account?
            </p>
            <div className="ml-[12px] flex-align-center">
              <Link
                to="/register"
                className="no-underline hover:no-underline flex-align-center space-x-2.5"
              >
                <span className="p-20px text-green font-roboto-flex italic text-base">
                  Sign Up
                </span>
                <FaArrowRight className="text-green" />
              </Link>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}

export default LoginForm;

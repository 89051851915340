import { Link } from "react-router-dom";
import logo from "../../assets/images/sch-logo.png";
import { logoLight } from "../../assets/images/logo-light.svg";

const Logo = ({ light, textClasses, imageClasses }) => {
  return (
    <div>
      {light && (
        <Link to="/" className="w-fit block">
          <img
            src={logo}
            alt="site-logo"
            className={`w-full h-16 ${imageClasses}`}
          />
        </Link>
      )}
      {!light && (
        <Link to="/" className="flex items-center justify-center gap-1.5">
          <img
            src={logo}
            alt="site-logo"
            className={`w-7 h-7 ${imageClasses}`}
          />
          <p className={`m-0 text-brownDark font-medium ${textClasses}`}>
            SeniorCareHomes.org
          </p>
        </Link>
      )}
    </div>
  );
};
export default Logo;

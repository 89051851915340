export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export function formatKeyWithSpaces(key) {
  return key.replace(/([A-Z])/g, ' $1').trim();
}

export function formatPhoneNumber(phoneNumber) {
  // Extracting individual parts of the phone number
  const countryCode = phoneNumber.slice(0, 2);
  const areaCode = phoneNumber.slice(2, 5);
  const firstPart = phoneNumber.slice(5, 8);
  const secondPart = phoneNumber.slice(8, 12);

  // Formatted phone number
  return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
}
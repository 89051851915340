import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_PLACES_KEY } from "../../config";

export default function SearchFormCareAssessment({ handleSearch, search, setSearch }) {



  return (
    <>
      <div>
        <div className="searchbar relative">
          <div className="input-autocomplete cursor-pointer">
            <GooglePlacesAutocomplete
              apiKey={GOOGLE_PLACES_KEY}
              apiOptions="us"
              selectProps={{
                placeholder: "Search city or zipcode..",
                onChange: ({ value }) => {
                  setSearch((prevSearch) => ({ ...prevSearch, address: value.description }));
                  handleSearch(value.description);
                },
              }}
              className="bg-black"
            />
          </div>
        </div>
      </div>
    </>
  );
}

import {useState, useEffect} from 'react';
import AgentSidebar from '../../components/nav/agent/AgentSidebar';
import {useAuth} from "../../context/auth";
import axios from 'axios';
import UpdateAdCard from '../../components/cards/UpdateAdCard';



export default function AgentDashboard() {
    const [auth, setAuth] = useAuth();

    const [ads, setAds] = useState();
    const [total, setTotal] = useState();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const owner = auth.user?.role?.includes("Owner");

    useEffect(() => {
        fetchAds();
    }, [auth.token !== '']);

    useEffect(() => {
        if(page===1) return;
        loadMore();
    }, [page]);

    const fetchAds = async () => {
        try {
            const {data} = await axios.get(`/user-ads/${page}`);
            setAds(data.ads);
            setTotal(data.total);
        } catch (error) {
            console.log(error);
        }
    };

    const loadMore = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`/user-ads/${page}`);
            setAds([...ads, ...data.ads]);
            setTotal(data.total);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <div>
            <h1 className='display-4 bg-primary text-light p-2'>Agent Dashboard</h1>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col col-lg-2 col-md-3 col-sm-4'>
                        <AgentSidebar />
                    </div>
                    <div className='col col-lg-10 col-md-9 col-sm-8'>
                        {!owner ? (
                            <div 
                                className='d-flex justify-content-center align-items-center vh-100' 
                                style={{marginTop: "-10%"}}
                            >
                                <h2>Hey {auth.user?.name ? auth.user?.name : auth.user?.username}, you are a client.</h2>
                            </div>
                        ) : (
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-8 offset-lg-2'>
                                        <p className='text-center'>Total number of listings: {total}</p>
                                    </div>
                                </div>

                                <div className='row'>
                                    {ads?.map(ad => (
                                        <UpdateAdCard ad={ad} key={ad._id}/>
                                    ))}
                                </div>

                                {ads?.length < total ? (
                                    <div className='row'>
                                        <div className='col text-center mt-4 mb-4'>
                                            <button 
                                                disabled={loading} 
                                                className='btn btn-warning' 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setPage(page + 1);
                                                }}
                                            >
                                                {loading ? "loading..." :  `Load More ${ads?.length} / ${total}`}
                                            </button>
                                        </div>
                                    </div>
                                ) : ('')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            
        </div>
    );
}

